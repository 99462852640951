import React from 'react';
import { withTheme } from '@mui/styles';

import Select from 'react-select';

import { FieldContainer } from '.';
const lng = localStorage.getItem('i18nextLng') || 'en';

const getCustomStyle = (theme) => {
  return {
    option: (provided) => ({
      ...provided,
      // height: '10px',
      paddingLeft: '10px',
      fontFamily: theme.fontFamily,
      fontSize: '14px',
      paddingRight: '14px',

    }),
    control: () => ({
      border: '1px solid rgba(145, 158, 171, 0.32)',
      textAlign: 'center',
      minWidth: '80px',
      display: 'flex',
      height: '34px',
      fontWeight: 'bold',
      cursor: 'pointer',
      borderRadius: '4px',
      // border: `1px solid ${theme.palette.primary.main}`,
      // paddingLeft: '14px',
      color: theme.palette.primary.main,
      fontFamily: theme.fontFamily,
      backgroundColor: 'transparent',
      '&:focus': {
        fontSize: '14px',
        outline: 'none',
        border: `1px solid ${theme.palette.primary.main}`,
        boxShadow: `0 0 10px ${theme.palette.primary.main}`
      }
    }),
    singleValue: (provided, state) => {
      return {
        ...provided,
        paddingLeft: '0px',
        fontSize: '14px',
      };
    },
  };
}

const options = [
  { value: 'en', label: 'English' },
  { value: 'es', label: 'Español' },
  { value: 'zh', label: '中文' }, // Chinese
  // { value: 'hi', label: 'हिन्दी' }, // Hindi
  { value: 'ar', label: 'العربية' }, // Arabic
  // { value: 'pt', label: 'Português' },
  // { value: 'bn', label: 'বাংলা' }, // Bengali
  // { value: 'ru', label: 'Русский' },
  // { value: 'ja', label: '日本語' }, // Japanese
  // { value: 'ko', label: '한국어' }, // Korean
  // { value: 'tr', label: 'Türkçe' }, // Turkish
  // { value: 'de', label: 'Deutsch' },
  { value: 'fr', label: 'Français' },
  // { value: 'it', label: 'Italiano' },
  // { value: 'nl', label: 'Nederlands' }, // Dutch
  // { value: 'pl', label: 'Polski' }, // Polish
  // { value: 'vi', label: 'Tiếng Việt' }, // Vietnamese
  // { value: 'sv', label: 'Svenska' }, // Swedish
  // { value: 'da', label: 'Dansk' }, // Danish
  // { value: 'fi', label: 'Suomi' }, // Finnish
  // { value: 'no', label: 'Norsk' }, // Norwegian
  // { value: 'el', label: 'Ελληνικά' }, // Greek
  // { value: 'sv', label: "Svenska" },
  { value: 'da', label: "Dansk" },
];

function findLabelByValue(value) {
  const option = options.find(option => option.value === value.substr(0, 2));
  return option ? option.label : 'English';
}

class LanguageSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputMode: false,
      value: { value: lng, label: findLabelByValue(lng) },
      valid: ''
    };
    this.handleBlur = this.handleBlur.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    if (!this.state.inputMode) {
      this.setState({ inputMode: true });
    }
  }

  onValueChange(value) {
    const { name } = this.props;
    let valid = true;
    if (!value) {
      valid = false;
    }
    this.setState({
      value,
      valid
    }, () => {
      this.props.onValueChange({
        name,
        value: this.state.value,
        valid
      });
    });
  }

  handleBlur = () => {
    if (this.state.inputMode) {
      this.setState({ inputMode: false });
    }
  };

  render() {
    const { valid } = this.state;
    const { title, theme, name, error } = this.props;
    const { value } = this.state;
    return (
      <FieldContainer>
        <Select
          name={name}
          components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
          //isClearable={true}
          isSearchable={false}
          options={options}
          styles={getCustomStyle(theme)}
          onChange={this.onValueChange}
          onBlur={this.handleBlur}
          value={value || { value: 'en', label: 'English' }}
        />
      </FieldContainer>
    );
  }
}

export default withTheme(LanguageSelect);