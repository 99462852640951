import { Component } from 'react';
import { connect } from 'react-redux';

import PersonList from '../../components/persons/PersonList';
import NoPartners from '../../components/persons/NoPartners';
import { ConfiguratedContainer } from 'components/common';
import Filter from '../../components/persons/Filter';

class Partners extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const {partners} = this.props;
        return (
            <ConfiguratedContainer configuration={{
                ['@media (max-width: 1200px)']: { // eslint-disable-line no-useless-computed-key
                    marginBottom: '30px',
                },
            }}>
                <Filter />
                <PersonList />
            </ConfiguratedContainer>
        );
    }
}

function mapStoreToProps(store) {
    return {
        person: store.persons.person,
        partners: store.persons.partners,
    };
}

export default connect(mapStoreToProps, {})(Partners);