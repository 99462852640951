
import { Component } from 'react';

import { ConfiguratedContainer } from '../common';
import { withTranslation } from 'react-i18next';

class NoPartners extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const {t} = this.props;
        return (
            <ConfiguratedContainer configuration={{
                backgroundColor: 'white',
                padding: '10px 0px 10px 15px',
                borderRadius: '10px',
                marginRight: '15px',
                maxWidth: '768px',
                margin: 'auto auto 10px auto',
                // ['@media (max-width: 768px)']: { // eslint-disable-line no-useless-computed-key
                //     marginRight: '15px'
                // }
            }}>
                <p>
                    {t('noPartner.content.message')}
                </p>
            </ConfiguratedContainer>
        );
    }
}

export default withTranslation()(NoPartners);
