import { Component } from 'react';
import { connect } from 'react-redux';
import { Avatar, IconButton } from '@mui/material';
import { AddCircle } from '@mui/icons-material';

import { styled } from '@mui/material/styles';

import { API_URL } from '../../../config';
import { ConfiguratedContainer, withRouter } from 'components/common';
import { getMessages } from '../../../actions/messages';
import FavoriteIcon from '@mui/icons-material/Favorite';

const BubbleStart = styled('div')(({ sender, theme }) => {
    return {
        position: 'relative',
        backgroundColor: sender ? theme.palette.primary.main : '#bbdefb',
        borderRadius: '10px',
        padding: '10px',
        color: sender ? 'white' : 'black',
        maxWidth: '100%',
        wordWrap: 'break-word',
        '&:hover': {
            cursor: 'pointer'
        }
    };
});

class ArchiveMessages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            count: props.count,
        };
        this.getMoreMessages = this.getMoreMessages.bind(this);
    }

    getMoreMessages() {
        this.setState({
            count: 2 * this.state.count
        }, () => {
            const { count } = this.state;
            this.props.getMessages(this.props.chatId, count);
        })
    }

    UNSAFE_componentWillMount() {
        const { params } = this.props;
        if (params.chatId) {
            const { count } = this.state;
            this.props.getMessages(params.chatId, count);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.params.chatId !== this.props.params.chatId) {
            const { count } = this.state;
            this.props.getMessages(nextProps.params.chatId, count);
        }
    }

    displayTime(isoDate) {
        const currentDate = new Date();
        const messageDate = new Date(Date.parse(isoDate));
        const hours = messageDate.getHours();
        const minutes = messageDate.getMinutes();
        const year = messageDate.getFullYear();
        const time = hours + ":" + minutes;
        const month = messageDate.toLocaleString('default', { month: 'short' });
        const dayName = messageDate.toLocaleString('default', { weekday: 'long' });
        const dayNumber = messageDate.getDate();

        if (currentDate.toDateString() === messageDate.toDateString()) {
            return 'Today at ' + time;
        } else if (currentDate.getFullYear() === messageDate.getFullYear()) {
            const currentWeek = Math.ceil(((currentDate.getUTCDay() + 1) + currentDate.getUTCDate()) / 7);
            const messageWeek = Math.ceil(((messageDate.getUTCDay() + 1) + messageDate.getUTCDate()) / 7);
            if (currentWeek === messageWeek) {
                return `${dayName}, at ${time}`;
            }
            return `${dayNumber} ${month}, at ${time}`;
        } else {
            return `${dayNumber} ${month}, ${year} at ${time}`

        }
    }

    render() {
        let { messages, person, total } = this.props;

        return (
            <ConfiguratedContainer configuration={{
                flex: 1,
                overflowY: 'auto',
                display: 'flex',
                flexDirection: 'column-reverse',
                paddingBottom: '10px'
            }}>
                {messages.length > 0 && messages.map(message => {
                    return <ConfiguratedContainer
                        configuration={{
                            display: 'flex',
                            marginTop: '10px',
                            flexDirection: person.id === message.sender ? 'row-reverse' : 'row'
                        }}
                        key={message.id}>
                        <ConfiguratedContainer configuration={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            paddingTop: '10px',
                            paddingLeft: '10px',
                            paddingRight: '10px',
                        }}>
                            {message.sender && <Avatar src={`${API_URL}/api/files/person/${message.sender}`} />}
                            <ConfiguratedContainer configuration={{
                                fontSize: '10px',
                                color: 'grey'
                            }}>
                                {message.time}
                            </ConfiguratedContainer>
                        </ConfiguratedContainer>
                        <ConfiguratedContainer configuration={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            gap: '5px',
                            marginTop: '10px',
                            alignItems: person.id === message.sender ? 'end' : 'start',
                            maxWidth: 'calc(100% - 86px)'
                        }}>
                            {message.texts.map((text, index) => {
                                return <ConfiguratedContainer configuration={{
                                    maxWidth: '100%',
                                }}>
                                    <BubbleStart
                                        key={text.id}
                                        sender={person.id === message.sender}>
                                        {text.text}
                                        <ConfiguratedContainer configuration={{
                                            position: 'absolute',
                                            right: `${person.id === message.sender ? 'calc(100% + 3px)' : '-18px'}`,
                                            top: 'calc(50% - 7px)',
                                        }}>
                                            <FavoriteIcon sx={{ color: text.like === 'LIKE' ? 'rgb(233, 30, 99)' : 'lightgrey', fontSize: 15 }} />
                                        </ConfiguratedContainer>
                                    </BubbleStart>
                                    {index === message.texts.length - 1 && <ConfiguratedContainer configuration={{
                                        fontSize: '10px',
                                        display: 'flex',
                                        flexDirection: person.id === message.sender ? 'row-reverse' : 'row',
                                        marginRight: '2px',
                                        marginLeft: '2px',
                                        color: 'grey'
                                    }}>
                                        <ConfiguratedContainer>{this.displayTime(text.time)}</ConfiguratedContainer>
                                    </ConfiguratedContainer>}
                                </ConfiguratedContainer>
                            })}
                        </ConfiguratedContainer>
                    </ConfiguratedContainer>
                })}
                {messages.length > 0 && total >= this.state.count && <ConfiguratedContainer configuration={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '10px',
                }}>
                    <IconButton onClick={this.getMoreMessages}>
                        <AddCircle />
                    </IconButton>
                </ConfiguratedContainer>}
            </ConfiguratedContainer>
        );
    }
}

function mapStoreToProps(store) {
    return {
        token: store.persons.token,
        person: store.persons.person,
        messages: store.messages.messages,
        total: store.messages.total,
        count: store.messages.count
    };
}

export default withRouter(connect(mapStoreToProps, { getMessages })(ArchiveMessages));