import * as types from '../constants/ActionTypes';
import { API_URL } from '../config';
import axios from 'axios';
import { logout } from './authentication';

export function requestMatch(person1, person2) {
  return async function (dispatch) {
    try {
      const response = await axios.post(`${API_URL}/api/match/request`, {person1, person2});
      dispatch({
        type: types.GET_MATCH,
        payload: response.data.match
      });
    } catch(e: any) {
      if (e.response.data.code === "TOKEN_EXPIRED") {
        dispatch(logout())
      }
    }
  }
}

export function cancelRequest(person1, person2) {
  return async function (dispatch) {
    try {
      const response = await axios.post(`${API_URL}/api/match/cancel`, {person1, person2});
      dispatch({
        type: types.CANCEL_MATCH,
      });
    } catch(e: any) {
      if (e.response.data.code === "TOKEN_EXPIRED") {
        dispatch(logout())
      }
    }
  }
}

export function getMatch() {
  return async function (dispatch) {
    try {
      const response = await axios.get(`${API_URL}/api/match/person`);
      dispatch({
        type: types.GET_MATCH,
        payload: response.data.match
      });
      return response.data.match;
    } catch(e: any) {
      if (e.response.data.code === "TOKEN_EXPIRED") {
        dispatch(logout())
      }
    }
  }
} 

export function recieveNewMatch() {
  return async function (dispatch) {
    try {
      dispatch({
        type: types.EVENT_NEW_MATCH,
      });
    } catch(e) {
      console.log(e)
    }
  }
} 

export function recieveCancelMatch() {
  return async function (dispatch) {
    try {
      dispatch({
        type: types.EVENT_CANCEL_MATCH,
      });
    } catch(e) {
      console.log(e)
    }
  }
} 

export function viewNewMatch() {
  return async function (dispatch) {
    try {
      dispatch({
        type: types.NEW_MATCH_VIEWED,
      });
    } catch(e) {
      console.log(e)
    }
  }
} 

export function viewCancelMatch() {
  return async function (dispatch) {
    try {
      dispatch({
        type: types.CANCEL_MATCH_VIEWED,
      });
    } catch(e) {
      console.log(e)
    }
  }
} 



