import React, { Component } from 'react';
import { Provider } from 'react-redux';

import Router from './routes';
import configureStore from './store/configureStore';
import { ConfiguratedContainer } from 'components/common';
import history from './history';
import ThemeConfig from './theme';

class App extends Component {

  render() {
    const store = configureStore();
    return (
      <ConfiguratedContainer configuration={{ height: '100%' }}>
        <ThemeConfig>
        <Provider store={store}>
            <Router history={history} />
        </Provider>
        </ThemeConfig>
      </ConfiguratedContainer>
    );
  }
}

export default App;
