import React from 'react';
import i18n from 'i18next';
import { ConfiguratedContainer } from 'components/common';
import LanguageSelect from 'components/common/LanguageSelect';

const LanguageSelector = () => {

  const handleLanguageChange = (newLng) => {
    i18n.changeLanguage(newLng.value.value);
  };

  return (
    <ConfiguratedContainer>
      <LanguageSelect onValueChange={handleLanguageChange} />
    </ConfiguratedContainer>
  );
};

export default LanguageSelector;
