import { Component } from 'react';

import { withTheme } from '@mui/styles';
import { connect } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';
import CommonInput from 'components/common/CommonInput';
import { regex } from 'config';
import { ConfiguratedContainer, withRouter } from 'components/common';
import { Button } from '@mui/material';
import { sendValidationEmail } from '../../actions/authentication';

class ReSendValidation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmitting: false
        };
        this.isValid = this.isValid.bind(this);
        this.onValueChange = this.onValueChange.bind(this);
        // this.onLogin = this.onLogin.bind(this);
        this.goToLogin = this.goToLogin.bind(this);
        this.goToRegister = this.goToRegister.bind(this);
        this.sendValidationEmail = this.sendValidationEmail.bind(this);
    };

    isValid() {
        const { email } = this.state;
        if (
            email && regex.emailRegex.test(email)
        ) {
            this.setState({ isValid: true });
        } else {
            this.setState({ isValid: false });
        }
    }

    goToLogin() {
        const { navigate } = this.props;
        navigate('/login');
    }

    goToRegister() {
        const { navigate } = this.props;
        navigate('/register');
    }


    onValueChange(data) {
        this.setState({
            [data.name]: data.value
        }, () => {
            this.isValid()
        });
    }

    sendValidationEmail() {
        const {
            email,
        } = this.state;
        this.setState({
            isSubmitting: true
        }, async () => {
            try {
                await this.props.sendValidationEmail(
                    email,
                );
                this.setState({
                    isSubmitting: false,
                    success: true

                });
            } catch (err) {
                this.setState({
                    isSubmitting: false,
                    error: true,
                    status: err.response.data.code,
                    message: err.response.data.message
                });
            }
        });

    }

    render() {
        return (
            <ConfiguratedContainer configuration={{
                display: 'table',
                height: '100%',
                width: '100%',
            }}>
                <ConfiguratedContainer configuration={{
                    display: 'table-cell',
                    verticalAlign: 'middle',
                    ['@media (max-width: 425px)']: { // eslint-disable-line no-useless-computed-key
                        height: '100%',
                    },
                }}>
                    {!this.state.error && !this.state.success && <ConfiguratedContainer configuration={{
                        backgroundColor: 'white',
                        width: '375px',
                        margin: 'auto',
                        padding: '20px',
                        borderRadius: '5px',
                        ['@media (max-width: 425px)']: { // eslint-disable-line no-useless-computed-key
                            height: 'calc(100% + 100px)',
                            paddingTop: '100px'
                        },
                    }}>
                        <ConfiguratedContainer configuration={{
                            display: 'flex',
                            justifyContent: 'center',
                        }} >
                            <img alt='Monotryst' src='/static/logo.png' />
                        </ConfiguratedContainer>
                        <ConfiguratedContainer configuration={{
                            // display: 'flex',
                            padding: ' 0 20px',
                            minHeight: '50x',
                            // backgroundColor: '#FFCDD2',
                            marginRight: '5px',
                            borderRadius: '10px',
                            // borderRolor: '#81C784',
                            paddingTop: '10px',
                            paddingBottom: '1px',
                            marginBottom: '10px'
                        }}>
                            <ConfiguratedContainer>
                                Please click the send button to receive a new validation email to confirm your email address.
                            </ConfiguratedContainer>
                            <ConfiguratedContainer configuration={{
                                height: '10px'
                            }}>

                            </ConfiguratedContainer>
                        </ConfiguratedContainer>
                        <ConfiguratedContainer configuration={{ width: 'calc(100% + 15px)' }}>
                            <CommonInput
                                title='Email'
                                name='email'
                                value={this.state.email}
                                error={'Email is required'}
                                regex={regex.emailRegex}
                                onValueChange={this.onValueChange}
                            />
                        </ConfiguratedContainer>
                        <ConfiguratedContainer configuration={{
                            marginTop: '20px',
                            marginBottom: '20px'
                        }}>
                            <LoadingButton
                                fullWidth
                                disabled={!this.state.isValid}
                                onClick={this.sendValidationEmail}
                                loading={this.state.isSubmitting}
                                loadingPosition="end"
                                endIcon={<div></div>}
                                variant="contained"
                            >
                                Send
                            </LoadingButton>
                        </ConfiguratedContainer>
                        <Button
                            fullWidth
                            onClick={this.goToLogin}
                            variant="outlined"
                        >
                            Login
                        </Button>

                    </ConfiguratedContainer>}




                    {this.state.success && <ConfiguratedContainer configuration={{
                        backgroundColor: 'white',
                        width: '375px',
                        margin: 'auto',
                        padding: '20px',
                        borderRadius: '5px',
                        ['@media (max-width: 425px)']: { // eslint-disable-line no-useless-computed-key
                            height: 'calc(100% + 100px)',
                            paddingTop: '100px'
                        },
                    }}>
                        <ConfiguratedContainer configuration={{
                            display: 'flex',
                            justifyContent: 'center',
                        }} >
                            <img alt='Monotryst' src='/static/logo.png' />
                        </ConfiguratedContainer>
                        <ConfiguratedContainer configuration={{
                            // display: 'flex',
                            padding: ' 0 20px',
                            minHeight: '50x',
                            backgroundColor: '#F1F8E9',
                            marginRight: '5px',
                            borderRadius: '10px',
                            // borderRolor: '#81C784',
                            paddingTop: '10px',
                            paddingBottom: '1px',
                            marginBottom: '10px',
                            textAlign: 'justify'
                        }}>
                            <ConfiguratedContainer>
                                <p>An email has been sent. </p>
                                <p>Please follow the instructions in the email to complete your registration.</p>
                                <p>Please also check in your spams in case you don't recieve the email.</p>
                            </ConfiguratedContainer>
                            <ConfiguratedContainer configuration={{
                                height: '10px'
                            }}>

                            </ConfiguratedContainer>
                        </ConfiguratedContainer>
                        {/* <Title>Login</Title> */}

                        <ConfiguratedContainer configuration={{
                            marginBottom: '20px'
                        }}>
                            <LoadingButton
                                fullWidth
                                disabled={!this.state.isValid}
                                onClick={this.goToLogin}
                                loading={this.state.isSubmitting}
                                loadingPosition="end"
                                endIcon={<div></div>}
                                variant="contained"
                            >
                                Go To Login
                            </LoadingButton>
                        </ConfiguratedContainer>

                    </ConfiguratedContainer>}
                    {this.state.error && <ConfiguratedContainer configuration={{
                        backgroundColor: 'white',
                        width: '375px',
                        margin: 'auto',
                        padding: '20px',
                        borderRadius: '5px',
                        ['@media (max-width: 425px)']: { // eslint-disable-line no-useless-computed-key
                            height: 'calc(100% + 100px)',
                            paddingTop: '100px'
                        },
                    }}>
                        <ConfiguratedContainer configuration={{
                            display: 'flex',
                            justifyContent: 'center',
                        }} >
                            <img alt='Monotryst' src='/static/logo.png' />
                        </ConfiguratedContainer>
                        <ConfiguratedContainer configuration={{
                            // display: 'flex',
                            padding: ' 0 20px',
                            minHeight: '50x',
                            backgroundColor: '#FFCDD2',
                            marginRight: '5px',
                            borderRadius: '10px',
                            // borderRolor: '#81C784',
                            paddingTop: '10px',
                            paddingBottom: '1px',
                            marginBottom: '20px'
                        }}>
                            <ConfiguratedContainer>
                                {this.state.message}
                            </ConfiguratedContainer>
                            <ConfiguratedContainer configuration={{
                                height: '10px'
                            }}>

                            </ConfiguratedContainer>
                        </ConfiguratedContainer>
                        {/* <Title>Login</Title> */}

                        <ConfiguratedContainer configuration={{
                            marginBottom: '20px'
                        }}>
                            <LoadingButton
                                fullWidth
                                disabled={!this.state.isValid}
                                onClick={() => this.setState({
                                    email: '',
                                    error: null,
                                    success: null,
                                    message: ''
                                })}
                                loading={this.state.isSubmitting}
                                loadingPosition="end"
                                endIcon={<div></div>}
                                variant="contained"
                            >
                                Retry
                            </LoadingButton>

                        </ConfiguratedContainer>
                        <Button
                            onClick={this.goToRegister}
                            variant="outlined"
                            fullWidth>
                            Register
                        </Button>

                    </ConfiguratedContainer>}
                </ConfiguratedContainer>
            </ConfiguratedContainer>

        );
    }
}

function mapStoreToProps(store) {
    return {

    };
}

export default withRouter(withTheme(connect(mapStoreToProps, { sendValidationEmail })(ReSendValidation)));
