import * as types from '../constants/ActionTypes';

const defaultState = {
  cities: []
};

export default function configuration(state = defaultState, action) {
  switch (action.type) {
    case types.RECIEVE_CITIES: {
      return { ...state, cities: action.payload.cities };
    }
    default:
      return { ...state }
  }
}
