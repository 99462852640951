import * as types from '../constants/ActionTypes';
import { API_URL } from '../config';
import axios from 'axios';
import { logout } from './authentication';



export function getChats(personId, searchText = '') {
  return async function (dispatch) {
    try {
      const response = await axios.post(`${API_URL}/api/chat/list/`, {personId, searchText});
      dispatch({
        type: types.GET_CHATS,
        payload: response.data.chats
      });
    } catch(e: any) {
      if (e.response.data.code === "TOKEN_EXPIRED") {
        dispatch(logout());
      }
    }
  }
}

export function getChat() {
  return async function (dispatch) {
    try {
      const response = await axios.get(`${API_URL}/api/chat`);
      dispatch({
        type: types.GET_CHAT,
        payload: response.data.chat
      });
    } catch(e: any) {
      if (e.response.data.code === "TOKEN_EXPIRED") {
        dispatch(logout());
      }
    }
  }
}

export function getArchiveChat(chatId) {
  return async function (dispatch) {
    try {
      const response = await axios.get(`${API_URL}/api/chat/${chatId}`);
      dispatch({
        type: types.GET_CHAT,
        payload: response.data.chat
      });
    } catch(e: any) {
      if (e.response.data.code === "TOKEN_EXPIRED") {
        dispatch(logout());
      }
    }
  }
}

