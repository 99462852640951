import { Component } from 'react';
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import { connect } from 'react-redux';
import { sendMessage } from '../../../actions/messages';

// import { API_URL } from '../../config';
import { ConfiguratedContainer } from 'components/common';
import { withTranslation } from 'react-i18next';

const Input = styled('input')({
    height: '100%',
    width: '100%',
    paddingLeft: '10px',
    border: 'none',
    outline: 'none',
    wordWrap: 'break-word',
    wordBreak: 'break-all',
    '&::palceholder': {
        color: 'lightgrey'
    }
});

class TextSender extends Component {
    constructor(props) {
        super(props);
        this.state = {
            text: ''
        };
        this.onSendMessage = this.onSendMessage.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    async onSendMessage() {
        const { person, partnerId, chatId } = this.props;
        await this.props.sendMessage({
            chatId,
            text: this.state.text,
            senderId: person.id,
            receiverId: partnerId
        });
        this.setState({
            text: ''
        });
    }

    handleKeyDown(event) {
        if (event.key === 'Enter' && this.state.text !== '') {
            this.onSendMessage();
        }
    }

    handleChange(event) {
        this.setState({
            text: event.target.value
        });
    }

    render() {
        const { text } = this.state;
        const { t } = this.props;
        return (
            <ConfiguratedContainer configuration={{
                height: '50px',
                display: 'flex',
                backgroundColor: 'white'
            }}>
                <ConfiguratedContainer configuration={{
                    flex: 1,
                    paddingRight: '10px'
                }}>
                    <Input placeholder={t('sender.message')}//'Send Message ...'
                        autoComplete="nope"
                        type="search"
                        value={text}
                        onKeyDown={this.handleKeyDown}
                        onChange={this.handleChange}
                    />
                </ConfiguratedContainer>
                <ConfiguratedContainer configuration={{
                    width: '100px',
                    display: 'flex',
                    alignItems: 'center',
                    paddingRight: '5px'
                }}>
                    <Button
                        onClick={this.onSendMessage}
                        fullWidth
                        color="inherit"
                        disabled={this.state.text === ''}
                        variant="outlined">
                        <ConfiguratedContainer configuration={{ color: '#0D47A1' }}>{t('sender.send')}</ConfiguratedContainer>
                    </Button>
                </ConfiguratedContainer>

            </ConfiguratedContainer>
        );
    }
}

function mapStoreToProps(store) {
    return {
        person: store.persons.person
    };
}

export default withTranslation()(connect(mapStoreToProps, { sendMessage })(TextSender));