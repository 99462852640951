import React, { Component } from 'react';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Close';
import { Avatar, List, ListItemButton } from '@mui/material';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { API_URL } from '../../../config';
import { ConfiguratedContainer, withRouter } from 'components/common';
import { withTheme } from '@emotion/react';
import { connect } from 'react-redux';
import { getChats } from '../../../actions/chat';
import Skeleton from '@mui/material/Skeleton';
import { withTranslation } from 'react-i18next';

class Chats extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: '',
            loading: false
        };
        this.selectChat = this.selectChat.bind(this);
        this.handleSearchChat = this.handleSearchChat.bind(this);
        this.goToMessages = this.goToMessages.bind(this);
        this.resetSearch = this.resetSearch.bind(this);
        this.goToPartners = this.goToPartners.bind(this);
    }

    UNSAFE_componentWillMount() {
        const { person } = this.props;
        const { searchText } = this.state;
        this.setState({ loading: true }, async () => {
            await this.props.getChats(person.id, searchText);
            this.setState({ loading: false});
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.person !== this.props.person) {
            const { searchText } = this.state;
            this.setState({ loading: true }, async () => {
                await this.props.getChats(nextProps.person.id, searchText);
                this.setState({ loading: false});
            });

        }
    }

    goToMessages() {
        const { navigate } = this.props;
        navigate('/archive/');
    }

    goToPartners() {
        const { navigate } = this.props;
        navigate('/partners/');
    }


    selectChat(chatId) {
        const { navigate } = this.props;
        this.setState({
            searchText: ''
        }, () => {
            const { person } = this.props;
            const { searchText } = this.state;
            this.props.getChats(person.id, searchText);
            navigate(`/archive/${chatId}`);
        });
    }

    resetSearch() {
        this.setState({
            searchText: ''
        }, () => {
            const { person } = this.props;
            const { searchText } = this.state;
            this.props.getChats(person.id, searchText);
        });
    }

    handleSearchChat(event) {
        this.setState({
            searchText: event.target.value
        }, () => {
            const { person } = this.props;
            const { searchText } = this.state;
            this.props.getChats(person.id, searchText);
        });
    }

    render() {
        const { person, t } = this.props;
        let { chats } = this.props;
        chats = chats.map(c => {
            if (person.id === c.personId1) {
                return {
                    id: c.id,
                    personId: c.personId2,
                    personName: c.p2firstname,
                    text: c.text,
                    sender: c.sender
                };
            }
            return {
                id: c.id,
                personId: c.personId1,
                personName: c.p1firstname,
                text: c.text,
                sender: c.sender
            };
        });
        const { theme, params } = this.props;
        return (
            <ConfiguratedContainer configuration={{
                borderRight: 'solid #CFD8DC 1px',
                width: params.chatId ? '300px' : '100%',
                ['@media (max-width: 768px)']: { // eslint-disable-line no-useless-computed-key
                    width: params.chatId ? '70px' : '100%',
                },
                ['@media (max-width: 425px)']: { // eslint-disable-line no-useless-computed-key
                    display: params.chatId ? 'none' : 'block',
                },
            }}>
                <ConfiguratedContainer configuration={{
                    backgroundColor: 'lightgrey',
                    height: '50px',
                    borderRight: 'solid 1px whitesmoke',
                    marginRight: '-1px',
                    display: 'flex',
                    ['@media (max-width: 768px)']: { // eslint-disable-line no-useless-computed-key
                        display: 'none',
                    },
                }}>
                    <InputBase
                        value={this.state.searchText}
                        sx={{ ml: 1, flex: 1 }}
                        placeholder={t('chat.content.search')}
                        inputProps={{ 'aria-label': t('chat.content.search') }}
                        onChange={this.handleSearchChat}
                    />
                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                        {this.state.searchText === '' && <SearchIcon />}
                        {this.state.searchText !== '' && <DeleteIcon onClick={this.resetSearch} />} 
                        {/* TODO: onlClick should be fixed */}
                    </IconButton>
                </ConfiguratedContainer>
                <ConfiguratedContainer configuration={{
                    backgroundColor: 'lightgrey',
                    height: '50px',
                    borderRight: 'solid #CFD8DC 1px',
                    display: 'flex',
                    justifyContent: 'center',
                    ['@media (min-width: 768px)']: { // eslint-disable-line no-useless-computed-key
                        display: 'none',
                    },
                }}>
                    <ConfiguratedContainer configuration={{
                        backgroundColor: 'lightgrey',
                        height: '50px',
                        borderRight: 'solid #CFD8DC 1px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flex: 1,
                        display: params.chatId ? 'none' : 'flex',
                    }}>
                        <InputBase
                            value={this.state.searchText}
                            sx={{ ml: 1, flex: 1 }}
                            placeholder={t('chat.content.search')}
                            inputProps={{ 'aria-label': t('chat.content.search') }}
                            onChange={this.handleSearchChat}
                        />
                    </ConfiguratedContainer>
                    {this.state.searchText === '' && <IconButton onClick={this.goToMessages} type="button" sx={{ p: '10px' }} aria-label="search">
                        <SearchIcon />
                    </IconButton>} 
                    {this.state.searchText !== '' &&<IconButton onClick={this.resetSearch} type="button" sx={{ p: '10px' }} aria-label="search">
                         <DeleteIcon />
                    </IconButton>}
                </ConfiguratedContainer>
                {!this.state.loading && <List style={{
                    padding: 0,
                    overflowY: 'auto',
                    height: 'calc(100% - 49px)'
                }}>


                    {chats.length > 0 && chats.map(chat => {
                        return (
                            <ConfiguratedContainer
                                key={chat.id}
                                onClick={() => this.selectChat(chat.id)}
                                configuration={{
                                    cursor: 'pointer',
                                    backgroundColor: 'rgba(249, 250, 251, 0.72)',
                                    borderRight: (params.chatId && chat.id === +params.chatId) ? '3px solid #0D47A1' : '',
                                }}>

                                <ListItemButton>
                                    <ListItemAvatar>
                                        <ConfiguratedContainer configuration={{
                                            marginLeft: '-2px',
                                            border: 'solid lightgrey',
                                            borderRadius: '50%',
                                            backgroundColor: 'lightgrey',
                                            // backgroundImage:`url("${API_URL}/static/profile.png")`
                                        }}>

                                            {chat && chat.personId && <Avatar alt="Profile Picture" src={`${API_URL}/api/files/person/${chat.personId}`} />}
                                        </ConfiguratedContainer>
                                    </ListItemAvatar>
                                    <ConfiguratedContainer configuration={{
                                        ['@media (max-width: 768px)']: { // eslint-disable-line no-useless-computed-key
                                            display: params.chatId ? 'none' : 'block',
                                        },
                                    }}>
                                        <ConfiguratedContainer configuration={{
                                            color: theme.palette.primary.main,
                                            fontWeight: '500',
                                            fontSize: '16px',

                                        }}>
                                            {chat.personName}
                                        </ConfiguratedContainer>
                                        {chat.text && <ConfiguratedContainer configuration={{
                                            color: 'grey'
                                        }}>
                                            {chat.text.length > 25 ? chat.text.slice(0, 25) + ' ...' : chat.text}
                                        </ConfiguratedContainer>}
                                    </ConfiguratedContainer>
                                    <ConfiguratedContainer configuration={{ flex: 1 }}>

                                    </ConfiguratedContainer>
                                    {/* {chat.text && !chat.read && chat.sender !== person.id && <ConfiguratedContainer configuration={{
                                        width: '15px',
                                        height: '15px',
                                        borderRadius: '50%',
                                        backgroundColor: '#E53935',
                                        textShadow: '2px 2px'
                                    }}></ConfiguratedContainer>} */}
                                </ListItemButton>
                                <Divider component="li" />
                            </ConfiguratedContainer>
                        )
                    })}

                    {chats.length === 0 && <ConfiguratedContainer configuration={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%',
                        flexDirection: 'column',
                        gap: '30px',
                        marginTop: '20px'
                    }}>
                        <ConfiguratedContainer>{t('chat.content.noArchived')}</ConfiguratedContainer>
                    </ConfiguratedContainer>}

                </List>}

                {!this.state.loading && chats.length === 0 && <ConfiguratedContainer configuration={{
                    textAlign: 'center',
                    marginTop: '30%'
                }}>
                    {t('chat.content.noPartner')}
                </ConfiguratedContainer>}

                {this.state.loading && <List style={{
                    padding: 0,
                    overflowY: 'auto',
                    height: 'calc(100% - 49px)'
                }}>
                            <ConfiguratedContainer
                                configuration={{
                                    cursor: 'pointer',
                                    backgroundColor: 'rgba(249, 250, 251, 0.72)',
                                }}>

                                <ListItemButton>
                                    <ListItemAvatar>
                                        <ConfiguratedContainer configuration={{
                                            marginLeft: '-2px',
                                        }}>
                                            <Skeleton variant="circular" width={40} height={40} />

                                        </ConfiguratedContainer>
                                    </ListItemAvatar>
                                    <ConfiguratedContainer configuration={{
                                            width: '100%',
                                        ['@media (max-width: 768px)']: { // eslint-disable-line no-useless-computed-key
                                            display: params.chatId ? 'none' : 'block',
                                        },
                                    }}>
                                        <ConfiguratedContainer configuration={{
                                            color: theme.palette.primary.main,
                                            fontWeight: '500',
                                            fontSize: '16px',
                                            width: '100%'
                                        }}>
                                            <Skeleton variant="text" sx={{ fontSize: '16px' }} width={150} />

                                        </ConfiguratedContainer>
                                        <ConfiguratedContainer configuration={{
                                            color: 'grey'
                                        }}>
                                            <Skeleton variant="text" sx={{ fontSize: '16px' }} width={'70%'}  />
                                        </ConfiguratedContainer>
                                    </ConfiguratedContainer>
                                    <ConfiguratedContainer configuration={{ flex: 1 }}>

                                    </ConfiguratedContainer>
                                     
                                </ListItemButton>
                                <Divider component="li" />
                            </ConfiguratedContainer>
                            <ConfiguratedContainer
                                configuration={{
                                    cursor: 'pointer',
                                    backgroundColor: 'rgba(249, 250, 251, 0.72)',
                                    // borderRight:  '3px solid #0D47A1',
                                }}>

                                <ListItemButton>
                                    <ListItemAvatar>
                                        <ConfiguratedContainer configuration={{
                                            marginLeft: '-2px',
                                            // ['@media (max-width: 768px)']: { // eslint-disable-line no-useless-computed-key
                                            //     marginLeft: !chat.read && chat.sender !== person.id ? '-4px' : '-2px',
                                            //     border: chat.text && !chat.read && chat.sender !== person.id ? 'solid 2px #E53935' : 'none',
                                            //     borderRadius: '50%',
                                            // },
                                        }}>
                                            <Skeleton variant="circular" width={40} height={40} />

                                        </ConfiguratedContainer>
                                    </ListItemAvatar>
                                    <ConfiguratedContainer configuration={{
                                            width: '100%',
                                        ['@media (max-width: 768px)']: { // eslint-disable-line no-useless-computed-key
                                            display: params.chatId ? 'none' : 'block',
                                        },
                                    }}>
                                        <ConfiguratedContainer configuration={{
                                            color: theme.palette.primary.main,
                                            fontWeight: '500',
                                            fontSize: '16px',
                                            width: '100%'
                                        }}>
                                            <Skeleton variant="text" sx={{ fontSize: '16px' }} width={150} />

                                        </ConfiguratedContainer>
                                        <ConfiguratedContainer configuration={{
                                            color: 'grey'
                                        }}>
                                            <Skeleton variant="text" sx={{ fontSize: '16px' }} width={'70%'}  />
                                        </ConfiguratedContainer>
                                    </ConfiguratedContainer>
                                    <ConfiguratedContainer configuration={{ flex: 1 }}>

                                    </ConfiguratedContainer>
                                     
                                </ListItemButton>
                                <Divider component="li" />
                            </ConfiguratedContainer>

                    

                </List>}
            </ConfiguratedContainer>
        );
    }
}
function mapStoreToProps(store) {
    return {
        chats: store.chats.chats,
        person: store.persons.person
    };
}

export default withTranslation()(withRouter(withTheme(connect(mapStoreToProps, { getChats })(Chats))));
