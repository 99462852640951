import { withTheme } from '@mui/styles';
import { styled } from '@mui/material/styles';

const Title = styled('div')(({ theme }) => {
  return {
    marginBottom: '10px',
    color: theme.palette.primary.main,
    fontWeight: '500',
    fontFamily: theme.fontFamily,
  };
});

export default withTheme(Title);
