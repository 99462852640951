//import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import { Avatar, IconButton } from '@mui/material';
import { API_URL } from '../../config';
import { Button } from '@mui/material';

import { withTheme } from '@emotion/react';
import { ConfiguratedContainer, withRouter } from 'components/common';

class MatchModal extends Component {
    constructor(props) {
        super(props);
        this.goToMessages = this.goToMessages.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleClose() {
        this.props.onClose();
    };

    goToMessages() {
        const { navigate } = this.props;
        this.props.onClose();
        navigate('/chat');

    }

    render() {
        const { openModal, match } = this.props;
        return (
            <Dialog fullScreen={false} fullWidth={true} onClose={this.handleClose} open={openModal}>

                <ConfiguratedContainer configuration={{
                    textAlign: 'center',
                    padding: '30px',
                    fontFamily: "'Comic Sans MS', cursive, sans-serif",
                    fontSize: '28px',
                    color: '#0D47A1',
                    ['@media (max-width: 505px)']: { // eslint-disable-line no-useless-computed-key
                        fontSize: '20px',
                    },
                    ['@media (max-width: 375px)']: { // eslint-disable-line no-useless-computed-key
                        fontSize: '14px',
                    }
                }}>
                    <h1>Congratulations</h1>
                </ConfiguratedContainer>
                <ConfiguratedContainer configuration={{
                    //padding: '10px 20px 0 20px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <ConfiguratedContainer configuration={{
                        display: 'flex',
                        //border: 'solid red',
                        justifyContent: 'space-between',
                        width: '350px',
                        ['@media (max-width: 505px)']: { // eslint-disable-line no-useless-computed-key
                            display: 'none'
                        }
                    }}>
                        {match && match.person1 && <Avatar sx={{ height: '170px', width: '170px' }} src={`${API_URL}/api/files/person/${match.person1}`} />}
                        {match && match.person2 && <Avatar sx={{ height: '170px', width: '170px' }} src={`${API_URL}/api/files/person/${match.person2}`} />}

                    </ConfiguratedContainer>
                    <ConfiguratedContainer configuration={{
                        display: 'flex',
                        //border: 'solid red',
                        justifyContent: 'space-between',
                        width: '210px',
                        ['@media (min-width: 505px)']: { // eslint-disable-line no-useless-computed-key
                            display: 'none'
                        }
                    }}>
                        {match && match.person1 && <Avatar sx={{ height: '100px', width: '100px' }} src={`${API_URL}/api/files/person/${match.person1}`} />}
                        {match && match.person2 && <Avatar sx={{ height: '100px', width: '100px' }} src={`${API_URL}/api/files/person/${match.person2}`} />}

                    </ConfiguratedContainer>
                </ConfiguratedContainer>
                <ConfiguratedContainer configuration={{
                    textAlign: 'center',
                    /* background-color: #ffecb3; */
                    padding: '30px',
                    //fontFamily: "'Comic Sans MS', cursive, sans-serif",
                    fontSize: '16px',
                    color: '#0D47A1',

                }}>
                    <p>You've matched</p>
                    <p>Start the conversation now!</p>
                </ConfiguratedContainer>
                <ConfiguratedContainer configuration={{
                    margin: '50px 30px 50px 30px '
                }}>
                    <Button
                        fullWidth
                        onClick={this.goToMessages}
                        variant="contained"
                    >
                        Messages
                    </Button>
                </ConfiguratedContainer>

            </Dialog>
        );
    }
}

// MatchModal.propTypes = {
//     onOpenSidebar: PropTypes.func
// };

function mapStoreToProps(store) {
    return {
        person: store.persons.person,
        partner: store.persons.partner,
        match: store.match.match,

    };
}

export default withRouter(withTheme(connect(mapStoreToProps, {})(MatchModal)));

