
import { Component } from 'react';
import { withTheme } from '@mui/styles';
import { API_URL } from '../../config';
import Skeleton from '@mui/material/Skeleton';

import { ConfiguratedContainer, withRouter } from '../common';

class PersonItemSkeleton extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    

    render() {
        return (
            <ConfiguratedContainer onClick={this.goToPerson} configuration={{
                display: 'flex',
                cursor: 'pointer',
                backgroundColor: 'white',
                marginBottom: '10px',
                padding: '10px 10px 10px 10px',
                borderRadius: '10px',
                minHeight: '100px',
                [':hover']: {// eslint-disable-line no-useless-computed-key
                    boxShadow: '0 0 11px rgba(33,33,33,.2)'
                }

            }}>
                <ConfiguratedContainer configuration={{ display: 'flex', alignItems: 'center' }}>
                    <ConfiguratedContainer configuration={{
                        height: '70px',
                        width: '70px',
                        borderRadius: '50%',
                        marginRight: '15px',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center'
                    }}
                        >
                                  <Skeleton variant="circular" width={70} height={70} />

                    </ConfiguratedContainer>



                </ConfiguratedContainer>
                <ConfiguratedContainer>
                    <ConfiguratedContainer configuration={{
                        fontWeight: '500',
                        fontSize: '20px',
                    }}>
                        <Skeleton variant="text" width={210} sx={{ fontSize: '20px' }} />
                    </ConfiguratedContainer>
                    <ConfiguratedContainer>
                        <Skeleton variant="text" width={210} sx={{ fontSize: '16px' }} />
                    </ConfiguratedContainer>
                    <ConfiguratedContainer>
                        <Skeleton variant="text" width={210} sx={{ fontSize: '16px' }} />
                    </ConfiguratedContainer>
                    
                </ConfiguratedContainer>

            </ConfiguratedContainer>
        );
    }
}



export default PersonItemSkeleton;
