// import workers from './workers';
import axios from 'axios';
import { API_URL } from '../config';

export const registerWorkers = async () => {
  const token = localStorage.getItem("token");
  if (!token) return;
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/worker.js')
      .then(function (registration) {
        // Service Worker registration successful
        navigator.serviceWorker.ready
          .then(async function (registration) {
            const subscription = await registration.pushManager.subscribe({
              userVisibleOnly: true,
              applicationServerKey: 'BGdt0gY_y0t64KekMD1olQsbOKiAp7GXGFRmPfvHAa-FYs7mmg5l8FPZzeeznOhuaJjX_Gz5RiZ9rTelgoeZWrc'
            });
            return axios.post(`${API_URL}/api/subscription/`,  subscription);

          })
          .then(function (subscription) {
            // Subscription successful
          })
          .catch(function (error) {
            console.error('Subscription failed: ', error);
          });

      })
      .catch(function (error) {
        console.log('Service Worker registration failed: ', error);
      });
  }


  // if ('serviceWorker' in navigator) {
  //     const register = await navigator.serviceWorker.register(API_URL + '/worker.js');
  //     const subscription = await register.pushManager.subscribe({
  //       userVisibleOnly: true,
  //       applicationServerKey: 'BGdt0gY_y0t64KekMD1olQsbOKiAp7GXGFRmPfvHAa-FYs7mmg5l8FPZzeeznOhuaJjX_Gz5RiZ9rTelgoeZWrc'
  //     });
  //     axios.post(`${API_URL}/api/subscription/`,  subscription);
  // } else {
  //   console.log('Service Worker is not supported by browser.');
  // }

}
