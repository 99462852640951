import * as types from '../constants/ActionTypes';
import { API_URL } from '../config';
import axios from 'axios';
import { logout } from './authentication';

export function receiveNotfications(notifications) {
  return async function (dispatch) {
      dispatch({
        type: types.RECIEVE_NOTIFICATIONS,
        payload: notifications
      });
  }
}



export function getNotifications(personId) {
  return async function (dispatch) {
    try {
      const response = await axios.get(`${API_URL}/api/notifications/${personId}`);
      dispatch({
        type: types.RECIEVE_NOTIFICATIONS,
        payload: response.data.notifications,
      });
    } catch(e: any) {
      if (e.response.data.code === "TOKEN_EXPIRED") {
        dispatch(logout())
      }
    }
  }
}
