import { combineReducers } from 'redux';
import persons from './persons';
import locations from './locations';
import chats from './chats';
import match from './match';
import messages from './messages';
import sockets from './sockets';
import notifications from './notifications';

const rootReducer = combineReducers({
  persons,
  locations,
  chats,
  messages,
  sockets,
  notifications,
  match
});

export default rootReducer;
