import React, { useRef, useState } from 'react';
import { alpha } from '@mui/material/styles';
import { Button, Box, Avatar, IconButton } from '@mui/material';
import MenuPopover from './MenuPopover';
import { withRouter } from 'components/common';
import { withTranslation } from 'react-i18next';

function UnAuthAccountPopover({ person, logout, navigate, t }) {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const goToLogin = () => {
    navigate('/login');
  };

  const goToRegister = () => {
    navigate('/register');
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <Avatar src={'/static/profile.png'} alt="photoURL" />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={goToLogin}>
            {t('unauthPopup.login')}
          </Button>
        </Box>
        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={goToRegister}>
          {t('unauthPopup.register')}
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}

export default withTranslation()(withRouter(UnAuthAccountPopover));
