
import { Component } from 'react';
import { withTheme } from '@mui/styles';
import { API_URL } from '../../config';
import FavoriteIcon from '@mui/icons-material/Favorite';
import dayjs from 'dayjs';
import { withTranslation } from 'react-i18next';

import { ConfiguratedContainer, withRouter } from '../common';

class PersonItem extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.goToPerson = this.goToPerson.bind(this);
    }

    goToPerson() {
        const { person, navigate } = this.props;
        navigate(`/person/${person.id}`);
    }

    formatDate(dateString) {
        const {t} = this.props;
        const date = new Date(dateString);
        const now = new Date(); // Get the current date and time

        const timeDifference = now - date; // Calculate the time difference in milliseconds

        const oneMinuteInMilliseconds = 60 * 1000; // Number of milliseconds in a minute
        const oneHourInMilliseconds = 60 * oneMinuteInMilliseconds; // Number of milliseconds in an hour
        const oneDayInMilliseconds = 24 * oneHourInMilliseconds; // Number of milliseconds in a day
        const oneMonthInMilliseconds = 30 * oneDayInMilliseconds; // An approximate number of milliseconds in a month
        const oneYearInMilliseconds = 365 * oneDayInMilliseconds; // An approximate number of milliseconds in a year

        if (timeDifference < oneMinuteInMilliseconds) {
            return t('personItem.time.justnow');
        } else if (timeDifference < oneHourInMilliseconds) {
            const minutes = Math.floor(timeDifference / oneMinuteInMilliseconds);
            return t('personItem.time.minutes', {
                minutes: minutes
            });
        } else if (timeDifference < oneDayInMilliseconds) {
            const hours = Math.floor(timeDifference / oneHourInMilliseconds);
            return t('personItem.time.hours', {
                hours
            });
        } else if (timeDifference < oneMonthInMilliseconds) {
            const days = Math.floor(timeDifference / oneDayInMilliseconds);
            return t('personItem.time.days', {
                days
            });
        } else if (timeDifference < oneYearInMilliseconds) {
            const months = Math.floor(timeDifference / oneMonthInMilliseconds);
            return t('personItem.time.months', {
                months
            });
        } else {
            const years = Math.floor(timeDifference / oneYearInMilliseconds);
            return t('personItem.time.years', {
                years
            });
        }
    }


    render() {
        const { person, theme, t } = this.props;
        const currentDate = dayjs();
        const birthdate = dayjs(person.birthyear);
        
        const imageURl = person.id ? `${API_URL}/api/files/person/${person.id}` : `${API_URL}/static/profile.png`;
        return (
            <ConfiguratedContainer onClick={this.goToPerson} configuration={{
                position: 'relative',
                display: 'flex',
                cursor: 'pointer',
                backgroundColor: 'white',
                marginBottom: '10px',
                padding: '10px 10px 10px 10px',
                borderRadius: '10px',
                minHeight: '100px',
                [':hover']: {// eslint-disable-line no-useless-computed-key
                    boxShadow: '0 0 11px rgba(33,33,33,.2)'
                }

            }}>
                <ConfiguratedContainer configuration={{ display: 'flex', alignItems: 'center' }}>
                    <ConfiguratedContainer configuration={{
                        height: '70px',
                        width: '70px',
                        borderRadius: '50%',
                        marginRight: '15px',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center'
                    }}
                        style={{ backgroundImage: `url(${imageURl})` }}>
                    </ConfiguratedContainer>
                </ConfiguratedContainer>
                <ConfiguratedContainer>
                    <ConfiguratedContainer configuration={{
                        color: theme.palette.primary.main,
                        fontWeight: '500',
                        fontSize: '20px',
                    }}>
                        {person.firstname}
                    </ConfiguratedContainer>
                    {/* <ConfiguratedContainer>
                        {`${person.countrylabel} / ${person.citylabel}`}
                    </ConfiguratedContainer> */}
                    <ConfiguratedContainer>
                        {person.distance > 1 ? t('personItem.distance', { distance: Math.floor(person.distance)}) : t('personItem.less')}
                    </ConfiguratedContainer>
                    <ConfiguratedContainer configuration={{ display: 'flex', gap: '5px' }}>
                        <ConfiguratedContainer configuration={{
                            color: theme.palette.primary.main,
                        }}>{t('personItem.birthdate', {
                            birthdate: currentDate.diff(birthdate, 'year')
                        })}</ConfiguratedContainer>
                    </ConfiguratedContainer>
                    <ConfiguratedContainer configuration={{
                        position: 'absolute',
                        right: '15px',
                        top: '10px',
                        fontSize: '10px'
                    }}>
                        {this.formatDate(person.lastconnection)}
                    </ConfiguratedContainer>
                </ConfiguratedContainer>
                {person.blocked && <ConfiguratedContainer configuration={{
                    position: 'absolute',
                    right: '30px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                    marginTop: '-10px'
                }}>
                    <img width={70} alt='User blocked' src='/static/block.png' />
                </ConfiguratedContainer>}
                {person.requested && <ConfiguratedContainer configuration={{
                    position: 'absolute',
                    right: '30px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                    marginTop: '-10px',
                    color: '#f50057'
                }}>
                    <FavoriteIcon />

                </ConfiguratedContainer>}
            </ConfiguratedContainer>
        );
    }
}

export default withTranslation()(withRouter(withTheme(PersonItem)));
