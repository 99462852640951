import { Component } from 'react';
import { connect } from 'react-redux';
import { Avatar, IconButton } from '@mui/material';
import { AddCircle } from '@mui/icons-material';

import { styled } from '@mui/material/styles';
import { API_URL } from '../../../config';
import { ConfiguratedContainer } from 'components/common';
import { getMessages, toggleLikeMessage } from '../../../actions/messages';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { withTranslation } from 'react-i18next';

const BubbleStart = styled('div')(({ sender, theme }) => {
    return {
        position: 'relative',
        backgroundColor: sender ? theme.palette.primary.main : '#bbdefb',
        borderRadius: '10px',
        padding: '10px',
        color: sender ? 'white' : 'black',
        maxWidth: '100%',
        wordWrap: 'break-word',
        '&:hover': {
            cursor: 'pointer'
        }
    };
});

class Messages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            count: props.count,
        };
        this.getMoreMessages = this.getMoreMessages.bind(this);
        this.likeMessage = this.likeMessage.bind(this);
    }

    likeMessage(messageId) {
        const { count } = this.state;
        const { chat, person } = this.props;
        if (chat) {
            let partnerId = chat.personId1;
            if (person.id === chat.personId1) {
                partnerId = chat.personId2;
            }
            this.props.toggleLikeMessage(chat.id, partnerId, messageId, count);
        }

    }

    getMoreMessages() {
        this.setState({
            count: 2 * this.state.count
        }, () => {
            const { count } = this.state;
            this.props.getMessages(this.props.chatId, count);
        })
    }

    UNSAFE_componentWillMount() {
        if (this.props.chatId) {
            const { count } = this.state;
            this.props.getMessages(this.props.chatId, count);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.chatId !== this.props.chatId) {
            const { count } = this.state;
            this.props.getMessages(nextProps.chatId, count);
        }
    }

    // displayTime(isoDate) {
    //     const currentDate = new Date();
    //     const messageDate = new Date(Date.parse(isoDate));
    //     const hours = messageDate.getHours();
    //     const minutes = messageDate.getMinutes();
    //     const year = messageDate.getFullYear();
    //     const time = hours + ":" + minutes;
    //     const month = messageDate.toLocaleString('default', { month: 'short' });
    //     const dayName = messageDate.toLocaleString('default', { weekday: 'long' });
    //     const dayNumber = messageDate.getDate();

    //     if (currentDate.toDateString() === messageDate.toDateString()) {
    //         return 'Today at ' + time;
    //     } else if (currentDate.getFullYear() === messageDate.getFullYear()) {
    //         const currentWeek = Math.ceil(((currentDate.getUTCDay() + 1) + currentDate.getUTCDate()) / 7);
    //         const messageWeek = Math.ceil(((messageDate.getUTCDay() + 1) + messageDate.getUTCDate()) / 7);
    //         if (currentWeek === messageWeek) {
    //             return `${dayName}, at ${time}`;
    //         }
    //         return `${dayNumber} ${month}, at ${time}`;
    //     } else {
    //         return `${dayNumber} ${month}, ${year} at ${time}`

    //     }
    // }

    displayTime(isoDate) {
        const {t} = this.props;
        const currentDate = new Date();
        const messageDate = new Date(Date.parse(isoDate));
        const hours = messageDate.getHours();
        const minutes = messageDate.getMinutes();
        const time = `${hours}:${minutes}`;
        const month = messageDate.toLocaleString('default', { month: 'short' });
        const dayName = messageDate.toLocaleString('default', { weekday: 'long' });
        const dayNumber = messageDate.getDate();
        const year = messageDate.getFullYear();
    
        // Example i18n keys, replace these with your actual keys
        const todayKey = 'messages.content.today';
        const sameWeekKey = 'messages.content.sameWeek';
        const otherDateKey = 'messages.content.otherDate';
    
        if (currentDate.toDateString() === messageDate.toDateString()) {
            return t(todayKey, { time });
        } else if (currentDate.getFullYear() === messageDate.getFullYear()) {
            const currentWeek = Math.ceil(((currentDate.getUTCDay() + 1) + currentDate.getUTCDate()) / 7);
            const messageWeek = Math.ceil(((messageDate.getUTCDay() + 1) + messageDate.getUTCDate()) / 7);
    
            if (currentWeek === messageWeek) {
                return t(sameWeekKey, { dayName, time });
            }
    
            return t(otherDateKey, { dayNumber, month, time });
        } else {
            return t(otherDateKey, { dayNumber, month, year, time });
        }
    }
    

    render() {
        let { messages, person, total } = this.props;

        return (
            <ConfiguratedContainer configuration={{
                flex: 1,
                overflowY: 'auto',
                display: 'flex',
                flexDirection: 'column-reverse',
                paddingBottom: '10px'
            }}>
                {messages.length > 0 && messages.map(message => {
                    return <ConfiguratedContainer
                        configuration={{
                            display: 'flex',
                            marginTop: '10px',
                            flexDirection: person.id === message.sender ? 'row-reverse' : 'row'
                        }}
                        key={message.id}>
                        <ConfiguratedContainer configuration={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            paddingTop: '10px',
                            paddingLeft: '10px',
                            paddingRight: '10px',
                            // backgroundColor: 'lightgrey'
                        }}>
                            {message.sender && <Avatar src={`${API_URL}/api/files/person/${message.sender}`} />}
                            <ConfiguratedContainer configuration={{
                                fontSize: '10px',
                                color: 'grey'
                            }}>
                                {message.time}
                            </ConfiguratedContainer>
                        </ConfiguratedContainer>
                        <ConfiguratedContainer configuration={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            gap: '5px',
                            marginTop: '10px',
                            alignItems: person.id === message.sender ? 'end' : 'start',
                            maxWidth: 'calc(100% - 86px)'
                        }}>
                            {message.texts.map((text, index) => {
                                return <ConfiguratedContainer configuration={{
                                    maxWidth: '100%',
                                    cursor: 'pointer'
                                }}
                                    key={text.id}
                                >
                                    <BubbleStart
                                        onDoubleClick={() => {
                                            if (person.id !== message.sender) this.likeMessage(text.id)
                                        }}
                                        key={text.id}
                                        sender={person.id === message.sender}>
                                        {text.text}
                                        <ConfiguratedContainer
                                            onClick={() => {
                                                if (person.id !== message.sender) this.likeMessage(text.id)
                                            }}
                                            configuration={{
                                                position: 'absolute',
                                                right: `${person.id === message.sender ? 'calc(100% + 3px)' : '-18px'}`,
                                                top: 'calc(50% - 7px)',
                                            }}>
                                            <FavoriteIcon sx={{ color: text.like === 'LIKE' ? 'rgb(233, 30, 99)' : 'lightgrey', fontSize: 15 }} />
                                        </ConfiguratedContainer>
                                    </BubbleStart>
                                    {index === message.texts.length - 1 && <ConfiguratedContainer

                                        configuration={{
                                            fontSize: '10px',
                                            display: 'flex',
                                            gap: '5px',
                                            flexDirection: person.id === message.sender ? 'row-reverse' : 'row',
                                            marginRight: '2px',
                                            marginLeft: '2px',
                                            color: 'grey'
                                        }}>
                                        {person.id === message.sender && <ConfiguratedContainer
                                            configuration={{
                                                marginTop: '-4px'
                                            }}>
                                            <DoneAllIcon color={text.read ? 'success' : ''} sx={{ fontSize: 20 }} /></ConfiguratedContainer>}
                                        <ConfiguratedContainer>{this.displayTime(text.time)}</ConfiguratedContainer>
                                    </ConfiguratedContainer>}
                                </ConfiguratedContainer>
                            })}
                        </ConfiguratedContainer>
                    </ConfiguratedContainer>
                })}
                {messages.length > 0 && total >= this.state.count && <ConfiguratedContainer configuration={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '10px',
                    // cursor: 'pointer'
                }}>
                    <IconButton onClick={this.getMoreMessages}>
                        <AddCircle />
                    </IconButton>
                </ConfiguratedContainer>}
            </ConfiguratedContainer>
        );
    }
}

function mapStoreToProps(store) {
    return {
        token: store.persons.token,
        chat: store.chats.chat,
        person: store.persons.person,
        messages: store.messages.messages,
        total: store.messages.total,
        count: store.messages.count
    };
}

export default withTranslation()(connect(mapStoreToProps, { getMessages, toggleLikeMessage })(Messages));