import { styled } from '@mui/material/styles';
// import { Outlet } from 'react-router-dom';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import Header from '../header/Header';
import DashboardSidebar from '../sidebar/DashboardSidebar';
import MatchModal from './MatchModal';
import CancelMatchModal from './CancelMatchModal';
import { viewNewMatch, viewCancelMatch, getMatch } from '../../actions/match';
import { withRouter } from 'components/common';

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
    display: 'flex',
    height: '100%',
    overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
    overflow: 'auto',
    height: '100%',
    width: '100%',
    paddingLeft: '15px',
    paddingTop: APP_BAR_MOBILE,
    paddingBottom: 0,
    marginTop: '10px',
    [theme.breakpoints.up('lg')]: {
        paddingTop: APP_BAR_DESKTOP + 24,
        paddingBottom: 10,
        marginTop: '0'
    }
}));

class HeaderMenuLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            openModal: false,
            openCancelModal: false
        };
        this.setOpen = this.setOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleCancelMatchClose = this.handleCancelMatchClose.bind(this);
    }

    handleClose() {
        this.props.viewNewMatch();
        this.setState({ openModal: false });
        const { navigate } = this.props;
        navigate('/match');
    };

    handleCancelMatchClose() {
        this.props.viewCancelMatch();

        this.setState({ openCancelModal: false });
        const { navigate } = this.props;
        navigate('/partners');
    };

    setOpen(open) {
        this.setState({ open });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.newMatch !== this.props.newMatch) {
            this.setState({ openModal: nextProps.newMatch });
            this.props.getMatch();
        }
        if (nextProps.eventCancelMatch !== this.props.eventCancelMatch) {
            this.setState({ openCancelModal: nextProps.eventCancelMatch });
        }
    }

    render() {
        const { open } = this.state;
        const { Outlet, person } = this.props;
        return (
            <RootStyle>
                <Header onOpenSidebar={() => this.setOpen(true)} />
                <DashboardSidebar person={person} isOpenSidebar={open} onCloseSidebar={() => this.setOpen(false)} />
                <MainStyle>
                    <Outlet />
                    <MatchModal
                        openModal={this.state.openModal}
                        onClose={this.handleClose}
                    />
                    <CancelMatchModal
                        openModal={this.state.openCancelModal}
                        onClose={this.handleCancelMatchClose}
                    />
                </MainStyle>
            </RootStyle>
        );
    }
}

function mapStoreToProps(store) {
    return {
        person: store.persons.person,
        newMatch: store.match.newMatch,
        eventCancelMatch: store.match.eventCancelMatch
    };
}

export default withRouter(connect(mapStoreToProps, { viewNewMatch, viewCancelMatch, getMatch })(HeaderMenuLayout));
