import * as types from '../constants/ActionTypes';

const defaultState = {
  chat: {},
  chats: []
};

export default function configuration(state = defaultState, action) {
  switch (action.type) {
    case types.GET_CHAT: {
      return { ...state, chat: {...action.payload} };
    }
    case types.GET_CHATS: {
      return { ...state, chats: [...action.payload] };
    }
    default:
      return { ...state }
  }
}
