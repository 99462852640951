import socketIOClient from 'socket.io-client';
import * as types from '../constants/ActionTypes';
import { API_URL } from 'config';
import { logout } from './authentication';


export function getSocketConnection(personId: number, token: string) {
    return function (dispatch) {
        try {
            const socket = socketIOClient(API_URL, {
                query: {
                    personId,
                    token
                }
            });

            dispatch({
                type: types.CONNECTION_ESTABLISHED,
                payload: socket
            });

        } catch(e: any) {
            if (e.response.data.code === "TOKEN_EXPIRED") {
                dispatch(logout())
            }
            console.log(e);
        }
    }
}

