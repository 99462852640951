import { Component } from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { IconButton } from '@mui/material';
import Link from '@mui/material/Link';

import { ConfiguratedContainer, withRouter } from 'components/common';

class ProfileStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expand: false
        };
        this.toggleExpand = this.toggleExpand.bind(this);
        this.goToPartners = this.goToPartners.bind(this);
    }

    toggleExpand() {
        this.setState({
            expand: !this.state.expand
        });
    }

    goToPartners() {
        const { navigate } = this.props;
        navigate('/partners');
    }

    render() {
        const { person, success = false } = this.props;
        return (
            <ConfiguratedContainer configuration={{
                // position: 'relative',
                cursor: 'pointer'
            }}>
                {person.active && success && <ConfiguratedContainer configuration={{
                    display: 'flex',
                    // color: 'white',
                    padding: '10px 20px',
                    marginRight: '20px',
                    borderRadius: '10px',
                    borderRolor: '#81C784',
                    position: 'absolute',
                    zIndex: 3000,
                    marginTop: '-100px',
                    cursor: 'pointer',
                    backgroundColor: '#A5D6A7',
                    marginLeft: '200px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    ['@media (max-width: 1200px)']: { // eslint-disable-line no-useless-computed-key
                        marginTop: '-80px',
                    },
                    ['@media (max-width: 613px)']: { // eslint-disable-line no-useless-computed-key
                        marginLeft: 'calc(50% - 160px)',
                    }

                }}>
                    <ConfiguratedContainer configuration={{ marginRight: '8px' }}><b>Profile Active</b></ConfiguratedContainer>
                    <Link href="/partners" underline="always" color="inherit">
                        Start your journey
                    </Link>
                </ConfiguratedContainer>}

                {!person.active && <ConfiguratedContainer configuration={{
                    padding: ' 0 20px',
                    minHeight: '50x',
                    backgroundColor: '#FFCDD2',
                    marginRight: '20px',
                    borderRadius: '10px',
                    borderColor: '#81C784',
                    paddingTop: '10px',
                    position: 'absolute',
                    zIndex: 3000,
                    marginTop: '-90px',
                    marginLeft: this.state.expand ? '0px' : '250px',
                    maxWidth: '100%',
                    ['@media (max-width: 1200px)']: { // eslint-disable-line no-useless-computed-key
                        marginTop: '-83px',
                        marginLeft: this.state.expand ? '0px' : '250px',
                    },
                    ['@media (max-width: 613px)']: { // eslint-disable-line no-useless-computed-key
                        marginLeft: this.state.expand ? '0px' : 'calc(50% - 140px)',
                    }
                }}
                    onClick={this.toggleExpand}>
                    <ConfiguratedContainer configuration={{ height: '1px' }} />
                    <ConfiguratedContainer configuration={{
                        color: '#D50000',
                        display: 'flex',
                        gap: '10px',
                        alignItems: 'center',
                        marginBottom: '10px',
                    }}>
                        <ConfiguratedContainer configuration={{
                            color: '#D50000',
                            fontSize: '14px'
                        }}><b>Profile Currently Inactive{!this.state.expand &&<span>, expand for more</span>} </b> {this.state.expand && <span>(Please fill in the missing profile information)</span>}</ConfiguratedContainer>
                        <IconButton>
                            <HelpOutlineIcon color={'error'} />
                        </IconButton>
                    </ConfiguratedContainer>
                    {this.state.expand && <ConfiguratedContainer configuration={{
                        display: 'flex',
                        gap: '10px',
                        flexWrap: 'wrap',
                        fontSize: '12px',
                        marginBottom: '19px',
                        '>div': {
                            color: '#D50000',
                        }
                    }}>
                        {!person.image && <div>-Profile Image</div>}
                        {!person.firstname && <div>-Name</div>}
                        {!person.gender && <div>-Your Gender</div>}
                        {!person.togender && <div>-Gender of Interest</div>}
                        {!person.birthyear && <div>-Birth Year</div>}
                        {!person.countryvalue && <div>-Country</div>}
                        {!person.cityvalue && <div>-City</div>}
                        {!person.position && <div>-Occupation</div>}
                        {!person.description && <div>-Description</div>}
                    </ConfiguratedContainer>}
                    <ConfiguratedContainer configuration={{ height: '1px' }} />

                </ConfiguratedContainer>}
            </ConfiguratedContainer>

        );
    }
}

export default withRouter(ProfileStatus);