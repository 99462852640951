import { Component } from 'react';
import { connect } from 'react-redux';
import ChatInformation from 'components/messages/chat/ChatInformation';
import Messages from 'components/messages/message/Messages';
import TextSender from 'components/messages/message/TextSender';
import { ConfiguratedContainer, withRouter } from 'components/common';
import { getChat } from 'actions/chat';
import { readMessages } from 'actions/messages';
import { Button } from '@mui/material';

class MessagesPage extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.readMessages = this.readMessages.bind(this);
        this.goToPartners = this.goToPartners.bind(this);
    }

    goToPartners() {
        const { navigate } = this.props;
        navigate('/home');
    }

    readMessages() {
        const { messages, chat, person, partner } = this.props;
        if (messages.length > 0 && messages[0].texts.length > 0 && !messages[0].texts[messages[0].texts.length - 1].read) {
            this.props.readMessages(chat.id, person.id, partner.id);
        }
    }

    UNSAFE_componentWillMount() {
        let { person } = this.props;
        this.props.getChat(person.id);
    }

    render() {
        const { person, chat } = this.props;
        let partnerId = chat.personId1;
        if (person.id === chat.personId1) {
            partnerId = chat.personId2;
        }
        return (
            <ConfiguratedContainer configuration={{
                display: 'flex',
                height: 'calc(100% + 33px)',
                marginRight: '15px',
                border: 'solid lightgrey 1px',
                borderRadius: '5px',
                margin: 0,
                marginTop: '-23px',
                marginLeft: '-15px',
                ['@media (max-width: 1200px)']: { // eslint-disable-line no-useless-computed-key
                    marginTop: -10,
                    height: 'calc(100% - 0px)',
                    // border: 'solid'
                },
                ['@media (max-width: 425px)']: { // eslint-disable-line no-useless-computed-key
                    margin: 0,
                    marginLeft: '-15px',
                    marginTop: '-10px',
                    height: '100%',
                }
            }}
                onClick={this.readMessages}
                onFocus={this.readMessages}>
                {/* {person.id && <Chats person={person} />} */}
                {chat && chat.id && <ConfiguratedContainer configuration={{
                    flex: 1,
                    display: 'flex',

                    flexDirection: 'column',
                    ['@media (max-width: 425px)']: { // eslint-disable-line no-useless-computed-key
                        width: '100%',
                    }
                }}>
                    <ChatInformation chat={chat} personId={person.id} />
                    <Messages chatId={chat.id} />
                    <TextSender chatId={chat.id} partnerId={partnerId} />
                </ConfiguratedContainer>}
                {(!chat || !chat.id) && <ConfiguratedContainer configuration={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                    flexDirection: 'column',
                    gap: '30px',
                    marginTop: '20px',
                    margin: 'auto'
                }}>
                    <b> Please find a partner to start a conversation </b>
                    <Button
                        fullWidth
                        onClick={this.goToPartners}
                        variant="outlined"
                    >
                        Search a new partner
                    </Button>
                </ConfiguratedContainer>}
            </ConfiguratedContainer>
        );
    }
}

function mapStoreToProps(store) {
    return {
        messages: store.messages.messages,
        partner: store.persons.partner,
        person: store.persons.person,
        chat: store.chats.chat
    };
}

export default withRouter(connect(mapStoreToProps, { getChat, readMessages })(MessagesPage));