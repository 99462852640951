import PropTypes from 'prop-types';
import React, { Component } from 'react';
// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton } from '@mui/material';
// components
import Iconify from '../Iconify';
//
import { connect } from 'react-redux';
import { logout } from '../../actions/authentication';
import { Link as RouterLink } from 'react-router-dom';
// material
// import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import UnAuthAccountPopover from './UnAuthAccountPopover';

import { ConfiguratedContainer } from 'components/common';
import { withTheme } from '@emotion/react';
import { withTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------


class Header extends Component {
  constructor(props) {
    super(props);
    this.onOpenSidebarAction = this.onOpenSidebarAction.bind(this);
  }

  onOpenSidebarAction() {
    this.props.onOpenSidebar();
  }

  render() {
    const { person, logout, theme, notifications, t } = this.props;
    return (
      <RootStyle>
        <ToolbarStyle>
          <IconButton
            onClick={this.onOpenSidebarAction}
            sx={{ mr: 1, color: 'text.primary', display: { lg: 'none' } }}
          >
            <Iconify icon="eva:menu-2-fill" />
          </IconButton>
          <Box sx={{ flexGrow: 1 }} />
          <ConfiguratedContainer configuration={{
            height: '32px',
            marginTop: '10px',
            display: 'flex',
            alignItems: 'center',
            ['@media (min-width: 1200px)']: { // eslint-disable-line no-useless-computed-key
              display: 'none'
            },
          }}>
            <RouterLink to="/home">
              <Box component="img" src="/static/logo.png" alt='Monotryst' sx={{ height: 50 }} />
            </RouterLink>
          </ConfiguratedContainer>

          {/* <Searchbar /> */}
          <Box sx={{ flexGrow: 1 }} />
          {person && <ConfiguratedContainer configuration={{
            marginRight: '10px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            ['@media (max-width: 1200px)']: { // eslint-disable-line no-useless-computed-key
              display: 'none'
            },
          }}>
            <ConfiguratedContainer configuration={{
              color: theme.palette.primary.main,
              fontWeight: '700'
            }}>
              {person.firstname} 
            </ConfiguratedContainer>
            {!person.active && <ConfiguratedContainer configuration={{
              color: theme.palette.primary.error,
              fontSize: '14px',
              textAlign: 'center'
            }}>
              {t('header.notActive')}
            </ConfiguratedContainer>}
          </ConfiguratedContainer>}

          <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
            {/* <LanguagePopover /> */}
            {/* <NotificationsPopover /> */}

            {person && <AccountPopover person={person} logout={this.props.logout} notifications={notifications} />}
            {!person && <UnAuthAccountPopover />}
          </Stack>
        </ToolbarStyle>
      </RootStyle>
    );
  }
}

Header.propTypes = {
  onOpenSidebar: PropTypes.func
};

function mapStoreToProps(store) {
  return {
    person: store.persons.person,
    notifications: store.notifications.notifications
  };
}

export default withTranslation()(withTheme(connect(mapStoreToProps, { logout })(Header)));

