import { Component } from 'react';
import { withTheme } from '@mui/styles';
import { connect } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';
import CommonInput from 'components/common/CommonInput';
import { regex } from '../../config';
import { ConfiguratedContainer, withRouter } from 'components/common';
import { confirmEmail } from '../../actions/authentication';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

class ConfirmRegistry extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmitting: false
        };
        this.isValid = this.isValid.bind(this);
        this.goToLogin = this.goToLogin.bind(this);
        this.onValueChange = this.onValueChange.bind(this);
        this.goToRequestEmailConfirmation = this.goToRequestEmailConfirmation.bind(this);
    };

    UNSAFE_componentWillMount() {
        const { params } = this.props;
        this.setState({
            isSubmitting: true
        }, async () => {
            try {
                await this.props.confirmEmail(params.token);
                this.setState({
                    isSubmitting: false,
                    success: "TOKEN_CONFIRMED"
                });
            } catch (err) {
                this.setState({
                    isSubmitting: false,
                    error: "TOKEN_DENIED"
                });
            }
        });

    }
    goToRequestEmailConfirmation() {
        const { navigate } = this.props;
        navigate('/requestconfirm');
    }

    isValid() {
        const { email } = this.state;
        if (
            email && regex.emailRegex.test(email)
        ) {
            this.setState({ isValid: true });
        } else {
            this.setState({ isValid: false });
        }
    }

    async goToLogin() {
        const { navigate } = this.props;
        navigate('/login');
    }

    onValueChange(data) {
        this.setState({
            [data.name]: data.value
        }, () => {
            this.isValid()
        });
    }


    render() {
        const {
            isSubmitting,
            error,
            success,
            email
        } = this.state;
        return (
            <ConfiguratedContainer configuration={{
                display: 'table',
                height: '100%',
                width: '100%',
                ['@media (max-width: 425px)']: { // eslint-disable-line no-useless-computed-key
                },
            }}>
                <ConfiguratedContainer configuration={{
                    display: 'table-cell',
                    verticalAlign: 'middle',
                    ['@media (max-width: 425px)']: { // eslint-disable-line no-useless-computed-key
                        height: '100%',
                    },
                }}>
                    <ConfiguratedContainer configuration={{
                        backgroundColor: 'white',
                        width: '375px',
                        margin: 'auto',
                        padding: '20px',
                        borderRadius: '5px',
                        ['@media (max-width: 425px)']: { // eslint-disable-line no-useless-computed-key
                            height: 'calc(100% + 100px)',
                            paddingTop: '120px',
                        },
                    }}>
                        <ConfiguratedContainer configuration={{
                            display: 'flex',
                            justifyContent: 'center',
                        }} >
                            <img alt='Monotryst' src='/static/logo.png' />
                        </ConfiguratedContainer>
                        {this.state.isSubmitting && <ConfiguratedContainer configuration={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}>
                            <Box sx={{ display: 'flex' }}>
                                <CircularProgress />
                            </Box></ConfiguratedContainer>}
                        {!this.state.error && !this.state.success && <ConfiguratedContainer>


                            <h>{isSubmitting} </h>
                            <h>{success} </h>
                            <h>{error} </h>

                            <ConfiguratedContainer configuration={{
                                marginBottom: '20px'
                            }}>
                            </ConfiguratedContainer>
                        </ConfiguratedContainer>}
                        {this.state.error && <ConfiguratedContainer>
                            <p>This link is expired or invalid</p>
                            <p>A new Validation email has been sent to you</p>
                            <p>Please check you inbox for the new validation email</p>

                        </ConfiguratedContainer>}

                        {this.state.success && <ConfiguratedContainer>
                            <p>Your email has been successfully validated. </p>
                            <p> You can now log in and start using the website. </p>
                            <p>Thank you for confirming. </p>

                            <ConfiguratedContainer configuration={{
                                marginTop: '10px'
                            }}>
                                <LoadingButton
                                    fullWidth
                                    onClick={this.goToLogin}
                                    loading={this.state.isSubmitting}
                                    loadingPosition="start"
                                    startIcon={<div />}
                                    variant="contained">
                                    Go Login
                                </LoadingButton>
                            </ConfiguratedContainer>
                        </ConfiguratedContainer>}
                    </ConfiguratedContainer>
                </ConfiguratedContainer>
            </ConfiguratedContainer>
        );
    }
}

function mapStoreToProps(store) {
    return {};
}

export default withRouter(withTheme(connect(mapStoreToProps, { confirmEmail })(ConfirmRegistry)));