import { Component } from 'react';
import { Avatar, IconButton} from '@mui/material';
import { API_URL } from '../../../config';
import { ConfiguratedContainer, withRouter } from 'components/common';
import { withTheme } from '@emotion/react';
import Skeleton from '@mui/material/Skeleton';
import { ArrowBack } from '@mui/icons-material';

class ChatInformation extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.goToProfile = this.goToProfile.bind(this);
        this.goToMessages = this.goToMessages.bind(this);
    }

    goToProfile() {
        const { navigate, personId, chat, archive } = this.props;
        let parnterId = chat.personId1;
        if (personId === chat.personId1) {
            parnterId = chat.personId2;
        }
        if (archive) {
            navigate(`/person/${parnterId}`);
        } else {
            navigate(`/match`);
        }
    }

    goToMessages() {
        const { navigate } = this.props;
        navigate('/chat/');
    }

    render() {
        const { theme, chat, personId, archive } = this.props;
        let partnerName = null;
        let parnterId = null;
        if (chat.p1firstname && chat.p2firstname) {
            partnerName = `${chat.p1firstname}`;
            parnterId = chat.personId1;
            if (personId === chat.personId1) {
                partnerName = `${chat.p2firstname}`;
                parnterId = chat.personId2;
            }
        }
        return (
            <ConfiguratedContainer configuration={{
                border: 'solid lightgrey 1px',
                backgroundColor: 'lightgrey ',
                height: '50px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <ConfiguratedContainer configuration={{
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'space-between'
                }}>
                    {archive && <ConfiguratedContainer configuration={{
                        display: 'flex',
                        // border: 'solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        // ['@media (min-width: 425px)']: { // eslint-disable-line no-useless-computed-key
                        //     display: 'none'
                        // },
                    }}>
                        <IconButton onClick={this.goToMessages}>
                            <ArrowBack />
                        </IconButton>
                    </ConfiguratedContainer>}
                    <ConfiguratedContainer configuration={{
                        flex: 10,
                        display: 'flex',
                        marginLeft: '10px',
                        alignItems: 'center',
                    }}>
                        {parnterId ?
                            <ConfiguratedContainer
                                configuration={{
                                    cursor: 'pointer'
                                }}
                                onClick={this.goToProfile}>
                                <Avatar src={`${API_URL}/api/files/person/${parnterId}`} alt="photoURL" />
                            </ConfiguratedContainer>
                            :
                            <Skeleton variant="circular" width={40} height={40} />
                        }
                        <ConfiguratedContainer configuration={{
                            width: '10px'
                        }}></ConfiguratedContainer>
                        <ConfiguratedContainer configuration={{}}>
                            <ConfiguratedContainer
                                configuration={{
                                    alignSelf: 'center',
                                    color: theme.palette.primary.main,
                                    fontWeight: '500',
                                    fontSize: '18px',
                                    cursor: 'pointer',
                                }}
                                onClick={this.goToProfile}>
                                {partnerName ? partnerName : <Skeleton variant="text" color="red" sx={{ fontSize: '18px' }} width={150} />}
                            </ConfiguratedContainer>
                        </ConfiguratedContainer>
                    </ConfiguratedContainer>
                </ConfiguratedContainer>
            </ConfiguratedContainer >
        );
    }
}

export default withRouter(withTheme(ChatInformation));