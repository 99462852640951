import * as types from '../constants/ActionTypes';

const defaultState = {
  socket: null,
};

export default function configuration(state = defaultState, action) {
  switch (action.type) {
    case types.CONNECTION_ESTABLISHED: {
      return { ...state, socket: action.payload };
    }
    default:
      return { ...state }
  }
}
