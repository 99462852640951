import React, { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { alpha } from '@mui/material/styles';
import { Button, Box, Divider, MenuItem, Typography, Avatar, IconButton } from '@mui/material';
import Iconify from '../Iconify';
import MenuPopover from './MenuPopover';
import { API_URL } from '../../config';
import { ConfiguratedContainer, withRouter } from 'components/common';
import { withTranslation } from 'react-i18next';

function AccountPopover({ person, t, logout, navigate, notifications }) {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const onLogoutAction = () => {
    logout();
    navigate('/home')
  };

  const imageUrl = `${API_URL}/api/files/${person.image}`;

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          border: notifications.length > 0 ? 'red 2px solid' : '',
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',

              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        {!person.image && <Avatar src={'/static/profile.png'} alt={t('account.content.image')} />}
        {person.image && <Avatar src={imageUrl} alt={t('account.content.image')} />}
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {person.firstname}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {person.email}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {[
          {
            label: t('account.content.settings'),
            icon: 'ant-design:setting-filled',
            linkTo: '/profile'
          },
          {
            label: t('account.content.terms'),
            icon: 'fluent:gavel-24-filled',
            linkTo: '/terms-conditions'
          }
        ].map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Iconify
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24
              }}
            />
            {option.label}
          </MenuItem>
        ))}

        {notifications.length > 0 && <MenuItem
          to={'/chat'}
          component={RouterLink}
          onClick={handleClose}
          sx={{ typography: 'body2', py: 1, px: 2.5 }}
        >
          <Iconify
            icon={'mdi:bell-notification'}
            sx={{
              mr: 2,
              width: 24,
              height: 24
            }}
            color={"#E53935"}
          />
          <ConfiguratedContainer configuration={{
            color: "#E53935"
          }}>
            {t('account.content.messages')}
          </ConfiguratedContainer>
        </MenuItem>}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={onLogoutAction}>
            {t('account.content.logout')}
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}

export default withTranslation()(withRouter(AccountPopover));
