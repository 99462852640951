import { Component } from 'react';
import { connect } from 'react-redux';
import { API_URL } from '../../config';
import { Avatar } from '@mui/material';
import { Button } from '@mui/material';

import { ConfiguratedContainer, withRouter } from 'components/common';
import { withTranslation } from 'react-i18next';

class NoPartnersAllowed extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.goToMessages = this.goToMessages.bind(this);
        this.goToMatch = this.goToMatch.bind(this);
    }
    goToMatch() {
        const { navigate } = this.props;
        navigate('/match/');
    }

    goToMessages() {
        const { navigate } = this.props;
        navigate('/chat/');
    }

    render() {
        const { partner, t } = this.props;
        return (
            <ConfiguratedContainer configuration={{
                ['@media (max-width: 1200px)']: { // eslint-disable-line no-useless-computed-key
                    marginBottom: '30px',
                },
            }}>
                <ConfiguratedContainer configuration={{
                    textAlign: 'center',
                    padding: '30px',
                    fontFamily: "Public Sans,sans-serif",
                    color: '#0D47A1',
                }}>
                    <p>{t('match.content.message1')} </p>

                    <ConfiguratedContainer configuration={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        {partner && partner.id && <Avatar sx={{ height: '170px', width: '170px' }} src={`${API_URL}/api/files/person/${partner.id}`} />}
                    </ConfiguratedContainer>

                    <p>{t('match.content.message2')} <b>{partner.firstname}</b></p>

                    <ConfiguratedContainer configuration={{
                        display: 'flex',
                        marginTop: '20px',
                        gap: '10px',
                        ['@media (max-width: 505px)']: { // eslint-disable-line no-useless-computed-key
                            flexDirection: 'column',
                            gap: '20px',

                        }
                    }}>
                        <ConfiguratedContainer configuration={{
                            flex: 1
                        }}>
                            <Button
                                fullWidth
                                onClick={this.goToMatch}
                                variant="outlined"
                            >
                                {t('match.buttons.viewProfile')}
                            </Button>
                        </ConfiguratedContainer>
                        <ConfiguratedContainer configuration={{
                            flex: 1

                        }}>
                            <Button
                                fullWidth
                                onClick={this.goToMessages}
                                variant="outlined"
                                color="success"
                            >
                                {t('match.buttons.messages')}
                            </Button>
                        </ConfiguratedContainer>
                    </ConfiguratedContainer>


                </ConfiguratedContainer>
            </ConfiguratedContainer>
        );
    }
}

function mapStoreToProps(store) {
    return {
        person: store.persons.person,
        partner: store.persons.partner,
        match: store.match.match,
    };
}

export default withTranslation()(withRouter(connect(mapStoreToProps, {})(NoPartnersAllowed)));