import React from 'react';
import { connect } from 'react-redux';
import { uploadProfileImage, updateProfileImage, deleteImage } from '../../../actions/person';
import { API_URL } from '../../../config';
import EditIcon from '@mui/icons-material/Add';
import './index.css';
import { ConfiguratedContainer } from '../containers';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonIcon from '@mui/icons-material/Person';
import DoneIcon from '@mui/icons-material/DoneAll';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import { withTranslation } from 'react-i18next';

async function compressImage(file, maxWidth = 600, maxHeight = 600, quality = 0.8) {
  return new Promise((resolve, reject) => {
    const img = new Image(); // Use Image here
    img.src = URL.createObjectURL(file);

    img.onload = async () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      let newWidth = img.width;
      let newHeight = img.height;

      if (img.width > maxWidth) {
        newWidth = maxWidth;
        newHeight = (img.height * maxWidth) / img.width;
      }

      if (newHeight > maxHeight) {
        newHeight = maxHeight;
        newWidth = (img.width * maxHeight) / img.height;
      }

      canvas.width = newWidth;
      canvas.height = newHeight;

      ctx.drawImage(img, 0, 0, newWidth, newHeight);

      canvas.toBlob(
        (blob) => {
          resolve(blob);
        },
        'image/jpeg',
        quality
      );
    };

    img.onerror = (error) => {
      reject(error);
    };
  });
}

function MyComponent(n = 0) {
  const elements = [];

  for (let i = 0; i < n; i++) {
    elements.push(
      <ConfiguratedContainer
        configuration={{
          borderRadius: '10px',
          height: '300px',
          width: '32%',
          marginBottom: '10px',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          position: 'relative',
          cursor: 'pointer',
          ['@media (max-width: 728px)']: { // eslint-disable-line no-useless-computed-key
            height: '200px'
          },
          ['@media (max-width: 505px)']: { // eslint-disable-line no-useless-computed-key
            height: '130px'
          }
        }}
      >
        <Skeleton variant="rounded" height={'100%'} width={'100%'} />

      </ConfiguratedContainer>
    );
  }

  return elements;
}


class ProfileImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageURl: props.person.image ? `${API_URL}/api/files/person/${props.person.id}` : '/static/profile.png'
    };
    this.fileSelectedHandler = this.fileSelectedHandler.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onSelectProfileImage = this.onSelectProfileImage.bind(this);
  }

  async fileSelectedHandler(event) {
    const { person } = this.props;
    event.preventDefault();

    const images = Array.from(event.target.files).slice(0, 9 - (person.images || []).length);
    const data = new FormData();
    data.append('personId', person.id)
    for (const f of images) {
      data.append('files', await compressImage(f));
    }
    this.setState({
      imagesCount: images.length,
      imagesLoading: true,
    }, async () => {
      try {
        await this.props.uploadProfileImage(data);
        this.props.onSuccess();
      } finally {
        this.setState({
          imagesCount: 0,
          imagesLoading: false
        });
      }
    })

  }

  onDelete(imageId) {
    const { person } = this.props;

    this.props.deleteImage(person.id, imageId);
  }

  async onSelectProfileImage(imageId) {
    const { person } = this.props;
    await this.props.updateProfileImage({personId: person.id, imageId});
    // window.location.reload();
  }

  render() {
    const { person, t } = this.props;
    return (
      <div className="profile-image-wrapper">
        <ConfiguratedContainer configuration={{
          width: 'calc(100% - 20px)',
          display: 'flex',
          margin: '20px 0px',
          flexWrap: 'wrap',
          gap: 'calc(4% / 2)'
        }}>
          {(person.images || []).map((image, index) => {
            const url = `${API_URL}/api/files/${image.id}`;
            return (
              <ConfiguratedContainer
                key={image.id}
                configuration={{
                  border: 'grey 1px solid',
                  borderRadius: '10px',
                  height: '300px',
                  width: '32%',
                  marginBottom: '10px',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  position: 'relative',
                  cursor: 'pointer',
                  ['@media (max-width: 728px)']: { // eslint-disable-line no-useless-computed-key
                    height: '200px'
                  },
                  ['@media (max-width: 505px)']: { // eslint-disable-line no-useless-computed-key
                    height: '130px'
                  }
                }}
                style={{ backgroundImage: `url(${url})` }}>
                  <ConfiguratedContainer configuration={{
                    backgroundColor: 'rgba(1,1,1, 0.5)',
                    width: '100%',
                    height: '40px',
                    position: 'absolute',
                    borderBottom: '1px dotted #808080',
                    borderRadius: '5px 5px 0 0'
                  }}>
                  </ConfiguratedContainer>
                  {(image.id === +person.image) && <ConfiguratedContainer configuration={{
                  position: 'absolute',
                  left: '0px',
                  ['@media (max-width: 505px)']: { // eslint-disable-line no-useless-computed-key
                    left: '-10px',
                    top: '-7px',
                  }
                }}>
                  
                  <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                    <DoneIcon color='success' />
                  </IconButton>
                </ConfiguratedContainer>}
                {!(image.id === +person.image) && <ConfiguratedContainer configuration={{
                  position: 'absolute',
                  left: '0px',
                  ['@media (max-width: 505px)']: { // eslint-disable-line no-useless-computed-key
                    left: '-10px',
                    top: '-7px',
                  }
                }}>
                  <IconButton type="button" sx={{ p: '10px' }} aria-label="search"  onClick={() => this.onSelectProfileImage(image.id)}>
                    <PersonIcon color='primary' />
                  </IconButton>
                </ConfiguratedContainer>}

                <ConfiguratedContainer configuration={{
                  position: 'absolute',
                  right: '0px',
                  ['@media (max-width: 505px)']: { // eslint-disable-line no-useless-computed-key
                    right: '-10px',
                    top: '-7px',
                  }
                }}>
                  {image.id !== +person.image && <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={() => this.onDelete(image.id)}>
                    <DeleteIcon color='error'  />
                  </IconButton>}
                </ConfiguratedContainer>
              </ConfiguratedContainer>
            )
          })}
          {MyComponent(this.state.imagesCount)}

          {!this.state.imagesLoading && (person.images || []).length < 9 && <div className="profile-upload">
            {!this.state.imageLoading && <div className="profile-edit">
              <input
                id='imageUpload'
                onChange={this.fileSelectedHandler}
                multiple
                min={0}
                max={9 - (person.images || []).length}
                type='file'
                accept=".png, .jpg, .jpeg" />
              <label className="profile-edit-label" htmlFor="imageUpload">
                <EditIcon color={'success'} className="profile-edit-icon" />
                <ConfiguratedContainer configuration={{
                  ['@media (max-width: 728px)']: { // eslint-disable-line no-useless-computed-key
                    fontSize: '12px'
                  },
                }}>{t('profileImage.image.upload.message', {count: 9 - (person.images || []).length})}
                 </ConfiguratedContainer>
              </label>
            </div>}

          </div>}
        </ConfiguratedContainer>

      </div>
    );
  }
}


export default withTranslation()(connect(() => { return {}; }, { uploadProfileImage, deleteImage, updateProfileImage })(ProfileImage));
