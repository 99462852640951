import { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from '@mui/material';
import { getPerson, deleteProfile } from 'actions/person';
import { logout } from 'actions/authentication';
import { ConfiguratedContainer, withRouter } from 'components/common';
import { regex } from '../../config';
import CommonText from '../../components/common/CommonText';
import DeleteDialog from './DeleteDialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { withTranslation } from 'react-i18next';

class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: '',
            openConfirm: false
        };
        this.deleteProfile = this.deleteProfile.bind(this);
        this.openConfirmCancelMatch = this.openConfirmCancelMatch.bind(this);
        this.closeConfirmCancelMatch = this.closeConfirmCancelMatch.bind(this);
        this.onFieldChange = this.onFieldChange.bind(this);
    }

    openConfirmCancelMatch() {
        this.setState({ openConfirm: true });
    }

    closeConfirmCancelMatch() {
        this.setState({ openConfirm: false });
    }

    UNSAFE_componentWillMount() {
        const { person } = this.props;
        this.props.getPerson(person.id)
    }

    onFieldChange(data) {
        this.setState({ [data.name]: data.value });
    }

    async deleteProfile() {
        await this.props.deleteProfile(this.state.message);
        await this.props.logout();
        this.props.navigate('/home');
    }

    render() {
        const { openConfirm } = this.state;
        const { t } = this.props;
        return (
                <Dialog
                    sx={{
                        width: '100%'
                    }}
                    open={this.props.open}
                    onClose={this.props.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
          {t('delete.content.deleteProfile')}
        </DialogTitle>
        <DialogContent
            sx={{
                width: '400px',
                paddingRight: '-20px'

            }}
        >
                        <CommonText
                            name='message'
                            edit={true}
                            title={t('delete.content.message')}
                            value={this.state.message}
                            onValueChange={this.onFieldChange}
                            error={'Message is required'}
                            regex={regex.textRegex}
                            maxLength={255}
                        />
                        <Button
                            //fullWidth
                            disabled={!this.state.message}
                            onClick={this.openConfirmCancelMatch}
                            variant="outlined"
                            color="error"
                        >
                            {t('delete.buttons.delete')}
                        </Button>

                        <DeleteDialog
                            open={openConfirm}
                            handleClose={this.closeConfirmCancelMatch}
                            confirmDelete={this.deleteProfile}
                        />
                    </DialogContent>
                </Dialog>
        );
    }
}

function mapStoreToProps(store) {
    return {
        person: store.persons.person
    };
}

export default withTranslation()(withRouter(connect(mapStoreToProps, { getPerson, deleteProfile, logout })(Settings)));