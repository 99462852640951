import * as types from '../constants/ActionTypes';
import axios from 'axios';
import { API_URL } from '../config';
import history from '../history';

export function login(person) {
    return function (dispatch) {
        return axios.post(`${API_URL}/api/persons/login`, person)
            .then(response => {
                const personData = response.data;
                localStorage.setItem("token", personData.token);
                localStorage.setItem("person", JSON.stringify(personData.person));
                dispatch({
                    type: types.GET_PERSON,
                    payload: personData
                });
                if (personData.person.active) {
                    history.push('/home');
                } else {
                    history.push('/edit');
                }
            });
    }
}

export function register(person) {
    return function (dispatch) {
        return axios.post(`${API_URL}/api/persons/register`, person)
            .then(response => {
                dispatch({
                    type: types.AUTH_SIGNUP,
                });
            });
    }
}

export function logout() {
    return async function (dispatch) {
        try {
            localStorage.clear()
            if ('serviceWorker' in navigator) {
                const registration =  await navigator.serviceWorker.ready;
                const subscription =  await registration.pushManager.getSubscription();
                if (subscription) {
                    axios.post(`${API_URL}/api/subscription/delete`, subscription);
                    await subscription.unsubscribe();
                }
            }
        } finally {
            for (let i = 0; i < localStorage.length; i++) {
                const key = localStorage.key(i);
                if (key) {
                    localStorage.removeItem(key);
                }
            }
            if ('caches' in window) {
                caches.keys().then(function(names) {
                    for (let name of names) {
                        caches.delete(name);
                    }
                });
            }
        }
        return dispatch({
            type: types.AUTH_LOGOUT,
        });
    }

}

export function requestResetPassword(email) {
    return function (dispatch) {
        return axios.post(`${API_URL}/api/persons/requestreset`, { email });
    }
}

export function confirmEmail(token) {
    return function (dispatch) {
        return axios.post(`${API_URL}/api/persons/confirm`, { token });
    }
}

export function sendValidationEmail(email) {
    return function (dispatch) {
        return axios.post(`${API_URL}/api/persons/sendconfirmation`, { email });
    }
}

export function resetPassword(password, token) {
    return function (dispatch) {
        return axios.post(`${API_URL}/api/persons/changepassword`, {
            token,
            password
        });
    }
}


