import React, { Component } from 'react';
import Home from './pages/Home';
import Partners from './pages/partners/Partners';
import NoPartnersAllowed from './pages/partners/NoPartnersAllowed';
import MatchProfile from './pages/partners/MatchProfile';
import EditProfile from './pages/profile/EditProfile';
import Register from './pages/auth/Register';
import Login from './pages/auth/Login';
import Reset from './pages/auth/Reset';
import ReSendValidation from './pages/auth/ReSendValidation';
import ConfirmRegistry from './pages/auth/ConfirmRegistry';
import MessagesPage from './pages/messages/MessagesPage';
import ArchivedChats from './pages/messages/ArchivedChats';

import { connect } from 'react-redux';
import { Routes, Route, Navigate } from 'react-router-dom';
import HeaderMenuLayout from 'components/layout/HeaderMenuLayout';
import { getSocketConnection } from './actions/connection';
import { getMatch, recieveNewMatch, recieveCancelMatch } from './actions/match';
import { getMessages } from './actions/messages';
import { receiveNotfications, getNotifications } from './actions/notifications';
import { getPartner, getPerson, updateLocation } from './actions/person';
import { withRouter } from 'components/common';
import ChangePassword from 'pages/auth/ChangePassword';
import Settings from 'pages/profile/Settings';
import TermsConditions from 'pages/legal/TermsConditions';
import notification from './ressources/notification.mp3';
import UnAuthHome from 'pages/UnAuthHome';
import { registerWorkers } from 'workers';

class Router extends Component {

  autdio;
  constructor(props) {
    super(props);
    this.playNotificationSound = this.playNotificationSound.bind(this);
    this.audio = new Audio(notification);
  }

  playNotificationSound() {
    this.audio.play();
  }

  async UNSAFE_componentWillMount() {
    const { person, token } = this.props;
    if (token && person) {
      // this.props.getPerson(person.id);
      this.props.getSocketConnection(person.id, token);
      this.props.getNotifications(person.id);
      const match = await this.props.getMatch();
      if (match) {
        let partnerId = match.person1;
        if (partnerId === person.id) {
          partnerId = match.person2;
        }
        this.props.getPartner(partnerId);
      }
      registerWorkers();
    }

  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.person !== this.props.person) {
      const { person, token } = nextProps;
      if (token) {
        this.props.getSocketConnection(person.id, token);
        this.props.getNotifications(person.id);
        const match = await this.props.getMatch();
        if (match) {
          let partnerId = match.person1;
          if (partnerId === person.id) {
            partnerId = match.person2;
          }
          this.props.getPartner(partnerId);
        }
        registerWorkers();
      }
    }
    if (nextProps.socket && nextProps.socket !== this.props.socket) {
      const { socket } = nextProps;
      socket.on('EVENT_GET_MESSAGE', (message) => {
        const { chat } = this.props;
        if (message.chatid === chat.id) {
          this.props.getMessages(message.chatid);
        }
      });
      socket.on('EVENT_GET_NOTIFICATIONS', (notifications) => {
        this.props.receiveNotfications(notifications);
        this.playNotificationSound();
      });
      socket.on('EVENT_NEW_MATCH', async () => {
        const match = await this.props.getMatch(nextProps.person.id);
        if (match) {
          this.props.recieveNewMatch();
        }
      });
      socket.on('EVENT_CANCEL_MATCH', async () => {
          this.props.recieveCancelMatch();
      });

      socket.on('EVENT_MESSAGES_READ', () => {
        const { chat } = this.props;
          this.props.getMessages(chat.id);
      });

      socket.on('EVENT_MESSAGE_LIKE', () => {
        const { chat } = this.props;
        if (chat.id) {
          this.props.getMessages(chat.id);
        }
      });

    }
  }

  componentDidMount() {
    const { person } = this.props;
    if (!person) return;
    // Check if geolocation is available in the browser
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // Extract latitude and longitude from the position object
          const { latitude, longitude } = position.coords;
          const location = {
            id: person.id,
            latitude,
            longitude
          };
          this.props.updateLocation(location);
        },
        (error) => {
          console.error('Error getting geolocation:', error);
        }
      );
    } else {
      console.error('Geolocation is not available in this browser.');
    }
  }

  render() {
    const { person, token, match } = this.props;

    return (
      <Routes>
        <Route path="/" element={<Navigate to="/home" />} />
        {!token && !match && <Route path="/home" element={<UnAuthHome />} /> }
        {token && person && person.active && match && <Route path="/home" element={<HeaderMenuLayout Outlet={NoPartnersAllowed} /> } /> }
        {token && person && person.active && !match && <Route path="/home" element={<HeaderMenuLayout Outlet={Partners} /> } /> }
        {token ? <Route path="/home" element={person && person.active ? <HeaderMenuLayout Outlet={Home} /> : <Navigate replace to="/edit" />} /> : <Route path="/home" element={<UnAuthHome />} />}
        {token ? <Route path="/home" element={person && person.active ? <HeaderMenuLayout Outlet={Home} /> : <Navigate replace to="/edit" />} /> : <Route path="/home" element={<UnAuthHome />} />}
        {token && <Route path="/login" element={<Navigate replace to="/home" />} />}
        {token && <Route path="/register" element={<Navigate replace to="/home" />} />}
        {/* {token && <Route path="/profile" element={<HeaderMenuLayout Outlet={ViewProfile} />} />} */}
        {token && <Route path="/profile" element={<HeaderMenuLayout Outlet={EditProfile} />} />}
        {token && <Route path="/edit" element={<HeaderMenuLayout Outlet={EditProfile} />} />}
        {token && !match && <Route path="/partners" element={<HeaderMenuLayout Outlet={Partners} />} />}
        {token && match && <Route path="/partners" element={<HeaderMenuLayout Outlet={NoPartnersAllowed} />} />}
        {token && <Route path="/chat" element={person.active ? <HeaderMenuLayout Outlet={MessagesPage} /> : <Navigate replace to="/edit" />}>
          <Route path=':chatId' element={person.active ? <HeaderMenuLayout Outlet={MessagesPage} /> : <Navigate replace to="/edit" />} />
        </Route>}
        {token && <Route path="/archive" element={person.active ? <HeaderMenuLayout Outlet={ArchivedChats} /> : <Navigate replace to="/edit" />}>
          <Route path=':chatId' element={person.active ? <HeaderMenuLayout Outlet={ArchivedChats} /> : <Navigate replace to="/edit" />} />
        </Route>}
        {token && <Route path="/person/:id" element={<HeaderMenuLayout Outlet={MatchProfile} />} />}
        {token && match && <Route path="/match" element={<HeaderMenuLayout Outlet={MatchProfile} />} />}
        {!token && <Route path="/login" element={<Login />} />}
        {!token && <Route path="/register" element={<Register />} />}
        {!token && <Route path="/reset" element={<Reset />} />}
        {!token && <Route path="/resend" element={<ReSendValidation />} />}
        {!token && <Route path="/confirm/:token" element={<ConfirmRegistry />} />}
        {!token && <Route path="/password/:token" element={<ChangePassword />} />}
        {token && <Route path="/settings" element={<HeaderMenuLayout Outlet={Settings} />} />}
        <Route path="/terms-conditions" element={<HeaderMenuLayout Outlet={TermsConditions} />} />
        <Route path="*" element={<Navigate replace to="/home" />} />
      </Routes>
    );
  }
}

function mapStoreToProps(store) {
  return {
    token: store.persons.token,
    person: store.persons.person,
    socket: store.sockets.socket,
    chat: store.chats.chat,
    match: store.match.match
  };
}

export default withRouter(connect(mapStoreToProps, { recieveCancelMatch, updateLocation, getPerson, getPartner, getSocketConnection, getMessages, receiveNotfications, getNotifications, getMatch, recieveNewMatch })(Router));

