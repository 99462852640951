import React from 'react';
import './index.css';
import CommonSelect from '../CommonSelect';
import { connect } from 'react-redux';

import { getCities } from '../../../actions/location'

class CommonCity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputMode: false,
      name: props.name,
      value: props.value
    };
    this.onClick = this.onClick.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { countryCode } = this.props;
    if (countryCode) {
      this.props.getCities(countryCode);
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.countryCode && newProps.countryCode !== this.props.countryCode) {
      this.props.getCities(newProps.countryCode);
    }
  }

  onChange(data) {
    this.setState({ value: data.value });
    if (this.props.onValueChange) {
      this.props.onValueChange(data);
    }
  }

  onClick() {
    this.setState({ inputMode: true });
  }

  render() {
    const { value } = this.state;
    const { title, edit, name, error, cities } = this.props;
    return (
      <CommonSelect
        title={title}
        name={name}
        options={cities}
        edit={edit}
        value={value}
        error={error}
        onValueChange={this.onChange}
      />
    );
  }
}

function mapStoreToProps(store) {
  return {
    cities: store.locations.cities
  };
}

export default connect(mapStoreToProps, { getCities })(CommonCity);
