import { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from '@mui/material';

import { getPartner } from '../../actions/person'

import { ConfiguratedContainer, withRouter, TwoColumnContainer } from 'components/common';
import ViewProfileImage from '../../components/common/image/ViewProfileImage'
import Title from 'components/common/Title';
import Skeleton from '@mui/material/Skeleton';
import { requestMatch, cancelRequest } from '../../actions/match';
import { blockPerson, unblockPerson } from '../../actions/person';
import UnmatchDialog from './UnmatchDialog';
import BlockDialog from './BlockDialog';
import dayjs from 'dayjs';
import { withTranslation } from 'react-i18next';

class PartnerProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            openModal: false,
            openConfirm: false,
            openBlock: false
        };
        this.cancelRequest = this.cancelRequest.bind(this);
        this.goToMessages = this.goToMessages.bind(this);
        this.openModal = this.openModal.bind(this);
        this.requestMatch = this.requestMatch.bind(this);
        this.closeConfirmCancelMatch = this.closeConfirmCancelMatch.bind(this);
        this.openConfirmCancelMatch = this.openConfirmCancelMatch.bind(this);
        this.closeConfirmCancelBlock = this.closeConfirmCancelBlock.bind(this);
        this.openConfirmCancelBlock = this.openConfirmCancelBlock.bind(this);
        this.blockPerson = this.blockPerson.bind(this);
        this.unblockPerson = this.unblockPerson.bind(this);
    }

    openModal() {
        this.setState({ openModal: true })
    }

    openBlockModal() {
        this.setState({ openBlockModal: true })
    }

    async requestMatch() {
        this.setState({
            loading: true
        }, async () => {
            try {
                const { person, partner } = this.props;
                await this.props.requestMatch(person.id, partner.id);
                await this.props.getPartner(partner.id);
            } finally {
                this.setState({
                    loading: false
                });
            }
        });

    }

    blockPerson(message) {
        this.setState({
            loading: true
        }, async () => {
            try {
                const { partner } = this.props;
                await this.props.blockPerson(partner.id, message);
                await this.props.getPartner(partner.id);
            } finally {
                this.setState({
                    loading: false,
                    openBlock: false
                });
            }
        });
    }

    unblockPerson() {
        this.setState({
            loading: true
        }, async () => {
            try {
                const { partner } = this.props;
                await this.props.unblockPerson(partner.id);
            } finally {
                this.setState({
                    loading: false,
                });
            }
        });
    }

    async UNSAFE_componentWillMount() {
        const { match, person, params } = this.props;
        let partnerId;
        if (params.id) {
            partnerId = params.id;
        } else {
            partnerId = match.person1;
            if (match.person1 === person.id) {
                partnerId = match.person2;
            }
        }
        this.setState({
            loading: true
        }, async () => {
            try {
                await this.props.getPartner(partnerId);
            } finally {
                this.setState({
                    loading: false,
                });
            }
        });
    }

    async UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.match !== this.props.match) {
            const { match, person, params } = nextProps;
            let partnerId;
            if (params.id) {
                partnerId = params.id;
            } else {
                partnerId = match.person1;
                if (match.person1 === person.id) {
                    partnerId = match.person2;
                }
            }
            this.setState({
                loading: true
            }, async () => {
                try {
                    await this.props.getPartner(partnerId);
                } finally {
                    this.setState({
                        loading: false,
                    });
                }
            });
        }
    }

    openConfirmCancelMatch() {
        this.setState({ openConfirm: true });
    }

    closeConfirmCancelMatch() {
        this.setState({ openConfirm: false });
    }

    openConfirmCancelBlock() {
        this.setState({ openBlock: true });
    }

    closeConfirmCancelBlock() {
        this.setState({ openBlock: false });
    }


    async cancelRequest() {
        this.setState({
            loading: true
        }, async () => {
            try {
                const { person, partner } = this.props;
                await this.props.cancelRequest(person.id, partner.id);
                await this.props.getPartner(partner.id);
            } finally {
                this.setState({
                    loading: false,
                    openConfirm: false
                });
            }
        });
    }

    goToMessages() {
        const { navigate } = this.props;
        navigate('/chat');
    }

    resolveMyGender(myGender) {
        const {t} = this.props;
        if (myGender === 'Female') {
            return t('editProfile.content.female');
        } else if (myGender === 'Male') {
            return t('editProfile.content.male');
        } else if (myGender === "Prefer not to say") {
            return t('editProfile.content.notSay');
        }
    }

    resolveToGender(toGender) {
        const {t} = this.props;
        if (toGender === 'Women') {
            return t('editProfile.content.women');
        } else if (toGender === 'Men') {
            return t('editProfile.content.men');
        } else if (toGender === "Any gender") {
            return t('editProfile.content.anygender');
        }
    }
    
    render() {
        const currentYear = new Date().getFullYear();


        const { partner, t } = this.props;
        const currentDate = dayjs();
        const birthdate = dayjs(partner.birthyear);
        const { openConfirm, loading, openBlock } = this.state;
        return (
            <ConfiguratedContainer configuration={{
                maxWidth: '768px',
                margin: 'auto',
                marginBottom: '10px'
            }}>
                <UnmatchDialog
                    name={partner.firstname}
                    open={openConfirm}
                    handleClose={this.closeConfirmCancelMatch}
                    confirmMatch={this.cancelRequest}
                />

                <BlockDialog
                    name={partner.firstname}
                    open={openBlock}
                    handleClose={this.closeConfirmCancelBlock}
                    confirmBlock={this.blockPerson}
                />
                <ViewProfileImage person={partner} openModal={this.openModal} onSuccess={() => this.setState({ success: true })} />
                {partner.blocked && <ConfiguratedContainer configuration={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginRight: '20px',
                    marginBottom: '20px'
                }}>
                    <img width={200} alt='User blocked' src='/static/block.png' />

                </ConfiguratedContainer>}
                
                <ConfiguratedContainer configuration={{
                    borderRadius: '5px',
                    marginRight: '10px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    maxWidth: '940px',
                    width: '100%',
                }}>
                    <TwoColumnContainer>

                        <ConfiguratedContainer configuration={{
                            flex: 1,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            ['@media (max-width: 505px)']: { // eslint-disable-line no-useless-computed-key
                                display: 'none',
                            }
                        }}>
                            <ConfiguratedContainer configuration={{
                                flex: 1
                            }}>
                                <ConfiguratedContainer configuration={{
                                    display: 'flex',
                                }}>
                                    <ConfiguratedContainer configuration={{
                                        display: 'flex',
                                        marginRight: '40px'
                                    }}>
                                        <ConfiguratedContainer configuration={{
                                            marginBottom: '10px',
                                            fontSize: '30px',
                                            color: '#0D47A1',
                                            fontWeight: 500
                                        }}>
                                            {this.state.loading ?
                                                <Skeleton variant="text" sx={{ fontSize: '30px' }} width={230} />
                                                : `${partner.firstname}`}
                                        </ConfiguratedContainer>
                                    </ConfiguratedContainer>
                                    <ConfiguratedContainer configuration={{
                                        flex: 1,
                                        display: 'flex',
                                        marginBottom: '10px',
                                        gap: '10px',
                                        height: '33px',
                                        marginTop: '7px'
                                    }}>
                                        {(!partner.blocked && !partner.request && !partner.match) && <Button
                                            disabled={!partner.id || loading}
                                            onClick={this.requestMatch}
                                            variant="outlined"
                                            color="success"
                                        >
                                            {t('profile.buttons.wantMatch')}
                                        </Button>}

                                        {!partner.blocked && partner.request && !partner.match && !partner.match && <Button
                                            disabled={!partner.id || loading}
                                            onClick={this.cancelRequest}
                                            variant="outlined"
                                            color="error"
                                        >
                                            {t('profile.buttons.cancel')}
                                        </Button>}
                                        {!partner.blocked && partner.match && <Button
                                            disabled={!partner.id || loading}
                                            // fullWidth
                                            onClick={this.openConfirmCancelMatch}
                                            variant="outlined"
                                            color="error"
                                        >
                                            {t('profile.buttons.unmatch')}
                                        </Button>}
                                        {!partner.blocked && partner.match && <Button
                                            disabled={!partner.id || loading}
                                            // fullWidth
                                            onClick={this.goToMessages}
                                            variant="outlined"
                                            color="success"
                                        >
                                            {t('profile.buttons.messages')}
                                        </Button>}
                                        {!partner.blocked && <Button
                                            disabled={!partner.id || loading}
                                            onClick={this.openConfirmCancelBlock}
                                            variant="outlined"
                                            color="error"
                                        >
                                            {t('profile.buttons.block')}
                                        </Button>}
                                        {partner.blocked && <Button
                                            disabled={!partner.id || loading}
                                            onClick={this.unblockPerson}
                                            variant="outlined"
                                            color="success"
                                        >
                                            {t('profile.buttons.unblock')}
                                        </Button>}
                                    </ConfiguratedContainer>
                                </ConfiguratedContainer>
                                <ConfiguratedContainer configuration={{
                                    display: 'flex', justifyContent: 'center',
                                }}>
                                    <ConfiguratedContainer configuration={{
                                        display: 'flex', flex: 1,
                                    }}>
                                        <ConfiguratedContainer configuration={{
                                            flex: 1, marginRight: '10px',
                                            width: '50xpx'
                                        }}>
                                            <Title>{t('profile.content.basedIn')} </Title>
                                        </ConfiguratedContainer>
                                        <ConfiguratedContainer configuration={{
                                            flex: 5,
                                            ['@media (max-width: 505px)']: { // eslint-disable-line no-useless-computed-key
                                                flex: 1
                                            }
                                        }}>
                                            {this.state.loading ?
                                                <Skeleton variant="text" width={145} />
                                                : `${partner.countrylabel} / ${partner.citylabel}`}

                                        </ConfiguratedContainer>
                                    </ConfiguratedContainer>

                                </ConfiguratedContainer>
                                <ConfiguratedContainer configuration={{
                                    display: 'flex', justifyContent: 'center',
                                }}>
                                    <ConfiguratedContainer configuration={{
                                        display: 'flex', flex: 1,
                                    }}>
                                        <ConfiguratedContainer configuration={{
                                            flex: 1, marginRight: '10px',
                                            width: '50xpx'
                                        }}>
                                            
                                            <Title>{t('profile.content.position')} </Title>
                                        </ConfiguratedContainer>
                                        <ConfiguratedContainer configuration={{
                                            flex: 5,
                                            ['@media (max-width: 505px)']: { // eslint-disable-line no-useless-computed-key
                                                flex: 1
                                            }
                                        }}>
                                            {this.state.loading ?
                                                <Skeleton variant="text" width={145} />
                                                : partner.distance < 1 ? t('profile.content.lessKm') : t('profile.content.moreKm', {distance: Math.round(partner.distance)})}

                                        </ConfiguratedContainer>
                                    </ConfiguratedContainer>

                                </ConfiguratedContainer>
                                <ConfiguratedContainer configuration={{
                                    display: 'flex', justifyContent: 'center',
                                }}>
                                    <ConfiguratedContainer configuration={{
                                        display: 'flex', flex: 1,
                                    }}>
                                        <ConfiguratedContainer configuration={{
                                            flex: 1, marginRight: '10px',
                                            width: '50xpx'
                                        }}>
                                            <Title>{t('profile.content.gender')} </Title>
                                        </ConfiguratedContainer>
                                        <ConfiguratedContainer configuration={{
                                            flex: 5,
                                            ['@media (max-width: 505px)']: { // eslint-disable-line no-useless-computed-key
                                                flex: 1
                                            }
                                        }}>
                                            {this.state.loading ?
                                                <Skeleton variant="text" width={145} />
                                                : `${this.resolveMyGender(partner.gender)}`}

                                        </ConfiguratedContainer>
                                    </ConfiguratedContainer>

                                </ConfiguratedContainer>
                                <ConfiguratedContainer configuration={{
                                    display: 'flex', justifyContent: 'center',
                                }}>
                                    <ConfiguratedContainer configuration={{
                                        display: 'flex', flex: 1,
                                    }}>
                                        <ConfiguratedContainer configuration={{
                                            flex: 1, marginRight: '10px',
                                            width: '50xpx'
                                        }}>
                                            <Title>{t('profile.content.lookingFor')} </Title>
                                        </ConfiguratedContainer>
                                        <ConfiguratedContainer configuration={{
                                            flex: 5,
                                            ['@media (max-width: 505px)']: { // eslint-disable-line no-useless-computed-key
                                                flex: 1
                                            }
                                        }}>
                                            {this.state.loading ?
                                                <Skeleton variant="text" width={145} />
                                                : `${this.resolveToGender(partner.togender)}`}

                                        </ConfiguratedContainer>
                                    </ConfiguratedContainer>

                                </ConfiguratedContainer>
                                <ConfiguratedContainer configuration={{
                                    display: 'flex', justifyContent: 'center',
                                }}>
                                    <ConfiguratedContainer configuration={{
                                        display: 'flex', flex: 1,
                                    }}>
                                        <ConfiguratedContainer configuration={{
                                            flex: 1, marginRight: '10px',
                                            width: '50xpx'
                                        }}>
                                            <Title>{t('profile.content.age')} </Title>
                                        </ConfiguratedContainer>
                                        <ConfiguratedContainer configuration={{
                                            flex: 5,
                                            ['@media (max-width: 505px)']: { // eslint-disable-line no-useless-computed-key
                                                flex: 1
                                            }
                                        }}>
                                            {this.state.loading ?
                                                <Skeleton variant="text" width={145} />
                                                : t('profile.content.birthdate', {birthdate: currentDate.diff(birthdate, 'year')})}

                                        </ConfiguratedContainer>
                                    </ConfiguratedContainer>

                                </ConfiguratedContainer>
                                <ConfiguratedContainer configuration={{
                                    display: 'flex', justifyContent: 'center',
                                }}>
                                    <ConfiguratedContainer configuration={{
                                        display: 'flex', flex: 1,
                                    }}>
                                        <ConfiguratedContainer configuration={{
                                            flex: 1, marginRight: '10px',
                                            width: '50xpx'
                                        }}>
                                            <Title>{t('profile.content.occupation')} </Title>
                                        </ConfiguratedContainer>
                                        <ConfiguratedContainer configuration={{
                                            flex: 5,
                                            ['@media (max-width: 505px)']: { // eslint-disable-line no-useless-computed-key
                                                flex: 1
                                            }
                                        }}>
                                            {this.state.loading ?
                                                <Skeleton variant="text" width={145} />
                                                : `${partner.position}`}

                                        </ConfiguratedContainer>
                                    </ConfiguratedContainer>
                                </ConfiguratedContainer>
                            </ConfiguratedContainer>

                        </ConfiguratedContainer>



                        
                        <ConfiguratedContainer configuration={{
                            flex: 1, display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            paddingRight: '20px',
                            ['@media (min-width: 505px)']: { // eslint-disable-line no-useless-computed-key
                                display: 'none',
                            },
                        }}>
                            <ConfiguratedContainer configuration={{
                                flex: 1,
                            }}>
                                <ConfiguratedContainer configuration={{
                                    flex: 1,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginBottom: '20px',
                                    gap: '10px',
                                }}>
                                    {(!partner.blocked && !partner.request && !partner.match) && <ConfiguratedContainer configuration={{flex: 1}}>
                                        <Button
                                            disabled={!partner.id}
                                            fullWidth
                                            onClick={this.requestMatch}
                                            variant="outlined"
                                            color="success"
                                        >
                                            {t('profile.buttons.wantMatch')}
                                        </Button>
                                    </ConfiguratedContainer>}
                                    {!partner.blocked && partner.request && !partner.match && !partner.match &&<ConfiguratedContainer configuration={{flex: 1}}>
                                         <Button
                                            disabled={!partner.id}
                                            fullWidth
                                            onClick={this.cancelRequest}
                                            variant="outlined"
                                            color="error"
                                        >
                                            {t('profile.buttons.cancel')}
                                        </Button>
                                    </ConfiguratedContainer>}
                                    {!partner.blocked && partner.match && <ConfiguratedContainer configuration={{flex: 1}}>
                                        <Button
                                            disabled={!partner.id}
                                            fullWidth
                                            onClick={this.openConfirmCancelMatch}
                                            variant="outlined"
                                            color="error"
                                        >
                                            {t('profile.buttons.unmatch')}
                                        </Button>
                                    </ConfiguratedContainer>}
                                    {!partner.blocked && partner.match &&<ConfiguratedContainer configuration={{flex: 1}}>
                                        <Button
                                            fullWidth
                                            onClick={this.goToMessages}
                                            variant="outlined"
                                            color="success"
                                        >
                                            {t('profile.buttons.messages')}
                                        </Button>
                                    </ConfiguratedContainer>}
                                    {!partner.blocked && <ConfiguratedContainer configuration={{flex: 1}}>
                                        <Button
                                            fullWidth
                                            disabled={!partner.id || loading}
                                            onClick={this.openConfirmCancelBlock}
                                            variant="outlined"
                                            color="error"
                                        >
                                            {t('profile.buttons.block')}
                                        </Button>
                                    </ConfiguratedContainer>}
                                    {partner.blocked && <ConfiguratedContainer configuration={{flex: 1}}>    
                                        <Button
                                            fullWidth
                                            disabled={!partner.id || loading}
                                            onClick={this.unblockPerson}
                                            variant="outlined"
                                            color="success"
                                        >
                                            {t('profile.buttons.unblock')}
                                        </Button>
                                    </ConfiguratedContainer>}
                                </ConfiguratedContainer>

                                <ConfiguratedContainer configuration={{
                                    flex: 1,
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}>
                                    <ConfiguratedContainer configuration={{
                                        marginBottom: '10px',
                                        fontSize: '30px',
                                        color: '#0D47A1',
                                        fontWeight: 500
                                    }}>
                                        {this.state.loading ?
                                            <Skeleton variant="text" sx={{ fontSize: '30px' }} width={230} />
                                            : `${partner.firstname}`}
                                    </ConfiguratedContainer>
                                </ConfiguratedContainer>
                                <ConfiguratedContainer configuration={{
                                    display: 'flex', justifyContent: 'space-between',
                                }}>
                                    <ConfiguratedContainer configuration={{
                                        marginRight: '10px',

                                    }}>
                                        <Title>{t('profile.content.basedIn')}</Title>
                                    </ConfiguratedContainer>
                                    <ConfiguratedContainer configuration={{

                                    }}>{this.state.loading ?
                                        <Skeleton variant="text" sx={{}} width={100} />
                                        : `${partner.countrylabel} / ${partner.citylabel}`}</ConfiguratedContainer>
                                </ConfiguratedContainer>
                                <ConfiguratedContainer configuration={{
                                    display: 'flex', justifyContent: 'space-between',
                                }}>
                                    <ConfiguratedContainer configuration={{
                                        marginRight: '10px',

                                    }}>
                                        <Title>{t('profile.content.position')}</Title>
                                    </ConfiguratedContainer>
                                    <ConfiguratedContainer configuration={{

                                    }}>{this.state.loading ?
                                        <Skeleton variant="text" sx={{}} width={100} />
                                        : partner.distance < 1 ? t('profile.content.lessKm') : t('profile.content.moreKm', {
                                            distance: Math.round(partner.distance)
                                        })}</ConfiguratedContainer>
                                </ConfiguratedContainer>

                                <ConfiguratedContainer configuration={{
                                    display: 'flex', justifyContent: 'space-between',
                                }}>
                                    <ConfiguratedContainer configuration={{
                                        marginRight: '10px',

                                    }}>
                                        <Title>{t('profile.content.gender')}</Title>
                                    </ConfiguratedContainer>
                                    <ConfiguratedContainer configuration={{

                                    }}>{this.state.loading ?
                                        <Skeleton variant="text" sx={{}} width={100} />
                                        : `${this.resolveMyGender(partner.gender)}`}</ConfiguratedContainer>
                                </ConfiguratedContainer>
                                <ConfiguratedContainer configuration={{
                                    display: 'flex', justifyContent: 'space-between',
                                }}>
                                    <ConfiguratedContainer configuration={{
                                        marginRight: '10px',

                                    }}>
                                        <Title>{t('profile.content.lookingFor')} </Title>
                                    </ConfiguratedContainer>
                                    <ConfiguratedContainer configuration={{

                                    }}>{this.state.loading ?
                                        <Skeleton variant="text" sx={{}} width={100} />
                                        : `${this.resolveToGender(partner.togender)}`}</ConfiguratedContainer>
                                </ConfiguratedContainer>
                                <ConfiguratedContainer configuration={{
                                    display: 'flex', justifyContent: 'space-between',
                                }}>
                                    <ConfiguratedContainer configuration={{
                                        marginRight: '10px',

                                    }}>
                                        <Title>{t('profile.content.age')}</Title>
                                    </ConfiguratedContainer>
                                    <ConfiguratedContainer configuration={{

                                    }}>{this.state.loading ?
                                        <Skeleton variant="text" sx={{}} width={100} />
                                        : `${currentDate.diff(birthdate, 'year')} years old`}</ConfiguratedContainer>
                                </ConfiguratedContainer>
                                <ConfiguratedContainer configuration={{
                                    display: 'flex', justifyContent: 'space-between',
                                }}>
                                    <ConfiguratedContainer configuration={{
                                        marginRight: '10px',

                                    }}>
                                        <Title>{t('profile.content.occupation')} </Title>
                                    </ConfiguratedContainer>
                                    <ConfiguratedContainer configuration={{

                                    }}>{this.state.loading ?
                                        <Skeleton variant="text" sx={{}} width={100} />
                                        : `${partner.position}`}</ConfiguratedContainer>
                                </ConfiguratedContainer>

                            </ConfiguratedContainer>

                        </ConfiguratedContainer>
                    </TwoColumnContainer>
                    <Title>{t('profile.content.description')} </Title>
                    {this.state.loading ?
                        <Skeleton variant="text" sx={{}} width={'calc(100% - 15px)'} height={'200px'} />
                        : <ConfiguratedContainer
                            configuration={{
                                marginBottom: '30px',
                                flex: 1,
                                paddingRight: '15px',
                                whiteSpace: 'break-spaces'
                            }}
                            dangerouslySetInnerHTML={{ __html: partner.description }} />}
                </ConfiguratedContainer>
            </ConfiguratedContainer>
        );
    }
}

function mapStoreToProps(store) {
    return {
        partner: store.persons.partner,
        match: store.match.match,
        person: store.persons.person
    };
}

export default withTranslation()(withRouter(connect(mapStoreToProps, { unblockPerson, blockPerson, getPartner, cancelRequest, requestMatch })(PartnerProfile)));
