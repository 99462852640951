import { Component } from 'react';

import { withTheme } from '@mui/styles';
import { connect } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';
import CommonInput from 'components/common/CommonInput';
import { regex } from 'config';
import { ConfiguratedContainer, withRouter } from 'components/common';
import { Button } from '@mui/material';
import { login } from './../../actions/authentication';
import { withTranslation } from 'react-i18next';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmitting: false
        };
        this.isValid = this.isValid.bind(this);
        this.onValueChange = this.onValueChange.bind(this);
        this.onLogin = this.onLogin.bind(this);
        this.goToRegister = this.goToRegister.bind(this);
        this.goToReset = this.goToReset.bind(this);
        this.goToLogin = this.goToLogin.bind(this);
        this.gotToResend = this.gotToResend.bind(this);
        this.onKeyEnter = this.onKeyEnter.bind(this);
    };

    isValid() {
        const { email, password } = this.state;
        if (
            email && regex.emailRegex.test(email)
            && password && regex.passwordRegex.test(password)
        ) {
            this.setState({ isValid: true });
        } else {
            this.setState({ isValid: false });
        }
    }

    onKeyEnter(event) {
        if (event.key === 'Enter' && this.state.isValid) {
            this.onLogin();
        }
    }

    goToLogin() {
        this.setState({
            isSubmitting: false,
            error: false,
            status: '',
            message: ''
        });
    }

    goToRegister() {
        const { navigate } = this.props;
        navigate('/register');
    }

    gotToResend() {
        const { navigate } = this.props;
        navigate('/resend');
    }

    goToReset() {
        const { navigate } = this.props;
        navigate('/reset');
    }

    onValueChange(data) {
        this.setState({
            [data.name]: data.value
        }, () => {
            this.isValid()
        });
    }

    onLogin() {
        const {
            email,
            password
        } = this.state;
        this.setState({
            isSubmitting: true
        }, async () => {
            try {
                await this.props.login({
                    email,
                    password
                });
                const { navigate } = this.props;
                navigate('/home');
            } catch (err) {
                this.setState({
                    isSubmitting: false,
                    error: true,
                    status: err && err.response ? err.response.data.code : "Error Login, please try again",
                    message: err && err.response ? err.response.data.message : "Error Login, please try again"
                });
            }
        });

    }

    render() {
        const {t} = this.props;
        return (
            <ConfiguratedContainer
                configuration={{
                    width: '100%',
                    height: '100%',
                    display: 'flex'
                }}>
                <ConfiguratedContainer
                    configuration={{
                        height: '100%',
                        ['@media (max-width: 768PX)']: { // eslint-disable-line no-useless-computed-key
                            display: 'none',
                        }
                    }}>
                    <img height={"100%"} alt='Monotryst' src='/static/front.jpg' />
                </ConfiguratedContainer>
                <ConfiguratedContainer configuration={{
                    display: 'table',
                    height: '100%',
                    flex: 1,
                    backgroundColor: 'white',
                    ['@media (max-width: 768px)']: { // eslint-disable-line no-useless-computed-key
                        width: '100%'
                    }
                }}>
                    <ConfiguratedContainer configuration={{
                        display: 'table-cell',
                        verticalAlign: 'middle',
                        ['@media (max-width: 425px)']: { // eslint-disable-line no-useless-computed-key
                            height: '100%',
                        },
                    }}>
                        {!this.state.error && <ConfiguratedContainer configuration={{
                            backgroundColor: 'white',
                            width: '375px',
                            margin: 'auto',
                            padding: '20px',
                            borderRadius: '5px',
                            ['@media (max-width: 425px)']: { // eslint-disable-line no-useless-computed-key
                                height: 'calc(100% + 100px)',
                                paddingTop: '100px'
                            },

                        }}>
                            <ConfiguratedContainer configuration={{
                                display: 'flex',
                                justifyContent: 'center',
                            }} >
                                <img width={200} alt='Monotryst' src='/static/logo.png' />
                            </ConfiguratedContainer>

                            {/* <Title>Login</Title> */}
                            <ConfiguratedContainer configuration={{ width: 'calc(100% + 15px)' }}>
                                <CommonInput
                                    title={t('login.content.email')}
                                    name='email'
                                    value={this.state.email}
                                    error={'Email is required'}
                                    regex={regex.emailRegex}
                                    onKeyPress={this.onKeyEnter}
                                    onValueChange={this.onValueChange}
                                />
                            </ConfiguratedContainer>
                            <ConfiguratedContainer configuration={{ width: 'calc(100% + 15px)' }}>
                                <CommonInput
                                    title={t('login.content.password')}
                                    name='password'
                                    type='password'
                                    value={this.state.password}
                                    error={'Password is required'}
                                    regex={regex.passwordRegex}
                                    onKeyPress={this.onKeyEnter}
                                    onValueChange={this.onValueChange}
                                />
                            </ConfiguratedContainer>
                            <ConfiguratedContainer configuration={{
                                marginTop: '20px',
                                marginBottom: '20px'
                            }}>
                                <LoadingButton
                                    fullWidth
                                    disabled={!this.state.isValid}
                                    onClick={this.onLogin}
                                    loading={this.state.isSubmitting}
                                    loadingPosition="start"
                                    startIcon={<div></div>}
                                    onKeyPress={this.onKeyEnter}
                                    variant="contained">
                                    {t('login.buttons.login')}
                                </LoadingButton>
                            </ConfiguratedContainer>
                            <Button
                                onClick={this.goToRegister}
                                variant="outlined"
                                fullWidth>
                                {t('login.buttons.signup')}
                            </Button>
                            <ConfiguratedContainer configuration={{
                                marginTop: '10px'
                            }}>
                                <Button
                                    onClick={this.goToReset}
                                    fullWidth>
                                    {t('login.buttons.forgot')}
                                </Button>
                            </ConfiguratedContainer>
                        </ConfiguratedContainer>}
                        {this.state.error && <ConfiguratedContainer configuration={{
                            backgroundColor: 'white',
                            width: '375px',
                            margin: 'auto',
                            padding: '20px',
                            borderRadius: '5px',
                            ['@media (max-width: 425px)']: { // eslint-disable-line no-useless-computed-key
                                height: 'calc(100% + 100px)',
                                paddingTop: '100px'
                            },
                        }}>
                            <ConfiguratedContainer configuration={{
                                display: 'flex',
                                justifyContent: 'center',

                            }} >
                                <img width={200} alt='Monotryst' src='/static/logo.png' />
                            </ConfiguratedContainer>
                            <ConfiguratedContainer configuration={{
                                // display: 'flex',
                                padding: ' 0 20px',
                                minHeight: '50x',
                                backgroundColor: '#FFCDD2',
                                marginRight: '5px',
                                borderRadius: '10px',
                                // borderRolor: '#81C784',
                                paddingTop: '10px',
                                paddingBottom: '1px',
                                marginBottom: '20px'
                            }}>
                                <ConfiguratedContainer>
                                    {this.state.message}
                                </ConfiguratedContainer>
                                <ConfiguratedContainer configuration={{
                                    height: '10px'
                                }}>

                                </ConfiguratedContainer>
                            </ConfiguratedContainer>
                            {/* <Title>Login</Title> */}

                            <ConfiguratedContainer configuration={{
                                marginBottom: '20px'
                            }}>
                                <LoadingButton
                                    fullWidth
                                    disabled={!this.state.isValid}
                                    onClick={this.goToLogin}
                                    loading={this.state.isSubmitting}
                                    loadingPosition="end"
                                    endIcon={<div></div>}
                                    variant="contained"
                                >
                                    {t('login.buttons.gotologin')}
                                </LoadingButton>

                            </ConfiguratedContainer>


                            <ConfiguratedContainer configuration={{
                                marginBottom: '20px'
                            }}>
                                <LoadingButton
                                    fullWidth
                                    disabled={!this.state.isValid}
                                    onClick={this.goToRegister}
                                    loading={this.state.isSubmitting}
                                    loadingPosition="end"
                                    endIcon={<div></div>}
                                    variant="contained"
                                >
                                    {t('login.buttons.gotoregister')}
                                </LoadingButton>

                            </ConfiguratedContainer>

                            {this.state.status === 'EMAIL_NOT_CONFIRMED' && <Button
                                onClick={this.gotToResend}
                                variant="outlined"
                                fullWidth>
                                    {t('login.buttons.resendEmail')}
                            </Button>}

                        </ConfiguratedContainer>}
                    </ConfiguratedContainer>
                </ConfiguratedContainer>
            </ConfiguratedContainer>


        );
    }
}

function mapStoreToProps(store) {
    return {
        person: store.persons.person
    };
}

export default withTranslation()(withRouter(withTheme(connect(mapStoreToProps, { login })(Login))));
