import React, { Component } from 'react';

import { ConfiguratedContainer, withRouter } from '../components/common';
import { Button, Box } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { withTheme } from '@emotion/react';
import { withTranslation } from 'react-i18next';
import LanguageSelector from './LanguageSelector';

class UnAuthHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.goToRegister = this.goToRegister.bind(this);
        this.goToSignin = this.goToSignin.bind(this);
    }

    goToRegister() {
        const { navigate } = this.props;
        navigate('/register')
    }

    goToSignin() {
        const { navigate } = this.props;
        navigate('/login')
    }

    render() {
        const { theme, t} = this.props;
        return (
            <ConfiguratedContainer configuration={{
                width: '100%',
                backgroundImage: 'url(/static/home/large.jpg)',
                backgroundRepeat: 'no-repeat',
                height: '100%',
                backgroundSize: 'cover',
                ['@media (max-width: 1200px)']: { // eslint-disable-line no-useless-computed-key
                    marginBottom: '30px',
                },
                ['@media (max-width: 768px)']: { // eslint-disable-line no-useless-computed-key
                    backgroundImage: 'url(/static/home/medium.png)',

                },
                ['@media (max-width: 525px)']: { // eslint-disable-line no-useless-computed-key
                    backgroundImage: 'url(/static/home/small.png)',
                    // marginLeft: '0',
                },
            }}>
                <ConfiguratedContainer
                    configuration={{
                        display: 'flex',
                        height: '70px',
                        width: '100%',
                        padding: '0 10px'
                    }}>
                    <RouterLink to="/home">
                        <Box component="img" alt='Monotryst' src="/static/logo.png" sx={{ height: 70 }} />
                    </RouterLink>
                    <Box sx={{ flexGrow: 1 }} />
                    <ConfiguratedContainer configuration={{
                        display: 'flex',
                        gap: '10px',
                        alignItems: 'center',
                        whiteSpace: 'nowrap',
                        marginRight: '10px',
                        marginTop: '5px'
                    }}>
                        <LanguageSelector />
                    </ConfiguratedContainer>
                    <ConfiguratedContainer configuration={{
                        display: 'flex',
                        gap: '10px',
                        alignItems: 'center',
                        whiteSpace: 'nowrap'
                    }}>
                        <Button
                            onClick={this.goToSignin}
                            fullWidth
                            color="inherit"
                            disabled={this.state.text === ''}
                            variant="outlined">
                            {t('home.buttons.login')}
                        </Button>
                        <Button
                            onClick={this.goToRegister}
                            fullWidth
                            disabled={this.state.text === ''}
                            variant="contained">
                            {t('home.buttons.register')}

                        </Button>
                    </ConfiguratedContainer>

                </ConfiguratedContainer>




                <ConfiguratedContainer configuration={{
                    // height: '700px',
                    width: '100%',
                    margin: 'auto',
                    marginTop: '30px',

                }}>

                    <ConfiguratedContainer configuration={{
                        marginLeft: 'calc(50% - 429px)',
                        marginTop: '200px',
                        ['@media (max-width: 768px)']: { // eslint-disable-line no-useless-computed-key
                            width: '350px',
                            marginLeft: 'calc(50% - 329px)',
                        },
                        ['@media (max-width: 525px)']: { // eslint-disable-line no-useless-computed-key
                            position: 'absolute',
                            width: '100%',
                            // marginTop: '300px',
                            marginLeft: '0',
                            bottom: '0'
                        },
                    }}>
                        <ConfiguratedContainer configuration={{
                            maxWidth: '500px',
                            padding: '20px',
                            borderRadius: '10px',
                            display: 'flex',
                            flexDirection: 'column',
                        }}>
                            <ConfiguratedContainer configuration={{
                                color: theme.palette.primary.main,
                                marginTop: '40px',
                                fontSize: '34px',
                                fontWeight: 'bold',
                                ['@media (max-width: 525px)']: { // eslint-disable-line no-useless-computed-key
                                    color: 'white',
                                },
                            }}>
                                {t('home.content.title')}
                            </ConfiguratedContainer>
                            <ConfiguratedContainer configuration={{
                                marginTop: '20px',
                                fontSize: '24px',
                                ['@media (max-width: 525px)']: { // eslint-disable-line no-useless-computed-key
                                    color: 'lightGrey',
                                },
                            }}>
                                {t('home.content.body')}
                            </ConfiguratedContainer>
                            <ConfiguratedContainer configuration={{
                                marginTop: '30px'
                            }}>
                            <Button
                                onClick={this.goToRegister}
                                variant="contained"
                            >
                                {t('home.buttons.registernow')}
                            </Button>
                            </ConfiguratedContainer>
                            
                        </ConfiguratedContainer>
                    </ConfiguratedContainer>

                </ConfiguratedContainer>
            </ConfiguratedContainer>
        );
    }

}

export default withTranslation()(withTheme(withRouter(UnAuthHome)));

