import React from 'react';
import { withTheme } from '@mui/styles';
import { styled } from '@mui/material/styles';

import Title from './Title';
import { FieldContainer, Error, ConfiguratedContainer } from '../common';

const Input = styled('input')(({ theme }) => {
    return {
        width: 'calc(100% - 20px)',
        height: '48px',
        borderRadius: '4px',
        border: `1px solid ${theme.palette.primary.main}`,
        paddingLeft: '14px',
        fontFamily: theme.fontFamily,
        color: theme.palette.text.primary,
        '&:focus': {
            outline: 'none',
            border: `1px solid ${theme.palette.primary.main}`,
            boxShadow: `0 0 10px ${theme.palette.primary.main}`
        }
    };
});

class CommonInput extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            name: props.name,
            value: props.value,
            valid: true,
            touched: false
        };
        this.onClick = this.onClick.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onValueChange = this.onValueChange.bind(this);
        //get reference for input
        // this.nameRef = React.createRef();

        //Setup cursor position for input
        // this.cursor = null;
    }

    // componentDidUpdate() {
    //     this._setCursorPositions();
    // }

    // _setCursorPositions = () => {
    //     //reset the cursor position for input
    //     this.nameRef.current.selectionStart = this.cursor;
    //     this.nameRef.current.selectionEnd = this.cursor;
    // };

    onValueChange(event) {
        const { regex } = this.props;
        this.setState({
            name: event.target.name,
            value: event.target.value
        }, () => {
            const { name, value } = this.state;
            let valid = true;
            if (regex && !regex.test(value)) {
                valid = false;
            }
            if (this.props.onValueChange) {
                this.props.onValueChange({
                    name,
                    value,
                    valid
                });
            }
            // this._setCursorPositions();
        });
    }

    onClick() {
        this.setState({ touched: true })
    }

    onBlur(event) {
        const { name, value, touched } = this.state;
        if (!touched) return;
        const { regex } = this.props;
        let valid = true;
        if (regex && !regex.test(value)) {
            valid = false;
        }
        if (this.props.onBlur) {
            this.props.onBlur({
                name,
                value,
                valid
            });
        }
        this.setState({
            valid
        });
    }

    render() {
        const { title, name, valid, type, error, theme, info } = this.props;
        const {value} = this.state;
        return (
            <FieldContainer>
                <Title theme={theme}> {title} <ConfiguratedContainer configuration={{
                    color: 'grey',
                    fontSize: '10px'
                }}>{info ? info : ''}</ConfiguratedContainer></Title>
                <Input
                    // ref={this.nameRef}
                    key={name}
                    name={name}
                    value={value}
                    info={info}
                    type={type || 'text'}
                    onBlur={this.onBlur}
                    onChange={this.onValueChange}
                    onKeyPress={(event) => {
                        if (this.props.onKeyPress) {
                            this.props.onKeyPress(event);
                        }
                    }}
                    onClick={this.onClick}

                />
                <Error valid={valid}>{error}</Error>
            </FieldContainer>
        );
    }
}

export default withTheme(CommonInput);
