import * as types from '../constants/ActionTypes';

const defaultState = {
  messages: [],
  count: 20,
  total: 20
};

export default function configuration(state = defaultState, action) {
  switch (action.type) {
    case types.GET_MESSAGES: {
      return { ...state, messages: [...action.payload], total: action.total };
    }
    case types.SEND_MESSAGE: {
      const message = action.payload;
      if (state.messages.length > 0 && state.messages[0].sender === message.sender) {
          state.messages[0].texts.push({
            id: message.id,
            text: message.text,
            time: message.createdat,
            read: false
          });
      } else {
        state.messages.unshift({
          id: message.id,
          sender: message.sender,
          texts: [{
            id: message.id,
            text: message.text,
            time: message.createdat,
            read: false
          }]
        })
      }
      return { ...state, messages: [...state.messages], total: state.total + 1 };


    }
    default:
      return { ...state }
  }
}
