import React from 'react';
import { API_URL } from '../../../config';
import './index.css';
import { ConfiguratedContainer } from '../containers';
import Carousel from 'react-material-ui-carousel';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

export default function ImagesPreview(props) {
    const handleClose = () => {
        props.onClose();
    };

    return (
        <Dialog PaperProps={{

            sx: {
                width: "100%",
                maxHeight: "100%",
                // border: 'solid blue',
                margin: '0 5px',
            }
        }}
            onClose={handleClose}
            open={props.open}>

            <ConfiguratedContainer
                configuration={{
                    width: '100%',
                        // border: 'solid red',
                    height: '100%',
                }}>
                <Carousel
                    sx={{
                        // border: 'solid red',
                        maxHeight:'100%',
                        'div': {
                        // maxHeight:'100%'
                            // border: 'solid pink'
                        }
                    }}
                    index={props.index}
                    autoPlay={false}
                    navButtonsAlwaysVisible={true}
                    indicators={true}
                    indicatorContainerProps={{
                        style: {
                            marginTop: "-50px"
                        },
                    }}
                >
                    {
                        props.items.map((item, i) => <Item key={i} item={item} />)
                    }
                </Carousel>
            </ConfiguratedContainer>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                    zIndex: 2000
                }}
            >
                <CloseIcon />
            </IconButton>
        </Dialog>

    )
}

function Item(props) {
    const url = `${API_URL}/api/files/${props.item.id}`;

    return (
        <ConfiguratedContainer
            configuration={{
                maxHeight: '100%',
                maxWidth: '100%',
                width: '100%',
                backgroundColor: 'rgba(0,0,0,0.5)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                'img': {
                    width: '100%',
                    // height: '100%',
                    maxHeight: '100%',
                    maxWidth: '100%',
                }

            }}>
            <img src={`${url}`} alt='Profile images'></img>
        </ConfiguratedContainer>
    )
}