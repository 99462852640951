import * as types from '../constants/ActionTypes';

const defaultState = {
  notifications: []
};

export default function configuration(state = defaultState, action) {
  switch (action.type) {
    case types.RECIEVE_NOTIFICATIONS: {
      return { ...state, notifications: [...action.payload] };
    }
    default:
      return { ...state }
  }
}
