
import { Component } from 'react';
import { connect } from 'react-redux';
import PersonItem from './PersonItem';
import { AddCircle } from '@mui/icons-material';
import { IconButton } from '@mui/material';

import { ConfiguratedContainer } from '../common';

import { getPartners } from '../../actions/person';
import PersonItemSkeleton from './PersonItemSkeleton';
import NoPartners from './NoPartners';

class PersonList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            loading: false
        };
        this.loadMorePartners = this.loadMorePartners.bind(this);
    }

    UNSAFE_componentWillMount() {
        const { person, filter, page } = this.props;
        this.setState({ loading: true }, async () => {
            await this.props.getPartners({
                personId: person.id,
                page,
                country: filter.country ? filter.country.value : undefined,
                city: filter.city ? filter.city.value : undefined,
                distance: filter.distance ? filter.distance : undefined,
                range: filter.range ? filter.range : undefined,
                type: filter.type ? filter.type : 'distance',
            });
            this.setState({ loading: false });
        })

    }

    loadMorePartners() {
        const { person, filter, page } = this.props;

        this.props.getPartners({
            personId: person.id,
            page: page + 1,
            country: filter.country ? filter.country.value : undefined,
            city: filter.city ? filter.city.value : undefined,
        });
    }

    render() {
        const { partners, total } = this.props;
        return (
            <ConfiguratedContainer configuration={{
                marginRight: '15px',
                maxWidth: '768px',
                margin: 'auto',
                ['@media (max-width: 768px)']: { // eslint-disable-line no-useless-computed-key
                    marginRight: '15px'
                }
            }}>
                {!this.state.loading && partners.map(
                    (person) => (
                        <PersonItem
                            key={person.id}
                            person={person}
                        />
                    )
                )}
                {this.state.loading &&
                    <ConfiguratedContainer>
                        <PersonItemSkeleton />
                        <PersonItemSkeleton />
                        <PersonItemSkeleton />
                        <PersonItemSkeleton />
                        <PersonItemSkeleton />
                        <PersonItemSkeleton />
                        <PersonItemSkeleton />
                        <PersonItemSkeleton />
                        <PersonItemSkeleton />
                    </ConfiguratedContainer>
                }
                {partners.length < total && <ConfiguratedContainer configuration={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '10px',
                    marginBottom: '10px',
                    // cursor: 'pointer'
                }}>
                    <IconButton size='large' onClick={this.loadMorePartners}>
                        <AddCircle size='large' />
                    </IconButton>
                </ConfiguratedContainer>}

                {!this.state.loading && partners.length === 0 && <NoPartners />}

            </ConfiguratedContainer>
        );
    }
}

function mapStoreToProps(store) {
    return {
        person: store.persons.person,
        partners: store.persons.partners,
        filter: store.persons.filter,
        total: store.persons.total,
        page: store.persons.page,
    };
}

export default connect(mapStoreToProps, { getPartners })(PersonList);
