
import { styled } from '@mui/material/styles';
import { MUIStyledCommonProps } from '@mui/system';
import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';

export const withRouter = WrappedComponent => props => {
    const params = useParams();
    const navigate = useNavigate();
    return (
        <WrappedComponent {...props} params={params} navigate={navigate} />
    );
};

type MarginContainerProps = {
    theme: MUIStyledCommonProps,
};

export const FlexContainer = styled('div')<MarginContainerProps>(({ theme }) => {
    return {
        display: 'flex'
    };
});

export const CenterContainer = styled('div')<MarginContainerProps>(({ theme }) => {
    return {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    };
});

type ConfigurationType = {
    [key: string]: object;
};

export const ConfiguratedContainer = styled('div')<ConfigurationType>(({ configuration }) => {
    return {
        ...configuration
    };
});

export const ImageContainer = styled('div')<MarginContainerProps>(({ theme }) => {
    return {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    };
});


export const MarginContainer = styled('div')<MarginContainerProps>(() => {
    return {
        marginBottom: '10px'
    };
});

type ImageProps = {
    width: string,
    height: string,
};

export const Image = styled('div')<ImageProps>(({ height, width }) => {
    return {
        height,
        width,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        ['@media (min-width:768px)']: { // eslint-disable-line no-useless-computed-key
            // width: '768px',
            margin: 'auto'
        }
    };
});

type LogoProps = {
    width: string,
    height: string,
};

export const Logo = styled('img')<LogoProps>(({ height }) => {
    return {
        height,
    };
});

export const FieldContainer = styled('div')(({ theme }) => {
    return {
        width: '100%',
        // height: '104px',
        alignItems: 'center',
        marginBottom: '5px',
    };
});

export const TwoColumnContainer = styled('div')(({ theme }) => {
    return {
        display: 'flex',
        ['@media (max-width: 505px)']: { // eslint-disable-line no-useless-computed-key
            flexDirection: 'column'
        }
    };
});
