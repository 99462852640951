import * as types from '../constants/ActionTypes';

const defaultState = {
  match: null,
  newMatch: false,
  eventCancelMatch: false,
};

export default function configuration(state = defaultState, action) {
  switch (action.type) {
    case types.GET_MATCH: {
      return { ...state, match: action.payload == null ? null : { ...action.payload } };
    }
    case types.CANCEL_MATCH: {
      return { ...state, match: null };
    }
    case types.EVENT_NEW_MATCH: {
      return { ...state, newMatch: true, eventCancelMatch: false }
    }
    case types.NEW_MATCH_VIEWED: {
      return { ...state, newMatch: false }
    }
    case types.EVENT_CANCEL_MATCH: {
      return { ...state, eventCancelMatch: true, newMatch: false, match: null }
    }
    case types.CANCEL_MATCH_VIEWED: {
      return { ...state, eventCancelMatch: false, newMatch: false, match: null }
    }
    default:
      return { ...state }
  }
}
