import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { styled } from '@mui/material/styles';
import { Box, Drawer } from '@mui/material';
import useResponsive from '../hooks/useResponsive';
// components
import Logo from './Logo';
import Scrollbar from './Scrollbar';
import NavSection from './NavSection';
import Iconify from '../Iconify';
//
import { withTranslation } from 'react-i18next';

// ----------------------------------------------------------------------
const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};

function DashboardSidebar({ person, isOpenSidebar, onCloseSidebar, t, notifications, match }) {
  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  const EDIT_PAGE = t('navigation.editProfile');
  const HOME = t('navigation.home');
  const LOGIN = t('navigation.login');
  const REGISTER = t('navigation.register');
  const ARCHIVE = t('navigation.archive');
  const MY_PROFILE = t('navigation.myProfile');
  const MESSAGES = t('navigation.messages');
  const MY_MATCH = t('navigation.myMatch');

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' }
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
        <Logo />
      </Box>

      <NavSection
        notification={notifications.length > 0}
        navConfig={person ? (person.active ? (match ? [
          {
            title: HOME,
            path: '/home',
            icon: getIcon('eva:home-fill')
          },
          {
            title: MY_PROFILE,
            path: '/profile',
            icon: getIcon('material-symbols:person-2-rounded')
          },
          {
            title: MY_MATCH,
            path: '/match',
            icon: getIcon('material-symbols:heart-check-rounded')
          },
          {
            title: MESSAGES,
            path: '/chat',
            icon: getIcon('jam:messages-alt-f')
          },
          {
            title: ARCHIVE,
            path: '/archive',
            icon: getIcon('material-symbols:archive')
          }
        ] : [
          {
            title: HOME,
            path: '/home',
            icon: getIcon('eva:home-fill')
          },
          {
            title: MY_PROFILE,
            path: '/profile',
            icon: getIcon('material-symbols:person-2-rounded')
          },
          {
            title: ARCHIVE,
            path: '/archive',
            icon: getIcon('material-symbols:archive')
          },
        ]) : [
          {
            title: EDIT_PAGE,
            path: '/edit',
            icon: getIcon('material-symbols:person-2-rounded')
          },
        ]) : [
          {
            title: HOME,
            path: '/home',
            icon: getIcon('eva:home-fill')
          },
          {
            title: LOGIN,
            path: '/login',
            icon: getIcon('material-symbols:lock-open')
          },
          {
            title: REGISTER,
            path: '/register',
            icon: getIcon('mdi:pencil-circle')
          },
        ]} />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed'
            }
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}

function mapStoreToProps(store) {
  return {
    person: store.persons.person,
    notifications: store.notifications.notifications,
    match: store.match.match
  };
}

export default withTranslation()(connect(mapStoreToProps, {})(DashboardSidebar));