import React from 'react';
import './index.css';
import CommonSelect from '../CommonSelect';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FieldContainer, Error } from '../../common';
import Title from '../Title';
import { withTheme } from '@mui/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Box from '@mui/material/Box';
import dayjs from 'dayjs';


class CommonYearBirth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputMode: false,
      name: props.name,
      value: props.value
    };
    this.onClick = this.onClick.bind(this);
    this.onChange = this.onChange.bind(this);
    this.yearBirthOptions = [];
    const currentYear = new Date().getFullYear();
    for (let i = 18; i <= 100; i++) {
      const year = currentYear - i;
      this.yearBirthOptions.push({
        label: `${year}`,
        value: year
      });
    }

  }

  onChange(data) {
    const value = data.toISOString();//data.$y + '-' + data.$M + '-' + data.$D;
    this.setState({ value });
    if (this.props.onValueChange) {
      this.props.onValueChange({
        name : 'birthyear',
        value
      });
    }
  }

  onClick() {
    this.setState({ inputMode: true });
  }

  render() {
    const { value } = this.state;
    const { title, theme } = this.props;
    return (
      <FieldContainer>
        <Title theme={theme}> {title} </Title>
        <LocalizationProvider dateAdapter={AdapterDayjs}>

          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              position: 'relative',
            }}
          >
            <DatePicker
              minDate={dayjs().subtract(100, 'year')}
              maxDate={dayjs().subtract(18, 'year')}
              defaultValue={dayjs().subtract(19, 'year')}
              value={dayjs(value)}
              onChange={this.onChange}
              sx={{
                width: 'calc(100% - 20px)',
                height: '48px',
                borderRadius: '4px',
                border: `1px solid ${theme.palette.primary.main}`,
                paddingLeft: '14px',
                color: theme.palette.primary.main,
                fontFamily: theme.fontFamily,
                backgroundColor: 'white',
                marginLeft: '-20px',
                'div': {
                  height: '48px',
                  // width: '100%',
                  marginLeft: '-14px',
                  borderRadius: '2px',
                  border: '0px'
                }
              }}
            />
          </Box>
        </LocalizationProvider>
      </FieldContainer>
    );
  }
}

export default withTheme(CommonYearBirth);

