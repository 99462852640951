import { Component } from 'react';
import { withTheme } from '@mui/styles';
import { connect } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';
import CommonInput from 'components/common/CommonInput';
import { regex } from '../../config';
import { ConfiguratedContainer, withRouter } from 'components/common';
import { requestResetPassword } from '../../actions/authentication';
import { Button } from '@mui/material';
import { withTranslation } from 'react-i18next';

class Reset extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmitting: false
        };
        this.isValid = this.isValid.bind(this);
        this.onReset = this.onReset.bind(this);
        this.onValueChange = this.onValueChange.bind(this);
        this.goToLogin = this.goToLogin.bind(this);
    };

    goToLogin() {
        const { navigate } = this.props;
        navigate('/login');
    }

    isValid() {
        const { email } = this.state;
        if (
            email && regex.emailRegex.test(email)
        ) {
            this.setState({ isValid: true });
        } else {
            this.setState({ isValid: false });
        }
    }

    async onReset() {
        const { email } = this.state;
        try {
            this.setState({
                isSubmitting: true
            }, async () => {

                await this.props.requestResetPassword(email);
                this.setState({
                    isSubmitting: false,
                    success: "EMAIL_SENT"
                })
            })
        } catch (err) {
            this.setState({
                isSubmitting: false,
                error: "USER_NOT_FOUND"
            })
        }
    }

    onValueChange(data) {
        this.setState({
            [data.name]: data.value
        }, () => {
            this.isValid()
        });
    }


    render() {
        const {
            email,
        } = this.state;
        const {t} = this.props;
        return (
            <ConfiguratedContainer configuration={{
                display: 'table',
                height: '100%',
                width: '100%',
                ['@media (max-width: 425px)']: { // eslint-disable-line no-useless-computed-key
                },
            }}>
                <ConfiguratedContainer configuration={{
                    display: 'table-cell',
                    verticalAlign: 'middle',
                    ['@media (max-width: 425px)']: { // eslint-disable-line no-useless-computed-key
                        height: '100%',
                    },
                }}>
                    <ConfiguratedContainer configuration={{
                        backgroundColor: 'white',
                        width: '375px',
                        margin: 'auto',
                        padding: '20px',
                        borderRadius: '5px',
                        ['@media (max-width: 425px)']: { // eslint-disable-line no-useless-computed-key
                            height: 'calc(100% + 100px)',
                            paddingTop: '120px',
                        },
                    }}>
                        <ConfiguratedContainer configuration={{
                            display: 'flex',
                            justifyContent: 'center',
                        }} >
                            <img alt='Monotryst' src='/static/logo.png' />
                        </ConfiguratedContainer>
                        {!this.state.error && !this.state.success && <ConfiguratedContainer>
                            <ConfiguratedContainer configuration={{ width: 'calc(100% + 15px)' }}>
                                <CommonInput
                                    title={t('reset.content.email')}
                                    name='email'
                                    value={email}
                                    error={'Email is required'}
                                    regex={regex.emailRegex}
                                    onValueChange={this.onValueChange}
                                />
                            </ConfiguratedContainer>

                            <ConfiguratedContainer configuration={{
                                marginTop: '20px',
                                marginBottom: '20px',
                            }}>
                                <LoadingButton
                                    fullWidth
                                    disabled={!this.state.isValid}
                                    onClick={this.onReset}
                                    loading={this.state.isSubmitting}
                                    loadingPosition="start"
                                    startIcon={<div />}
                                    variant="contained">
                                        {t('reset.buttons.reset')}
                                    
                                </LoadingButton>
                            </ConfiguratedContainer>
                            <Button
                                fullWidth
                                onClick={this.goToLogin}
                                variant="outlined"
                            >
                                {t('reset.buttons.login')}
                            </Button>

                            <ConfiguratedContainer configuration={{
                                marginBottom: '20px'
                            }}>
                            </ConfiguratedContainer>
                        </ConfiguratedContainer>}
                        {this.state.error && <ConfiguratedContainer>
                            <p>{t('reset.content.error.emailNotFound.part1', {email: this.state.email})}</p>
                            <p>{t('reset.content.error.emailNotFound.part2')}</p>
                            <ConfiguratedContainer configuration={{
                                marginTop: '10px'
                            }}>
                                <LoadingButton
                                    fullWidth
                                    disabled={!this.state.isValid}
                                    onClick={() => this.setState({
                                        email: '',
                                        error: null,
                                        success: null
                                    })}
                                    loading={this.state.isSubmitting}
                                    loadingPosition="start"
                                    startIcon={<div />}
                                    variant="contained">
                                        {t('reset.buttons.retry')}
                                </LoadingButton>
                            </ConfiguratedContainer>
                            <ConfiguratedContainer configuration={{
                                marginTop: '15px'
                            }}>
                                <LoadingButton
                                    fullWidth
                                    onClick={this.goToLogin}
                                    loading={this.state.isSubmitting}
                                    loadingPosition="start"
                                    startIcon={<div />}
                                    variant="outlined">
                                    {t('reset.buttons.goToLogin')}
                                </LoadingButton>
                            </ConfiguratedContainer>
                        </ConfiguratedContainer>}

                        {this.state.success && <ConfiguratedContainer>
                            <p>{t('reset.content.success.email.part1', {email: this.state.email})} </p>
                            <p>{t('reset.content.success.email.part2')} </p>
                            <p>{t('reset.content.success.email.part3')} </p>
                            <ConfiguratedContainer configuration={{
                                marginTop: '10px'
                            }}>
                                <LoadingButton
                                    fullWidth
                                    onClick={this.goToLogin}
                                    loading={this.state.isSubmitting}
                                    loadingPosition="start"
                                    startIcon={<div />}
                                    variant="contained">
                                        {t('reset.buttons.goToLogin')}
                                </LoadingButton>
                            </ConfiguratedContainer>
                        </ConfiguratedContainer>}
                    </ConfiguratedContainer>
                </ConfiguratedContainer>
            </ConfiguratedContainer>
        );
    }
}

function mapStoreToProps(store) {
    return {};
}

export default withTranslation()(withRouter(withTheme(connect(mapStoreToProps, { requestResetPassword })(Reset))));