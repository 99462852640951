import * as types from '../constants/ActionTypes';
import { API_URL } from '../config';
import axios from 'axios';
import { getNotifications } from './notifications';
import { logout } from './authentication';

export function sendMessage(message) {
  return async function (dispatch) {
    try {
      const response = await axios.post(`${API_URL}/api/messages/`, message);
      dispatch({
        type: types.SEND_MESSAGE,
        payload: response.data.message
      });
      dispatch(getNotifications(message.senderId))
      // dispatch(getMessages(response.data.message.chatid));
    } catch(e: any) {
      if (e.response.data.code === "TOKEN_EXPIRED") {
        dispatch(logout())
      }
    }
  }
}

export function getMessages(chatId, count = 20) {
  return async function (dispatch) {
    try {
      const response = await axios.post(`${API_URL}/api/messages/chat/${chatId}`, {count});
      dispatch({
        type: types.GET_MESSAGES,
        payload: response.data.messages,
        total: response.data.total
      });
    } catch(e: any) {
      if (e.response.data.code === "TOKEN_EXPIRED") {
        dispatch(logout())
      }
    }
  }
}

export function toggleLikeMessage(chatId , partnerId, messageId, count=20) {
  return async function (dispatch) {
    try {
      await axios.post(`${API_URL}/api/messages/like`, {
        messageId,
        partnerId
      });
      dispatch(getMessages(chatId, count));
    } catch(e) {
      console.log(e)
    }
  }
}

export function readMessages(chatId, personId, partnerId) {
  return async function (dispatch) {
    try {
      await axios.post(`${API_URL}/api/messages/read/${chatId}`, {
        partnerId
      });
      dispatch(getNotifications(personId))
    } catch(e: any) {
      if (e.response.data.code === "TOKEN_EXPIRED") {
        dispatch(logout())
      }
    }
  }
}
