import { Component, PureComponent } from 'react';
import { connect } from 'react-redux';
import { styled } from '@mui/material/styles';

import CommonInput from '../../components/common/CommonInput';
import CommonText from '../../components/common/CommonText';
import CommonSelect from '../../components/common/CommonSelect';
import CommonCountry from '../../components/common/country';
import CommonYearBirth from '../../components/common/yearBirth';
import CommonCity from '../../components/common/city';
import ProfileImage from '../../components/common/image/ProfileImage';
import ProfileStatus from './ProfileStatus';

import { regex } from '../../config';
import { Button } from '@mui/material';

import { updatePerson, updateLanguages, getPerson, updateLocation } from 'actions/person';
import { ConfiguratedContainer, withRouter } from 'components/common';
import Settings from './Settings';
import debounce from 'lodash.debounce';
import Title from 'components/common/Title';
import { withTranslation } from 'react-i18next';

export const TwoColumnContainer = styled('div')(({ theme }) => {
    return {
        display: 'flex',
        ['@media (max-width: 505px)']: { // eslint-disable-line no-useless-computed-key
            flexDirection: 'column'
        }
    };
});

class EditProfile extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            openDelete: false,
            errors: {},
            ...props.person,
            birthyear: props.person.birthyear,
            gender: {
                label: this.resolveMyGender(props.person.gender),
                value: props.person.gender,
            },
            togender: {
                label: this.resolveToGender(props.person.togender),
                value: props.person.togender,
            },
            country: {
                label: props.person.countrylabel,
                value: props.person.countryvalue,
            },
            city: {
                label: props.person.citylabel,
                value: props.person.cityvalue,
            }
        };
        this.onFieldChange = this.onFieldChange.bind(this);
        this.onSelectedLanguageChange = this.onSelectedLanguageChange.bind(this);
        this.openModalDelete = this.openModalDelete.bind(this);
        this.closeModalDelete = this.closeModalDelete.bind(this);
        this.debounceUpdatePerson = debounce(this.callApi, 300); // 300ms debounce delay
        this.onSave = this.onSave.bind(this);
        this.onFieldChangeDebounce = this.onFieldChangeDebounce.bind(this);
        this.goToPartners = this.goToPartners.bind(this);
        this.goToPartners = this.goToPartners.bind(this);
    }

    resolveMyGender(myGender) {
        const {t} = this.props;
        if (myGender === 'Female') {
            return t('editProfile.content.female');
        } else if (myGender === 'Male') {
            return t('editProfile.content.male');
        } else if (myGender === "Prefer not to say") {
            return t('editProfile.content.notSay');
        }
    }

    resolveToGender(toGender) {
        const {t} = this.props;
        if (toGender === 'Women') {
            return t('editProfile.content.women');
        } else if (toGender === 'Men') {
            return t('editProfile.content.men');
        } else if (toGender === "Any gender") {
            return t('editProfile.content.anygender');
        }
    }

    goToPartners() {
        const { navigate } = this.props;
        navigate('/partners');
    }

    UNSAFE_componentWillMount() {
        const { person } = this.props;
        this.props.getPerson(person.id)
    }

    openModalDelete() {
        this.setState({
            openDelete: true
        });
    }

    closeModalDelete() {
        this.setState({
            openDelete: false
        });
    }

    async onSelectedLanguageChange(data) {
        const { errors } = this.state;
        errors[data.name] = data.valid;
        this.setState({ [data.name]: data.values, errors }, async () => {
            const {
                id,
            } = this.state;
            await this.props.updateLanguages({
                personId: id,
                values: data.values,
                type: data.name
            });
            this.setState({
                success: true
            })
        });
    }

    callApi = async (options) => {
        try {
            // Make your API call here
            await this.props.updatePerson(options);
            const { person } = this.props;
            await this.props.getPerson(person.id)
            // Handle the response as needed
        } catch (error) {
            // Handle errors
        }
    };

    async onSave() {
        const { person } = this.props;
        await this.props.getPerson(person.id)
        this.setState({
            success: true
        });
    }

    async onFieldChange(data) {
        const { errors } = this.state;
        errors[data.name] = data.valid;
        const {
            id,
        } = this.state;
        await this.callApi({
            id,
            [data.name]: data.value
        });

        if (data.name === 'country' && data.value == null) {
            errors['city'].isError = false;
            this.setState({
                city: null,
                errors
            });
        }
    }

    async onFieldChangeDebounce(data) {
        const { errors } = this.state;
        errors[data.name] = data.valid;
        const {
            id,
        } = this.state;
        await this.debounceUpdatePerson({
            id,
            [data.name]: data.value
        });

        if (data.name === 'country' && data.value == null) {
            errors['city'].isError = false;
            this.setState({
                city: null,
                errors
            });
        }
    }

    async onFieldChangeCity(data) {
        const { id } = this.state;
        const { label, value, latitude, longitude } = data.value;
        data.value = { label, value };
        await this.onFieldChange(data);
        const location = {
            id,
            latitude,
            longitude
        };
        this.props.updateLocation(location);

    }

    render() {
        const { firstname, birthyear, position, country, city, gender, togender, errors } = this.state;
        const { person, t } = this.props;
        const edit = true;
        return (
            <ConfiguratedContainer configuration={{
                maxWidth: '768px',
                margin: 'auto',
                marginBottom: '10px'
            }}>
                <ProfileStatus person={person} success={this.state.success} />
                <ProfileImage person={person} onSuccess={() => this.setState({ success: true })} />
                <TwoColumnContainer>
                    <CommonInput
                        key='firstname'
                        title={t('editProfile.content.name')}
                        name='firstname'
                        edit={edit}
                        value={firstname}
                        valid={errors['firstname']}
                        onBlur={this.onFieldChange}
                        error={'Firstname is required'}
                        regex={regex.inputRegex}
                    />
                </TwoColumnContainer>
                <TwoColumnContainer>
                    <CommonSelect
                        title={t('editProfile.content.gender')}
                        name='gender'
                        value={gender}
                        options={[
                            { value: 'Male', label: t('editProfile.content.male')},
                            { value: 'Female', label: t('editProfile.content.female') },
                            { value: 'Prefer not to say', label: t('editProfile.content.notSay') },
                        ]}
                        edit={edit}
                        error={'Gender is required'}
                        onValueChange={this.onFieldChange}
                    />
                    <CommonSelect
                        title={t('editProfile.content.lookingFor')}
                        name='togender'
                        value={togender}
                        options={[
                            { value: 'Men', label: t('editProfile.content.men') },
                            { value: 'Women', label: t('editProfile.content.women') },
                            { value: 'Any gender', label: t('editProfile.content.anygender') },
                        ]}
                        edit={edit}
                        error={'Gender is required'}
                        onValueChange={this.onFieldChange}
                    />
                </TwoColumnContainer>
                <TwoColumnContainer>
                    <CommonCountry
                        title={t('editProfile.content.country')}
                        name='country'
                        value={country}
                        edit={edit}
                        error={'Country is required'}
                        onValueChange={(data) => this.onFieldChange(data)}
                    />
                    {country && <CommonCity
                        title={t('editProfile.content.city')}
                        name='city'
                        value={city}
                        countryCode={country.value}
                        onValueChange={(data) => this.onFieldChangeCity(data)}
                        edit={edit}
                        error={'City is required'}
                    />}
                </TwoColumnContainer>
                <TwoColumnContainer>
                    <CommonYearBirth
                        title={t('editProfile.content.birthdate')}
                        name='birthyear'
                        value={birthyear}
                        edit={edit}
                        error={'Year of birth is required'}
                        onValueChange={(data) => this.onFieldChange(data)}
                    />
                    <CommonInput
                        title={t('editProfile.content.job')}
                        name='position'
                        edit={edit}
                        value={position}
                        onBlur={this.onFieldChange}
                        error={'Position is required'}
                        regex={regex.inputRegex}
                    />
                </TwoColumnContainer>
                <CommonText
                    name='description'
                    edit={edit}
                    title={t('editProfile.content.description')}
                    value={this.state.description}
                    onValueChange={this.onFieldChangeDebounce}
                    error={'Description is required'}
                    regex={regex.textRegex}
                />

                

                <ConfiguratedContainer configuration={{
                    marginBottom: '20px',
                    marginRight: '20px'
                }}>
                    <Button
                        fullWidth
                        onClick={this.onSave}
                        variant="contained"
                    >
                        {t('editProfile.buttons.save')}
                    </Button>
                </ConfiguratedContainer>

                {person.active && <ConfiguratedContainer configuration={{
                    marginBottom: '20px',
                    marginRight: '20px'
                }}>
                    <Button
                        fullWidth
                        onClick={this.goToPartners}
                        variant="outlined"
                    >
                        {t('editProfile.buttons.search')}
                    </Button>
                </ConfiguratedContainer>}

                {person.active && <ConfiguratedContainer configuration={{
                    marginBottom: '20px',
                    marginRight: '20px'

                }}>
                    <Title>{t('editProfile.content.delete')}</Title>
                    <Button
                        color="error"
                        onClick={this.openModalDelete}
                        variant="outlined"
                    >
                        {t('editProfile.buttons.delete')}
                    </Button>
                    <Settings
                        open={this.state.openDelete}
                        handleClose={this.closeModalDelete}
                    />
                </ConfiguratedContainer>}

            </ConfiguratedContainer>
        );
    }
}

function mapStoreToProps(store) {
    return {
        person: store.persons.person
    };
}

export default withTranslation()(withRouter(connect(mapStoreToProps, { updatePerson, updateLanguages, getPerson, updateLocation })(EditProfile)));