import React, { Component } from 'react';

import { ConfiguratedContainer, withRouter } from '../components/common';
import { Button, Box } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { withTheme } from '@emotion/react';

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.goToParnters = this.goToParnters.bind(this);
        this.goToChats = this.goToChats.bind(this);
    }

    goToParnters() {
        const { navigate } = this.props;
        navigate('/partners')
    }

    goToChats() {
        const { navigate } = this.props;
        navigate('/chat')
    }

    render() {
        const { theme } = this.props;
        return (
            <ConfiguratedContainer configuration={{
                width: '100%',
                height: '100%',
                marginLeft: '-5px'
            }}>
                
            </ConfiguratedContainer>
        );
    }

}

export default withTheme(withRouter(Home));

