import { Component } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CommonText from '../../components/common/CommonText';
import { regex } from '../../config';
import { ConfiguratedContainer, withRouter, TwoColumnContainer } from 'components/common';

class BlockDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
    };
    this.onFieldChange = this.onFieldChange.bind(this);
    this.confirmBlock = this.confirmBlock.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  confirmBlock() {
    this.props.confirmBlock(this.state.message);
  }

  handleClose() {
    this.props.handleClose();
  };

  onFieldChange(data) {
    this.setState({ [data.name]: data.value });
  }

  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
           Are you sure you want to Block <b>{this.props.name}</b>
          </DialogTitle>
          <DialogContent>
              <ConfiguratedContainer configuration={{
                marginRight: '-20px'
              }}>

              <CommonText
                name='message'
                edit={true}
                title='Please indicate a reason:'
                value={this.state.message}
                onValueChange={this.onFieldChange}
                error={'Message is required'}
                regex={regex.textRegex}
                maxLength={255}
              />
              </ConfiguratedContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose}>Cancel</Button>
            <Button
              disabled={!this.state.message}
              color="error"
              onClick={this.confirmBlock} autoFocus>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default BlockDialog;