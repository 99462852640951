import axios from 'axios';
import * as types from '../constants/ActionTypes';

const defaultState = {
  person: JSON.parse(localStorage.getItem('person')) || null,
  partner: {},
  partners: [],
  token: localStorage.getItem('token') || null,
  filter: JSON.parse(localStorage.getItem('filter')) || {},
  page: 1
};

if (defaultState.token) {
  axios.defaults.headers.common['Authorization'] = defaultState.token;
} else {
  axios.defaults.headers.common['Authorization'] = null;
}

export default function configuration(state = defaultState, action) {
  switch (action.type) {
    case types.GET_PERSON: {
      if (!!action.payload.token) {
        axios.defaults.headers.common['Authorization'] = action.payload.token;
        return { ...state, person: { ...action.payload.person }, token: action.payload.token };
      } else {
        return { ...state, person: { ...action.payload.person } };
      }
    }
    case types.GET_PARTNER: {
      return { ...state, partner: { ...action.payload.person } };
    }
    case types.AUTH_ERROR: {
      axios.defaults.headers.common['Authorization'] = null;
      return { ...state, person: null, token: null };
    }
    //case types.AUTH_SIGNUP: {
      // axios.defaults.headers.common['Authorization'] = action.payload.token;
      // return { ...state, person: { ...action.payload.person }, token: action.payload.token };
    //}
    case types.AUTH_SIGNUP_ERROR: {
      axios.defaults.headers.common['Authorization'] = null;
      return { ...state, person: null, token: null };
    }
    case types.AUTH_LOGOUT: {
      axios.defaults.headers.common['Authorization'] = null;
      return { ...state, person: null, token: null };
    }
    case types.GET_PARTNERS: {
      return {
        ...state, partners:
          action.page === 1 ? [...action.payload.partners] : [...state.partners.concat(action.payload.partners)],
        total: +action.payload.totalCount,
        page: action.page
      };
    }
    case types.UPDATE_PARTNERS_FILTER: {
      return { ...state, filter: { ...action.payload } };
    }
    default:
      return { ...state }
  }
}
