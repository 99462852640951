import { Component } from 'react';
import { connect } from 'react-redux';
import ChatInformation from 'components/messages/chat/ChatInformation';
import Chats from 'components/messages/chat/Chats';
import ArchiveMessages from 'components/messages/message/ArchiveMessages';
import { ConfiguratedContainer, withRouter } from 'components/common';
import { getArchiveChat } from 'actions/chat';

class ArchivedChats extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    UNSAFE_componentWillMount() {
        if (this.props.params.chatId) {
            this.props.getArchiveChat(this.props.params.chatId);
        }
    }

    async UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.params && nextProps.params.chatId !== this.props.params.chatId) {
            this.props.getArchiveChat(nextProps.params.chatId);
        }
    }

    render() {
        const { person, chat, params } = this.props;
        return (
            <ConfiguratedContainer configuration={{
                display: 'flex',
                height: 'calc(100% + 33px)',
                marginRight: '15px',
                border: 'solid lightgrey 1px',
                borderRadius: '5px',
                margin: 0,
                marginTop: '-23px',
                marginLeft: '-15px',
                ['@media (max-width: 1200px)']: { // eslint-disable-line no-useless-computed-key
                    marginTop: -10,
                    height: 'calc(100% - 0px)',
                    // border: 'solid'
                },
                ['@media (max-width: 425px)']: { // eslint-disable-line no-useless-computed-key
                    margin: 0,
                    marginLeft: '-15px',
                    marginTop: '-10px',
                    height: '100%',
                }
            }}>
                <Chats />
                {params.chatId && <ConfiguratedContainer configuration={{
                    flex: 1,
                    display: 'flex',

                    flexDirection: 'column',
                    ['@media (max-width: 425px)']: { // eslint-disable-line no-useless-computed-key
                        width: '100%',
                    }
                }}>
                    {chat && <ChatInformation chat={chat} personId={person.id} archive={true} />}
                    <ArchiveMessages chatId={chat.id} />
                </ConfiguratedContainer>}
            </ConfiguratedContainer>
        );
    }
}

function mapStoreToProps(store) {
    return {
        person: store.persons.person,
        chat: store.chats.chat,
        match: store.match.match
    };
}

export default withRouter(connect(mapStoreToProps, { getArchiveChat })(ArchivedChats));