
import { Component } from 'react';
import { withTheme } from '@mui/styles';
import CommonCountry from 'components/common/country';
import CommonCity from 'components/common/city';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { Box, IconButton } from '@mui/material';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { connect } from 'react-redux';
import { updateFilter, getPartners } from '../../actions/person';
import Button from '@mui/material/Button';

import { ConfiguratedContainer, withRouter } from '../common';
import Slider from '@mui/material/Slider';
import Title from 'components/common/Title';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import debounce from 'lodash.debounce';
import { withTranslation } from 'react-i18next';

function valuetext(value) {
    return `${value} km`;
}

function valueAgetext(value) {
    return `${value} years`;
}

class Filter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expand: false,
            country: props.filter.country,
            city: props.filter.city,
            distance: props.filter.distance || 300,
            range: props.filter.range || [18, 100],
            type: props.filter.type || 'location',
            searching: false
        };
        this.onFieldChange = this.onFieldChange.bind(this);
        this.expandFilter = this.expandFilter.bind(this);
        this.resetFilter = this.resetFilter.bind(this);
        // this.applyFilter = this.applyFilter.bind(this);
        this.onDisanceChange = this.onDisanceChange.bind(this);
        this.handleAgeChange = this.handleAgeChange.bind(this);
        this.onChangeType = this.onChangeType.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.debouncedGetPartners = debounce(this.callApi, 300); // 300ms debounce delay

    }

    callApi = async (options) => {
        try {
            // Make your API call here
            await this.props.getPartners(options);
            // Handle the response as needed
        } catch (error) {
            // Handle errors
        }
    };

    onSearch() {
        const { country, city, distance = 300, range = [18, 100], type = 'location' } = this.state;
        const { person } = this.props;
        try {
            this.setState({
                searching: true
            }, async () => {
                this.props.updateFilter({ country, city, distance, range, type });
                this.debouncedGetPartners({
                    personId: person.id,
                    page: 1,
                    country: country ? country.value : undefined,
                    city: city ? city.value : undefined,
                    distance,
                    range,
                    type
                });
            })
        } finally {
            this.setState({
                searching: false
            });
        };

    }

    onChangeType(event) {
        const chosenType = event.target.value;
        try {
            this.setState({
                city: undefined,
                country: undefined,
                distance: 300,
                type: chosenType,
                searching: true
            }, async () => {
                await this.onSearch();
            });
        } finally {
            this.setState({
                searching: false
            });
        };
    }

    handleAgeChange(event) {
        try {
            this.setState({
                range: event.target.value,
                searching: true
            }, async () => {
                await this.onSearch();
            });
        } finally {
            this.setState({
                searching: false
            });
        };
    }

    onDisanceChange(event) {
        try {

            this.setState({
                distance: event.target.value
            }, async () => {
                await this.onSearch();
            });
        } finally {
            this.setState({
                searching: false
            });
        };
    }

    expandFilter(event) {
        this.setState({
            expand: !this.state.expand
        });
    }

    resetFilter() {
        this.setState({
            country: null,
            city: null,
            distance: 300,
            range: [18, 100],
            searching: true,
            type: 'location'
        }, async () => {
            await this.onSearch();
        });
    }

    onFieldChange(data) {
        this.setState({
            [data.name]: data.value
        }, () => {
            const { country, city, distance } = this.state;
            this.props.updateFilter({ country, city, distance });
            this.onSearch();
        });
    }

    render() {
        const { theme, t } = this.props;
        const { country, city, expand, distance, range, type } = this.state;
        const edit = true;
        return (
            <ConfiguratedContainer configuration={{
                backgroundColor: 'white',
                padding: '10px 0px 10px 15px',
                borderRadius: '10px',
                marginRight: '15px',
                maxWidth: '768px',
                margin: 'auto auto 10px auto',
                [':hover']: { // eslint-disable-line no-useless-computed-key
                    boxShadow: !expand ? '0 0 11px rgba(33,33,33,.2)' : '',
                },
                ['@media (max-width: 768px)']: { // eslint-disable-line no-useless-computed-key
                    marginRight: '15px'
                }
            }}>
                {!expand && <ConfiguratedContainer
                    // onClick={this.expandFilter}
                    configuration={{
                        width: '100%',
                        cursor: 'pointer'
                    }}>
                    <ConfiguratedContainer configuration={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                    }}>
                        <ConfiguratedContainer configuration={{
                            color: theme.palette.primary.main,
                            fontWeight: '500',
                            fontSize: '20px',
                            cursor: 'pointer'
                        }}
                            // onClick={this.expandFilter}
                            >{t('filter.title')}</ConfiguratedContainer>

                        {/* <Box sx={{ flexGrow: 1 }} /> */}

                        <ConfiguratedContainer configuration={{
                            marginRight: '10px',
                            display: 'flex',
                            gap: '15px',
                            flex: 1
                        }}>
                            <Button fullWidth onClick={this.expandFilter}>
                                {t('filter.content.expand') }
                            </Button>
                            {/* <Button onClick={this.resetFilter} endIcon={<FilterAltOffIcon />}>
                                Clear
                            </Button> */}
                            <IconButton onClick={this.expandFilter}>
                                <UnfoldLessIcon />
                            </IconButton>
                        </ConfiguratedContainer>
                    </ConfiguratedContainer>
                    {/* <ConfiguratedContainer configuration={{
                        display: 'flex',
                        gap: '10px',
                        // justifyContent: 'space-around',
                        alignItems: 'center',
                        flexWrap: 'wrap'
                    }}>
                        {type === 'location' && country && <ConfiguratedContainer configuration={{
                            display: 'flex',
                            gap: '5px'
                        }}>
                            <b>Country:</b> {country.label}
                        </ConfiguratedContainer>}
                        {type === 'location' && country && city && <ConfiguratedContainer configuration={{
                            display: 'flex',
                            gap: '5px'
                        }}>
                            <b>City:</b> {city.label}
                        </ConfiguratedContainer>}
                        {type === 'distance' && distance && <ConfiguratedContainer configuration={{
                            display: 'flex',
                            gap: '5px'
                        }}>
                            <b>Distance:</b> {distance} km
                        </ConfiguratedContainer>}

                        {range && <ConfiguratedContainer configuration={{
                            display: 'flex',
                            gap: '5px'
                        }}>
                            <b>Range:</b> {range[0]} to {range[1]} years
                        </ConfiguratedContainer>}

                    </ConfiguratedContainer> */}
                </ConfiguratedContainer>}

                {expand && <ConfiguratedContainer configuration={{
                    marginLeft: '-15px',
                    marginTop: '-10px',
                    padding: '0 0 0 15px',
                    borderBottom: '#0D47A1 1px dashed',
                    marginBottom: '10px',
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    <ConfiguratedContainer configuration={{
                        color: theme.palette.primary.main,
                        fontWeight: '500',
                        fontSize: '30px',
                        margin: '10px 0',
                        cursor: 'pointer'
                    }}
                        onClick={this.expandFilter}>{t('filter.title')}</ConfiguratedContainer>
                    <Box sx={{ flexGrow: 1 }} />
                    <ConfiguratedContainer configuration={{
                        marginRight: '10px',
                        display: 'flex',
                        gap: '15px'
                    }}>
                        {(country || range[0] !== 18 || range[1] !== 100 || distance !== 300) && <Button onClick={this.resetFilter} endIcon={<FilterAltOffIcon />}>
                            
                        </Button>}
                        <IconButton onClick={this.expandFilter}>
                            <UnfoldMoreIcon />
                        </IconButton>
                    </ConfiguratedContainer>
                </ConfiguratedContainer>}

                {expand && <ConfiguratedContainer configuration={{
                    width: 'calc(100% - 25px)',
                    flexDirection: 'column',
                    marginBottom: '20px',
                    display: 'flex',
                    ['@media (max-width: 505px)']: { // eslint-disable-line no-useless-computed-key
                        flexDirection: 'column'
                    },
                }}>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={this.state.type}
                        onChange={this.onChangeType}
                    >
                        <FormControlLabel value="distance" control={<Radio />} label={t('filter.content.distance')} />
                        <FormControlLabel value="location" control={<Radio />} label={t('filter.content.location')} />
                    </RadioGroup>
                </ConfiguratedContainer>}

                {expand && type === 'distance' && <ConfiguratedContainer configuration={{
                    width: 'calc(100% - 35px)',
                    flexDirection: 'column',
                    marginLeft: '5px',
                    display: 'flex',
                    ['@media (max-width: 505px)']: { // eslint-disable-line no-useless-computed-key
                        flexDirection: 'column'
                    },
                }}>
                    <Title> {t('filter.content.distance')} ({distance} km):</Title>
                    <Slider
                        aria-label="Distance"
                        value={distance}
                        getAriaValueText={valuetext}
                        valueLabelDisplay="auto"
                        step={25}
                        marks
                        min={0}
                        max={300}
                        onChange={this.onDisanceChange}
                    />

                </ConfiguratedContainer>}

                {expand && type === 'location' && <ConfiguratedContainer configuration={{
                    width: '100%',
                    display: 'flex',
                    ['@media (max-width: 505px)']: { // eslint-disable-line no-useless-computed-key
                        flexDirection: 'column'
                    },
                }}>
                    <CommonCountry
                        title={t('filter.content.country')}
                        name='country'
                        value={country}
                        edit={edit}
                        onValueChange={(data) => this.onFieldChange(data)}
                    />
                    {country && <CommonCity
                        title={t('filter.content.city')}
                        name='city'
                        value={city}
                        countryCode={country.value}
                        onValueChange={(data) => this.onFieldChange(data)}
                        edit={edit}
                    />}
                </ConfiguratedContainer>}

                {expand && <ConfiguratedContainer configuration={{
                    width: 'calc(100% - 35px)',
                    flexDirection: 'column',
                    marginLeft: '5px',
                    marginRight: '27px',
                    marginTop: '17px',
                }}>

                    {/* <ConfiguratedContainer configuration={{marginLeft: '-14px',}}>  */}
                    {range && <Title> {t('filter.content.age', {
                        range1: range[0],
                        range2: range[1] 
                    })}</Title>}
                    {/* </ConfiguratedContainer> */}
                    <Slider
                        step={1}
                        min={18}
                        max={100}
                        getAriaLabel={() => 'Minimum distance'}
                        value={range}
                        onChange={this.handleAgeChange}
                        valueLabelDisplay="auto"
                        getAriaValueText={valueAgetext}
                        disableSwap
                        marks
                    />

                </ConfiguratedContainer>}
                {expand && <ConfiguratedContainer configuration={{
                    marginTop: '10px',
                    marginRight: '20px'
                }}>
                    <Button
                        fullWidth
                        onClick={() => {
                            this.onSearch();
                            this.expandFilter();
                        }}
                        variant="contained"
                    >
                        {t('filter.buttons.apply')}
                    </Button>
                </ConfiguratedContainer>}
                {/* {!expand && <ConfiguratedContainer configuration={{
                    marginTop: '10px',
                    marginRight: '20px'
                }}>
                    <Button
                        fullWidth
                        onClick={this.expandFilter}
                        variant="contained"
                    >
                        Expand Search
                    </Button>
                </ConfiguratedContainer>} */}
            </ConfiguratedContainer>
        );
    }
}

function mapStoreToProps(store) {
    return {
        person: store.persons.person,
        filter: store.persons.filter,
    };
}

export default withTranslation()(connect(mapStoreToProps, { updateFilter, getPartners })(withRouter(withTheme(Filter))));

