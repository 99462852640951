import React from 'react';
import { withTheme } from '@mui/styles';

import Select from 'react-select';

import Title from './Title';
import { FieldContainer, Error } from '../common';

const getCustomStyle = (theme) => {
  return {
    option: (provided) => ({
      ...provided,
      paddingLeft: '14px',
      fontFamily: theme.fontFamily,
      fontSize: '14px',
    }),
    control: () => ({
      width: 'calc(100% - 20px)',
      display: 'flex',
      height: '48px',
      borderRadius: '4px',
      border: `1px solid ${theme.palette.primary.main}`,
      paddingLeft: '14px',
      color: theme.palette.primary.main,
      fontFamily: theme.fontFamily,
      backgroundColor: 'white',
      '&:focus': {
        fontSize: '14px',
        outline: 'none',
        border: `1px solid ${theme.palette.primary.main}`,
        boxShadow: `0 0 10px ${theme.palette.primary.main}`
      }
    }),
    singleValue: (provided, state) => {
      return {
        ...provided,
        paddingLeft: '0px',
        fontSize: '14px',
      };
    },
  };
}


class CommonSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputMode: false,
      value: props.value,
      valid: ''
    };
    this.handleBlur = this.handleBlur.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    if (!this.state.inputMode) {
      this.setState({ inputMode: true });
    }
  }

  onValueChange(value) {
    const { name } = this.props;
    let valid = true;
    if (!value) {
      valid = false;
    }
    this.setState({
      value,
      valid
    }, () => {
      this.props.onValueChange({
        name,
        value: this.state.value,
        valid
      });
    });
  }

  handleBlur = () => {
    if (this.state.inputMode) {
      this.setState({ inputMode: false });
    }
  };

  render() {
    const { valid } = this.state;
    const { title, theme, options, name, error } = this.props;
    const { value } = this.state;
    return (
      <FieldContainer>
        <Title theme={theme}> {title} </Title>
        <Select
          name={name}
          isClearable={true}
          options={options}
          styles={getCustomStyle(theme)}
          onChange={this.onValueChange}
          onBlur={this.handleBlur}
          value={value}
        />
        <Error valid={valid}>{error}</Error>
      </FieldContainer>
    );
  }
}

export default withTheme(CommonSelect);