export const GET_PERSON = 'GET_PERSON';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_SIGNUP = 'AUTH_SIGNUP';
export const AUTH_SIGNUP_ERROR = 'AUTH_SIGNUP_ERROR';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const GET_PARTNERS = 'GET_PARTNERS';
export const GET_PARTNER = 'GET_PARTNER';
export const SAVE_PARTNER = 'SAVE_PARTNER';
export const UPDATE_PARTNER = 'UPDATE_PARTNER';
export const UPDATE_PARTNERS_FILTER = 'UPDATE_PARTNERS_FILTER';

export const RECIEVE_CITIES = 'RECIEVE_CITIES';
export const RECIEVE_CITIES_ERROR = 'RECIEVE_CITIES_ERROR';

export const GET_CHATS = 'GET_CHATS';

export const GET_CHAT = 'GET_CHAT';
export const GET_MESSAGES = 'GET_MESSAGES';
// export const SAVE_MESSAGE = 'SAVE_MESSAGE';
export const SEND_MESSAGE = 'SEND_MESSAGE';

export const CONNECTION_ESTABLISHED = 'CONNECTION_ESTABLISHED';
export const CONNECTION_GET_MESSAGE = 'CONNECTION_GET_MESSAGE';

export const RECIEVE_NOTIFICATIONS = 'RECIEVE_NOTIFICATIONS';

export const GET_MATCH = "GET_MATCH";
export const CANCEL_MATCH = "CANCEL_MATCH";
export const EVENT_NEW_MATCH = 'EVENT_NEW_MATCH';
export const EVENT_CANCEL_MATCH = 'EVENT_CANCEL_MATCH';
export const NEW_MATCH_VIEWED = 'NEW_MATCH_VIEWED';
export const CANCEL_MATCH_VIEWED = 'CANCEL_MATCH_VIEWED';
