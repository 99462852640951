import React from 'react';
import './index.css';
import { countries } from './countries';
import CommonSelect from '../CommonSelect';
import { getCities } from '../../../actions/location'
import { connect } from 'react-redux';

class CommonCountry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputMode: false,
      name: props.name,
      value: props.value
    };
    this.onClick = this.onClick.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange(data) {
    this.setState({ value: data.value });
    if (this.props.onValueChange) {
      this.props.onValueChange(data);
      if (data.value) {
        this.props.getCities(data.value.value)
      }
    }
  }

  onClick() {
    this.setState({ inputMode: true });
  }

  render() {
    const { value } = this.state;
    const { title, edit, name, error } = this.props;
    return (
      <CommonSelect
        title={title}
        name={name}
        options={countries}
        edit={edit}
        value={value}
        error={error}
        onValueChange={this.onChange}
      />
    );
  }
}

function mapStoreToProps(store) {
  return {
  };
}

export default connect(mapStoreToProps, { getCities })(CommonCountry);

