import { Component } from 'react';
import { withTheme } from '@mui/styles';
import { ConfiguratedContainer, withRouter } from 'components/common';
import { withTranslation } from 'react-i18next';

class NotFound extends Component {
    render() {
        const { theme, t } = this.props;
        return (
            <ConfiguratedContainer configuration={{
                maxWidth: '1000px',
                paddingRight: '20px',
                textAlign: 'justify',
                margin: 'auto',
                ['@media (max-width: 1000px)']: { // eslint-disable-line no-useless-computed-key
                marginLeft: '-20px',

                },
            }}>
                <ConfiguratedContainer configuration={{
                    marginTop: '20px',
                    marginBottom: '20px',
                    color: theme.palette.primary.main,
                    fontWeight: '500',
                    fontSize: '30px',
                    marginLeft: '20px'
                }}>
                    {t('terms.title')}
                </ConfiguratedContainer>
                <ol>
                    <li>
                        <b>{t('terms.introduction')} </b> 
                        {t('terms.introductionContent')}
                    </li>
                    <li>
                        <b>{t('terms.membership')} </b>
                        {t('terms.membershipContent')}
                    </li>
                    <li>
                        <b>{t('terms.conduct')} </b>
                        {t('terms.conductContent')}
                    </li>
                    <li>
                        <b> {t('terms.age')} </b>
                        {t('terms.ageContent')}
                    </li>
                    <li>
                        <b>{t('terms.content')} </b>
                        {t('terms.contentContent')}
                    </li>
                    <li>
                        <b>{t('terms.property')} </b>
                        {t('terms.propertyContent')}
                    </li>
                    <li>
                        <b>{t('terms.disclamer')} </b>
                        {t('terms.disclamerContent')}
                    </li>
                    <li>
                        <b>{t('terms.limitation')} </b>
                        {t('terms.limitationContent')}
                    </li>
                    <li>
                        <b> {t('terms.indemnification')} </b>
                        {t('terms.indemnificationContent')}
                    </li>
                    <li>
                        <b> {t('terms.termination')} </b>
                        {t('terms.terminationContent')}
                    </li>
                    <li>
                        <b> {t('terms.law')} </b>
                        {t('terms.lawContent')}
                    </li>
                    <li>
                        <b>{t('terms.changes')} </b>
                        {t('terms.changesContent')} 
                    </li>
                    <li>
                        <b> {t('terms.contact')}  </b>
                        {t('terms.contactContent')}

                    </li>
                    

                </ol>
            </ConfiguratedContainer>
        );
    }
}

export default withTranslation()(withRouter(withTheme(NotFound)));