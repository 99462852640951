import React from 'react';
import { connect } from 'react-redux';
import { uploadProfileImage, deleteImage } from '../../../actions/person';
import { API_URL } from '../../../config';
import './index.css';
import { ConfiguratedContainer, withRouter } from '../containers';
import IconButton from '@mui/material/IconButton';
import { ArrowBack } from '@mui/icons-material';
import ImagesPreview from './ImagesPreview';
import { withTranslation } from 'react-i18next';

class ViewProfileImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      index: 0
    };
    this.openImagePreview = this.openImagePreview.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.goToPartners = this.goToPartners.bind(this);
  }

  goToPartners() {
    const { navigate } = this.props;
    navigate('/partners');
  }

  openImagePreview(index) {
    this.setState({
      open: true,
      index
    });
  }

  onCloseModal() {
    this.setState({
      open: false,
      index: 0
    });
  }

  render() {
    const { person, t } = this.props;

    return (
      <div className="profile-image-wrapper">
        
        <ConfiguratedContainer
          onClick={this.goToPartners}
          configuration={{
            display: 'flex',
            alignItems: 'center',
            // color: 'grey',
            cursor: 'pointer'
          }}>
          <IconButton >
            <ArrowBack />

          </IconButton>
          <p>{t('profile.buttons.back')}</p>
        </ConfiguratedContainer>
        {person.images && <ImagesPreview index={this.state.index} items={person.images} open={this.state.open} onClose={this.onCloseModal} />}
        <ConfiguratedContainer configuration={{
          width: 'calc(100% - 20px)',
          display: 'flex',
          margin: '20px 0px',
          flexWrap: 'wrap',
          gap: 'calc(4% / 2)'
        }}>
          {(person.images || []).map((image, index) => {
            const url = `${API_URL}/api/files/${image.id}`;
            return (
              <ConfiguratedContainer
                onClick={() => this.openImagePreview(index)}
                key={image.id}
                configuration={{
                  border: 'grey 1px solid',
                  borderRadius: '10px',
                  height: '300px',
                  width: '32%',
                  marginBottom: '10px',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  position: 'relative',
                  cursor: 'pointer',
                  ['@media (max-width: 728px)']: { // eslint-disable-line no-useless-computed-key
                    height: '200px'
                  },
                  ['@media (max-width: 505px)']: { // eslint-disable-line no-useless-computed-key
                    height: '130px'
                  }
                }}
                style={{ backgroundImage: `url(${url})` }}>
                <ConfiguratedContainer configuration={{
                  position: 'absolute',
                  right: '0px',
                  ['@media (max-width: 505px)']: { // eslint-disable-line no-useless-computed-key
                    right: '-10px',
                    top: '-7px',
                  }
                }} />
              </ConfiguratedContainer>
            )
          })}

        </ConfiguratedContainer>

      </div>
    );
  }
}


export default withTranslation()(withRouter(connect(() => { return {}; }, { uploadProfileImage, deleteImage })(ViewProfileImage)));
