import * as types from '../constants/ActionTypes';
import axios from 'axios';
import { API_URL } from '../config';
import { logout } from './authentication';

export function getCities(countyCode) {
    return function (dispatch) {
        return axios.get(`${API_URL}/api/locations/${countyCode}`)
            .then(response => {
                const data = response.data;
                dispatch({
                    type: types.RECIEVE_CITIES,
                    payload: data
                });
            })
            .catch(error => {
                if (error.response.data.code === "TOKEN_EXPIRED") {
                    dispatch(logout())
                }
                dispatch({
                    type: types.RECIEVE_CITIES_ERROR,
                });
            });
    }
}

