import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Box } from '@mui/material';
import { ConfiguratedContainer } from 'components/common';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  return (
    <ConfiguratedContainer configuration={{
      height: '30px',
      marginTop: '16px',
      marginLeft: '20px',
      display: 'flex',
      alignItems: 'center'
    }}>

      <RouterLink to="/home">
        <Box component="img" src="/static/logo.png" alt='Monotryst' sx={{ height: 70 }} />
      </RouterLink>
      <ConfiguratedContainer configuration={{
                    // border: 'solid',
                    marginTop:'-15px',
                    marginLeft:'10px',
                    textAlign: 'center',
                    fontFamily: "Public Sans,sans-serif",// "Arial, Helvetica, sans-serif;",
                    fontSize: '28px',
                    color: '#0D47A1',
                    ['@media (max-width: 505px)']: { // eslint-disable-line no-useless-computed-key
                        fontSize: '20px',
                    }
                }}>
                    <p>Monotryst</p>
                </ConfiguratedContainer>
    </ConfiguratedContainer>
  );
}
