import * as types from '../constants/ActionTypes';
import { API_URL } from '../config';
import axios from 'axios';
import { logout } from './authentication';

export function getPartners(query) {
  return async function (dispatch) {
    try {
      const response = await axios.post(`${API_URL}/api/persons/partners`, query);
      dispatch({
        type: types.GET_PARTNERS,
        payload: response.data,
        page: query.page
      });
    } catch(e: any) {
      if (e.response.data.code === "TOKEN_EXPIRED") {
        dispatch(logout())
      }
    }
  }
}

export function getPerson(personId) {
  return function (dispatch) {
    return axios.get(`${API_URL}/api/persons/${personId}`)
      .then(response => {
        localStorage.setItem('person', JSON.stringify(response.data.person));
        dispatch({
          type: types.GET_PERSON,
          payload: response.data
        })
      })
      .catch(error => {
        if (error.response.data.code === "TOKEN_EXPIRED") {
          dispatch(logout())
        }
      });
  }
}

export function getPartner(personId) {
  return function (dispatch) {
    return axios.get(`${API_URL}/api/persons/partner/${personId}`)
      .then(response => {
        dispatch({
          type: types.GET_PARTNER,
          payload: response.data
        })
      })
      .catch(error => {
        if (error.response.data.code === "TOKEN_EXPIRED") {
          dispatch(logout())
        }
      });
  }
}

export function updatePerson(data) {
  return function (dispatch) {
    return axios.post(`${API_URL}/api/persons/update`, data)
      .then(response => {
        localStorage.setItem('person', JSON.stringify(response.data.person));
        // dispatch({
        //   type: types.GET_PERSON,
        //   payload: response.data
        // })
      })
      .catch(error => {
        if (error.response.data.code === "TOKEN_EXPIRED") {
          dispatch(logout())
        }
      });
  }
}

export function blockPerson(partnerId, message) {
  return function (dispatch) {
    return axios.post(`${API_URL}/api/persons/block`, {partnerId, message})
      .then(response => {
        dispatch({
          type: types.CANCEL_MATCH,
        });
      })
      .catch(error => {
        if (error.response.data.code === "TOKEN_EXPIRED") {
          dispatch(logout())
        }
      });
  }
}

export function unblockPerson(partnerId, message) {
  return function (dispatch) {
    return axios.post(`${API_URL}/api/persons/unblock`, {partnerId, message})
      .then(response => {
        dispatch(getPartner(partnerId));
      })
      .catch(error => {
        if (error.response.data.code === "TOKEN_EXPIRED") {
          dispatch(logout())
        }
      });
  }
}

export function updateLocation(location) {
  return function (dispatch) {
    return axios.post(`${API_URL}/api/persons/location`, location)
      .then(response => {
        localStorage.setItem('person', JSON.stringify(response.data.person));
        dispatch({
          type: types.GET_PERSON,
          payload: response.data
        })
      })
      .catch(error => {
        if (error.response.data.code === "TOKEN_EXPIRED") {
          dispatch(logout())
        }
      });
  }
}

export function updateLanguages(data) {
  return function (dispatch) {
    return axios.put(`${API_URL}/api/persons/languages`, data)
      .then(() => {
        dispatch(getPerson(data.personId))
      });
  }
}

export function uploadProfileImage(data) {
  return function (dispatch) {
    return axios.post(`${API_URL}/api/persons/file`, data)
      .then(() => {
        dispatch(getPerson(data.get('personId')))
      });
  }
}

export function updateProfileImage(data) {
  return function (dispatch) {
    return axios.post(`${API_URL}/api/persons/profileimage`, data)
      .then(() => {
        dispatch(getPerson(data.personId))
      });
  }
}

export function deleteImage(personId, imageId) {
  return function (dispatch) {
    return axios.delete(`${API_URL}/api/persons/file/${imageId}`)
      .then(() => {
        dispatch(getPerson(personId))
      });
  }
}

export function deleteProfile(message) {
  return function (dispatch) {
    return axios.post(`${API_URL}/api/persons/delete`, {message})
    .catch(error => {
      if (error.response.data.code === "TOKEN_EXPIRED") {
        dispatch(logout())
      }
    });;
  }
}


export function updateFilter(filter) {
  return function (dispatch) {
    localStorage.setItem('filter', JSON.stringify(filter));
    dispatch({
      type: types.UPDATE_PARTNERS_FILTER,
      payload: filter
    });
  }
}


