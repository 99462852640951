import { Component } from 'react';
import { withTheme } from '@mui/styles';
import { connect } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';
import CommonInput from 'components/common/CommonInput';
import { regex } from '../../config';
import { ConfiguratedContainer, withRouter } from 'components/common';
import { resetPassword } from '../../actions/authentication';

class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmitting: false
        };
        this.isValid = this.isValid.bind(this);
        this.onReset = this.onReset.bind(this);
        this.onValueChange = this.onValueChange.bind(this);
        this.goToLogin = this.goToLogin.bind(this);
        this.goToReset = this.goToReset.bind(this);
    };

    goToLogin() {
        const { navigate } = this.props;
        navigate('/login');
    }

    goToReset() {
        const { navigate } = this.props;
        navigate('/reset');
    }

    isValid() {
        const { password } = this.state;
        if (
            password && regex.passwordRegex.test(password)
        ) {
            this.setState({ isValid: true });
        } else {
            this.setState({ isValid: false });
        }
    }

    async onReset() {
        const { params } = this.props;
        const { password } = this.state;
        try {
            await this.props.resetPassword(password, params.token);
            this.setState({
                success: "PASSWORD_UPDATED"
            });
        } catch (err) {
            console.log(err)
            this.setState({
                error: "TOKEN_EXPIRED"
            });
        }
    }

    onValueChange(data) {
        this.setState({
            [data.name]: data.value
        }, () => {
            this.isValid()
        });
    }


    render() {
        const {
            password
        } = this.state;
        return (
            <ConfiguratedContainer configuration={{
                display: 'table',
                height: '100%',
                width: '100%',
                ['@media (max-width: 425px)']: { // eslint-disable-line no-useless-computed-key
                    // height: '100%',
                    // height: 'calc(100% + 20px)',

                },
            }}>
                <ConfiguratedContainer configuration={{
                    display: 'table-cell',
                    verticalAlign: 'middle',
                    ['@media (max-width: 425px)']: { // eslint-disable-line no-useless-computed-key
                        height: '100%',
                        // height: 'calc(100% + 160px)',

                    },
                }}>
                    {!this.state.success && !this.state.error && <ConfiguratedContainer configuration={{
                        backgroundColor: 'white',
                        width: '375px',
                        margin: 'auto',
                        padding: '20px',
                        borderRadius: '5px',
                        ['@media (max-width: 425px)']: { // eslint-disable-line no-useless-computed-key
                            height: '100%',
                            // border: 'solid',
                            paddingTop: '120px',
                            // paddingBottom: '20px'
                        },
                    }}>
                        <ConfiguratedContainer configuration={{
                            display: 'flex',
                            justifyContent: 'center',
                        }} >
                            <img  alt='Monotryst' src='/static/logo.png' />
                        </ConfiguratedContainer>
                        <ConfiguratedContainer>
                            <ConfiguratedContainer configuration={{ width: 'calc(100% + 15px)' }}>
                                <CommonInput
                                    title='Password'
                                    name='password'
                                    type='password'
                                    value={password}
                                    error={'Password is required'}
                                    regex={regex.passwordRegex}
                                    onValueChange={this.onValueChange}
                                />
                            </ConfiguratedContainer>

                            <ConfiguratedContainer configuration={{
                            }}>
                                <LoadingButton
                                    fullWidth
                                    disabled={!this.state.isValid}
                                    onClick={this.onReset}
                                    loading={this.state.isSubmitting}
                                    loadingPosition="start"
                                    startIcon={<div />}
                                    variant="contained">
                                    Reset Password
                                </LoadingButton>
                            </ConfiguratedContainer>

                            <ConfiguratedContainer configuration={{
                                marginBottom: '20px'
                            }}>
                            </ConfiguratedContainer>
                        </ConfiguratedContainer>

                    </ConfiguratedContainer>}
                    {this.state.error && <ConfiguratedContainer configuration={{
                        backgroundColor: 'white',
                        width: '375px',
                        margin: 'auto',
                        padding: '20px',
                        borderRadius: '5px',
                        ['@media (max-width: 425px)']: { // eslint-disable-line no-useless-computed-key
                            height: 'calc(100% + 100px)',
                            // border: 'solid',
                            paddingTop: '120px',
                            // paddingBottom: '20px'
                        },
                    }}>
                        <ConfiguratedContainer configuration={{
                            display: 'flex',
                            justifyContent: 'center',
                        }} >
                            <img alt='Monotryst' src='/static/logo.png' />
                        </ConfiguratedContainer>
                        <ConfiguratedContainer>
                            <p>Sorry, but the link you used to change your password has expired.</p>
                            <p>Please request a new password reset link to continue the process.</p>
                        </ConfiguratedContainer>
                        <ConfiguratedContainer configuration={{
                            }}>
                                <LoadingButton
                                    fullWidth
                                    onClick={this.goToReset}
                                    loading={this.state.isSubmitting}
                                    loadingPosition="start"
                                    startIcon={<div />}
                                    variant="contained">
                                    Request Change password
                                </LoadingButton>
                            </ConfiguratedContainer>

                    </ConfiguratedContainer>}
                    {this.state.success && <ConfiguratedContainer configuration={{
                        backgroundColor: 'white',
                        width: '375px',
                        margin: 'auto',
                        padding: '20px',
                        borderRadius: '5px',
                        ['@media (max-width: 425px)']: { // eslint-disable-line no-useless-computed-key
                            height: '100%',
                            // border: 'solid',
                            paddingTop: '120px',
                            // paddingBottom: '20px'
                        },
                    }}>
                        <ConfiguratedContainer configuration={{
                            display: 'flex',
                            justifyContent: 'center',
                        }} >
                            <img alt='Monotryst' src='/static/logo.png' />
                        </ConfiguratedContainer>
                        <ConfiguratedContainer>
                            <p>Congratulations! Your password has been successfully updated.</p>
                            <p>We strongly recommend keeping your password secure and confidential. </p>
                            <p>If you have any further questions or concerns, please don't hesitate to reach out to us.</p>
                        </ConfiguratedContainer>
                        <ConfiguratedContainer configuration={{
                            }}>
                                <LoadingButton
                                    fullWidth
                                    onClick={this.goToLogin}
                                    loading={this.state.isSubmitting}
                                    loadingPosition="start"
                                    startIcon={<div />}
                                    variant="contained">
                                    Login
                                </LoadingButton>
                            </ConfiguratedContainer>

                    </ConfiguratedContainer>}
                </ConfiguratedContainer>
            </ConfiguratedContainer>
        );
    }
}

function mapStoreToProps(store) {
    return {};
}

export default withRouter(withTheme(connect(mapStoreToProps, { resetPassword })(ChangePassword)));